import React, { useState, useCallback } from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { IGetFinancialInstitutionConnectionResponseInfinite } from '@src/requests/financial_institution_connection';
import { IFinancialInstitutionBankAccount } from '@src/types/financial_institution_bank_account';
import { IFinancialInstitutionConnection } from '@src/types/financial_institution_connection';

import Table from '@src/components/ui/table';

import Item from './financial_institution_connection_item';
import { useSetupAccount } from './new_bank_or_credit_card/new_account';

interface IFinancialInstitutionConnectionTableProps {
  query: UseInfiniteQueryResult<IGetFinancialInstitutionConnectionResponseInfinite, Error>,
  financialInstitutionConnections: IFinancialInstitutionConnection[],
  refetchFinancialConnectionList: () => void
}

const FinancialInstitutionConnectionTable = ({
  query,
  financialInstitutionConnections,
  refetchFinancialConnectionList,
}: IFinancialInstitutionConnectionTableProps) => {
  const [openFinancialBankAccountMapping, setOpenFinancialBankAccountMapping] = useState<IFinancialInstitutionBankAccount | null>(null);

  const closeFinancialBankAccountMapping = useCallback(() => {
    setOpenFinancialBankAccountMapping(null);
  }, [setOpenFinancialBankAccountMapping]);

  const {
    Component: SetupAccountComponent,
    props: setupAccountProps,
    openWithValue: openSetupAccount,
  } = useSetupAccount({
    onDone:   closeFinancialBankAccountMapping,
    onCancel: closeFinancialBankAccountMapping,
  });

  const handleClickForMapFICAccount = useCallback((
    financialAccount: IFinancialInstitutionBankAccount,
    financialInstitutionConnection: IFinancialInstitutionConnection,
  ) => {
    // If clicking the same account that's already open, do nothing
    if (openFinancialBankAccountMapping?.id === financialAccount.id) {
      return;
    }

    // If a different account is open, close it first
    if (openFinancialBankAccountMapping !== null) {
      setupAccountProps.onCancel();
    }

    setTimeout(() => {
      setOpenFinancialBankAccountMapping(financialAccount);
      openSetupAccount({
        financialConnectionBankAccount: financialAccount,
        managementGroupId:              financialInstitutionConnection.managementGroup.id,
      });
    }, 0);
  }, [openFinancialBankAccountMapping, openSetupAccount, setupAccountProps]);

  return (
    <>
      <SetupAccountComponent
        { ...setupAccountProps }
        refetchFinancialConnectionList={ refetchFinancialConnectionList }
      />
      <Table
        className="finacial-institution-connection-center-table"
        infiniteQuery={ query }
        wrapperClassName="banking-transactions-table-wrapper"
      >
        <Table.Head>
          <Table.Row>
            <Table.HCell widthPercent={ 20 }>Financial Institution Account</Table.HCell>
            <Table.HCell widthPercent={ 10 }>Last 4 digits</Table.HCell>
            <Table.HCell widthPercent={ 10 }>Management Groups</Table.HCell>
            <Table.HCell widthPercent={ 50 }>Banking Account</Table.HCell>
            <Table.HCell widthPercent={ 5 }>Connection</Table.HCell>
            <Table.HCell widthPercent={ 5 } />
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {financialInstitutionConnections.length > 0 ? (
            <>
              {
                financialInstitutionConnections.map((financialInstitutionConnection) => (
                  <Item
                    key={ financialInstitutionConnection.id }
                    financialInstitutionConnection={ financialInstitutionConnection }
                    handleClickForMapFICAccount={ handleClickForMapFICAccount }
                    openFinancialBankAccountMapping={ openFinancialBankAccountMapping }
                    refetchFinancialConnectionList={ refetchFinancialConnectionList }
                  />
                ))
              }
            </>
          ) : (
            !query.isFetching && (
              <Table.Row>
                <Table.Cell align="center" colSpan={ 6 }>
                  No connection available in this management group
                </Table.Cell>
              </Table.Row>
            )
          )}
        </Table.Body>
      </Table>
    </>
  );
};

export default React.memo(FinancialInstitutionConnectionTable);
