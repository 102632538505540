import React from 'react';

import Tooltip from '@src/components/ui_v2/tooltip';
import { DocytSupportIcon } from '@src/components/utils/icomoon';

import styles from '../styles.module.scss';

interface IDetailsRegionFooterMetricsProps {
  title: string;
  value: string;
  hint?: React.ReactNode;
}

const DetailsRegionFooterMetrics = ({ title, value, hint }: IDetailsRegionFooterMetricsProps) => {
  return (
    <div className={ styles['metrics-item'] }>
      <p className={ styles.title }>
        { `${title}:` }
      </p>

      <p className={ styles.value }>
        { value }
      </p>

      { hint && (
        <Tooltip.Hover content={ hint }>
          <DocytSupportIcon className={ styles.hint } />
        </Tooltip.Hover>
      ) }
    </div>
  );
};

export default React.memo(DetailsRegionFooterMetrics);
