import React from 'react';

import Skeleton from 'react-loading-skeleton';

import Cell, { ITableCellProps } from '../cell';

import 'react-loading-skeleton/dist/skeleton.css';

interface INormalLoadingCellProps extends Omit<ITableCellProps, 'children'> {}

const NormalLoadingCell = ({
  ...cellProps
}: INormalLoadingCellProps) => {
  return (
    <Cell { ...cellProps }>
      <Skeleton />
    </Cell>
  );
};

export default NormalLoadingCell;
