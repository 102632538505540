import React from 'react';

import { IBusinessUser } from '@src/types/business_users';
import { IPortfolioDashboard } from '@src/types/portfolio';

import ShareAction from '@src/components/portfolio_dashboard/actions/share_action';
import { TOption } from '@src/components/ui_v2/inputs';
import { ShareDashboardIcon } from '@src/components/utils/icomoon';

import BusinessesDropdown from './businesses_dropdown';

import styles from './styles.module.scss';

interface IDashboardDetailsHeaderProps {
  dashboard: IPortfolioDashboard,
  onShareDone: (accessibleUsers: IBusinessUser[]) => void,
  options: TOption[],
  selectedOptions: TOption[],
  onChange: (value: TOption[]) => void,
}

const DashboardDetailsHeader = ({
  dashboard,
  onShareDone,
  options,
  selectedOptions,
  onChange,
}: IDashboardDetailsHeaderProps): JSX.Element => {
  
  return (
    <div className={ styles['details-header'] }>
      <div className={ styles['right-wrapper'] }>
        <ShareAction
          currentDashboard={ dashboard }
          prefixIcon={ <ShareDashboardIcon fontSize={ 20 } /> }
          onDone={ onShareDone }
        />
      </div>
    </div>
  );
};

export default DashboardDetailsHeader;
