import React, { Children, isValidElement, ReactNode, useEffect } from 'react';

import BulkAction from './action';
import BulkActionsDropdown from './dropdown';
import SelectedItems from './selected_items';

import styles from '../styles.module.scss';

interface IHeaderRegionBulkActionsProps {
  children: ReactNode,
}

const HeaderRegionBulkActions = ({ children }: IHeaderRegionBulkActionsProps): JSX.Element => {
  useEffect(() => {
    Children.forEach(children, (child) => {
      if (isValidElement(child)) {
        if (child.type !== BulkAction && child.type !== BulkActionsDropdown && child.type !== SelectedItems) {
          throw new Error(
            'Only "HeaderRegion.SelectedItems", "HeaderRegion.BulkActionsDropdown" and "HeaderRegion.BulkAction" '
              + 'components are allowed as children of "HeaderRegion.BulkActions"',
          );
        }
      }
    });
  }, [children]);

  return <div className={ styles['bulk-content'] }>{ children }</div>;
};

export default React.memo(HeaderRegionBulkActions);
