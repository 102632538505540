import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

import { TID } from '@src/types/common';

import Form from '@src/components/ui_v2/form';
import { RadioInput } from '@src/components/ui_v2/inputs';

import CategorySelect from './category_select';
import { addReportTypeValidation, IAddReportTypeValues } from './schema';

interface IAddReportTypeFormProps {
  categories: ICategory[];
  taxes: ICategory[];
  otherLedgers: ICategory[];
  formId: string;
  step: number;
  onSubmit: (data: IAddReportTypeValues) => void;
}
interface ICategory {
  id: TID;
  label: string;
  disabled?: boolean;
}

interface ISelection {
  id: TID;
  label?: string;
  disabled?: boolean;
  isDebit: number;
  isChecked: boolean;
}

function initializeSelection(items: ICategory[]) {
  return items.map((item) => ({
    id:        item.id,
    isDebit:   1,
    isChecked: false,
  }));
}

function getTypeKey(type: number) {
  return type === 1 ? 'selectedCategories' : type === 2 ? 'selectedTaxes' : 'selectedLedger';
}

function toggleChecked(items: ISelection[], id: number) {
  return items.map((item) => (item.id === id ? { ...item, isChecked: !item.isChecked } : item));
}

function updateIsDebit(items: ISelection[], id: number, value: number) {
  return items.map((item) => (item.id === id ? { ...item, isDebit: value } : item));
}

function processSelectedItems(items: ISelection[]) {
  return items
    .filter((item) => item.isChecked)
    .map((item) => ({
      id:      item.id,
      isDebit: item.isDebit === 1,
    }));
}

const STEP_ADD_NEW_REVENUE_MAPPING = 1;
const STEP_SELECT_DATA_FREQUENCY = 2;
const STEP_SELECT_PAYMENT_PROCESSOR = 3;
const STEP_SELECT_REVENUE_CATEGORIES = 4;
const STEP_SELECT_TAX_CATEGORIES = 5;
const STEP_SELECT_OTHER_LEDGERS = 6;

const SELECT_REVENUE_CATEGORIES = 1;
const SELECT_TAX_CATEGORIES = 2;
const SELECT_OTHER_LEDGERS = 3;
const AddReportTypeForm = ({
  formId,
  step,
  onSubmit,
  categories,
  taxes,
  otherLedgers,
}: IAddReportTypeFormProps) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<IAddReportTypeValues>({
    defaultValues: {
      split_revenue_receivables: true,
      frequency:                 'month_to_date',
      category_ids:              [],
      tax_ids:                   [],
      ledger_ids:                [],
    },
    mode:           'onSubmit',
    reValidateMode: 'onChange',
    resolver:       yupResolver(addReportTypeValidation),
  });

  const [formState, setFormState] = useState({
    selectedCategories: initializeSelection(categories),
    selectedTaxes:      initializeSelection(taxes),
    selectedLedger:     initializeSelection(otherLedgers),
  });

  const handleCheckboxChange = (id: number, type: number) => {
    setFormState((prev) => ({
      ...prev,
      [getTypeKey(type)]: toggleChecked(prev[getTypeKey(type)], id),
    }));
  };

  const handleIsDebitChange = (id: number, value: number, type: number) => {
    setFormState((prev) => ({
      ...prev,
      [getTypeKey(type)]: updateIsDebit(prev[getTypeKey(type)], id, value),
    }));
  };

  const handleSubmitForm = (data: IAddReportTypeValues) => {
    const finalData: IAddReportTypeValues = {
      ...data,
      category_ids: processSelectedItems(formState.selectedCategories),
      tax_ids:      processSelectedItems(formState.selectedTaxes),
      ledger_ids:   processSelectedItems(formState.selectedLedger),
    };
    onSubmit(finalData);
  };

  return (
    <Form id={ formId } onSubmit={ handleSubmit(handleSubmitForm) }>
      {step === STEP_ADD_NEW_REVENUE_MAPPING && (
        <>
          <p>Please add your revenue mapping name</p>
          <Form.TextField
            error={ errors.name?.message }
            label="Name"
            placeholder="Type Here"
            { ...register('name') }
          />
        </>
      )}
      {step === STEP_SELECT_DATA_FREQUENCY && (
        <Controller
          control={ control }
          name="frequency"
          render={ ({ field }) => (
            <>
              <RadioInput
                checked={ field.value === 'month_to_date' }
                className="m-b-10"
                title="Month-To-Date (MTD) Basis"
                onChange={ () => field.onChange('month_to_date') }
              />
              <RadioInput
                checked={ field.value === 'daily' }
                title="Per-Day Basis Most people select this"
                onChange={ () => field.onChange('daily') }
              />
            </>
          ) }
        />
      )}
      {step === STEP_SELECT_PAYMENT_PROCESSOR && (
        <Controller
          control={ control }
          name="split_revenue_receivables"
          render={ ({ field }) => (
            <>
              <RadioInput
                checked={ field.value === true }
                className="m-b-10"
                title="Track cash and each merchant processor deposits"
                onChange={ () => field.onChange(true) }
              />
              <RadioInput
                checked={ field.value === false }
                title="Do not track cash and merchant processor deposits"
                onChange={ () => field.onChange(false) }
              />
            </>
          ) }
        />
      )}
      {step === STEP_SELECT_REVENUE_CATEGORIES && (
      <div>
        <h3>Select categories which appear on your Revenue Reports.</h3>
        <p>All changes apply for future reports only.</p>
        <CategorySelect
          categories={ categories }
          formState={ formState.selectedCategories }
          handleCheckboxChange={ handleCheckboxChange }
          handleIsDebitChange={ handleIsDebitChange }
          type={ SELECT_REVENUE_CATEGORIES }
        />
      </div>
      )}
      {step === STEP_SELECT_TAX_CATEGORIES && (
      <div>
        <CategorySelect
          categories={ taxes }
          formState={ formState.selectedTaxes }
          handleCheckboxChange={ handleCheckboxChange }
          handleIsDebitChange={ handleIsDebitChange }
          type={ SELECT_TAX_CATEGORIES }
        />
      </div>
      )}
      {step === STEP_SELECT_OTHER_LEDGERS && (
      <div>
        <CategorySelect
          categories={ otherLedgers }
          formState={ formState.selectedLedger }
          handleCheckboxChange={ handleCheckboxChange }
          handleIsDebitChange={ handleIsDebitChange }
          type={ SELECT_OTHER_LEDGERS }
        />
      </div>
      )}
    </Form>
  );
};

export default AddReportTypeForm;
