import React from 'react';

import { IAchTransaction } from '@src/types/accounts_payable/ach_transactions';

import CancelAchPaymentAction from './cancel_ach_payment_action';
import SendMessageAction from './send_message_action';
import ViewInvoiceAction from './view_invoice_action';

type TAchTransactionsListActionsProps = {
  document: IAchTransaction,
}

const AchTransactionsListActions = ({
  document,
}: TAchTransactionsListActionsProps) => {
  return (
    <>
      <SendMessageAction document={ document } />
      <ViewInvoiceAction document={ document } />
      {
        (document.status !== 'cancelled') && (
          <CancelAchPaymentAction document={ document } />
        )
      }
    </>
  );
};

export default AchTransactionsListActions;
