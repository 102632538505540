import React, { forwardRef } from 'react';

import styles from '../styles.module.scss';

interface IMentionTag {
  name: string;
  avatar: string;
  role: string;
  id: number;
}

interface IMentionListProps {
  mentionFilterText: string | null;
  mentionList: IMentionTag[];
  selectedIndex: number;
  style: React.CSSProperties;
  onSelect: (tag: IMentionTag) => void;
}

export const MentionList = forwardRef<HTMLDivElement, IMentionListProps>(({
  mentionFilterText,
  mentionList,
  selectedIndex,
  style,
  onSelect,
}, ref) => {
  const filteredList = mentionFilterText
    ? mentionList.filter((tag) => tag.name.toLowerCase().includes(mentionFilterText.toLowerCase()))
    : mentionList;

  return (
    <div
      ref={ ref }
      className={ styles['mention-list'] }
      style={ style }
    >
      {filteredList.map((tag, index) => (
        <div
          key={ tag.id }
          className={ `${styles['mention-item']} ${index === selectedIndex ? styles.selected : ''}` }
          role="button"
          tabIndex={ 0 }
          onClick={ () => onSelect(tag) }
        >
          {tag.avatar ? (
            <img alt={ tag.name } className={ styles['small-avatar'] } src={ tag.avatar } />
          ) : (
            <i className="icon icon-smile in-blue-400" />
          )}
          <span>{ tag.name }</span>
          {tag.role && <span className={ styles.role }>{ tag.role }</span>}
        </div>
      ))}
    </div>
  );
});

MentionList.displayName = 'MentionList';

export default MentionList;
