import React, { ReactNode, useMemo, useState } from 'react';

import { ExpandRegionContext, IExpandRegionContextProps } from './hooks';

interface IExpandRegionProviderProps {
  children: ReactNode;
}

const ExpandRegionProvider = ({ children }: IExpandRegionProviderProps) => {
  const [toggleStatus, setToggleStatus ] = useState<boolean>(false);

  const contextValue: IExpandRegionContextProps = useMemo(() => ({
    toggleStatus,
    setToggleStatus
  }), [toggleStatus, setToggleStatus]);
  
  return (
    <ExpandRegionContext.Provider value={ contextValue }>
      { children }
    </ExpandRegionContext.Provider>
  );
};

export default React.memo(ExpandRegionProvider);
