module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="multi-enity-container m-b-20">\n  <div class="p-b-20 p-t-20 item-divider">\n    <div class="display-flex align-items-center m-r-200">\n      <a class="text-decoration-blank font-bold font-22 width-300px" href="/multi_entity_reports/custom_reports/');
    
      __out.push(__sanitize(this.multiBusinessReport.id));
    
      __out.push('?start_date=');
    
      __out.push(__sanitize(this.startDate));
    
      __out.push('&end_date=');
    
      __out.push(__sanitize(this.endDate));
    
      __out.push('"> ');
    
      __out.push(__sanitize(this.multiBusinessReport.get('name')));
    
      __out.push(' </a>\n      <div class="text-decoration-blank font-bold font-22 m-l-auto m-r-auto"> ');
    
      __out.push(__sanitize(this.title));
    
      __out.push(' </div>\n    </div>\n  </div>\n  <div id="multi-custom-report-item-account-value-region" class="p-t-20">\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}