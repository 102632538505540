import { apiGet } from '@src/requests/helpers';
import { underscoreKeys } from '@src/utils/transform_keys';

const openQBOConnectWindow = (businessId: number) => {
  window.addEventListener('message', (event) => {
    if (event.data.type !== 'business:quickbooks:connected') return;

    window.Docyt.vent.trigger('business:quickbooks:connected', event.data.businessId);
  });

  apiGet(
    '/api/v1/cloud_service_authorizations/qbo_authenticate',
    underscoreKeys({ businessId }),
  ).then((data) => {
    window.open(
      data.authentication_url,
      'popUpWindow',
      'height=700, width=800, left=100, top=100, resizable=yes, scrollbars=yes, '
        + 'toolbar=yes, menubar=no, location=no, directories=no, status=yes',
    );
  });
};

export {
  openQBOConnectWindow,
};
