import React from 'react';

import classNames from 'classnames';

import Dropdown from '@src/components/ui_v2/dropdown';
import { ThreeDotsIcon } from '@src/components/utils/icomoon';

import Cell, { ITableCellProps } from './cell';

import styles from './styles.module.scss';

interface ITableAmountCellProps extends ITableCellProps {
  fixed?: boolean,
}

const TableAmountCell = ({
  fixed,
  children,
  className,
  ...cellProps
}: ITableAmountCellProps) => {
  const classes = classNames(
    styles['table-actions-dropdown-cell'],
    {
      [styles.sticky]: fixed,
      [styles.right]:  fixed,
    },
    className,
  );

  return (
    <Cell
      className={ classes }
      { ...cellProps }
    >
      <Dropdown.Provider className={ styles['table-actions-dropdown-content'] }>
        <Dropdown.ToggleButton className={ styles['table-actions-dropdown-toggle-button'] }>
          <ThreeDotsIcon />
        </Dropdown.ToggleButton>
        <Dropdown.Menu>
          { children }
        </Dropdown.Menu>
      </Dropdown.Provider>
    </Cell>
  );
};

export default TableAmountCell;
