import React, { useCallback } from 'react';

import { Button } from '@src/components/ui_v2/buttons';

import { usePushIntegrationConnectModel } from './push_integration_connect_model';

const PushIntegrationConnect = (): JSX.Element => {
  const pushIntegrationConnectModel = usePushIntegrationConnectModel();
  const externalSystemId = 21;
  const externalSystemName = 'Anand Systems';

  const connectPushSystem = useCallback(() => {
    pushIntegrationConnectModel.open();
  }, [pushIntegrationConnectModel]);

  return (
    <>
      <pushIntegrationConnectModel.Component
        externalSystemId={ externalSystemId }
        externalSystemName={ externalSystemName }
        { ...pushIntegrationConnectModel.props }
      />
      <Button
        variant="link"
        onClick={ connectPushSystem }
      >
        Push Integration Connect
      </Button>
    </>
  );
};

export default PushIntegrationConnect;
