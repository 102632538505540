import React from 'react';

import useUserFeatureFlag from '@src/hooks/use_user_feature_flag';

import MultiEntityReportsProvider from '@src/components/providers/multi_entity_reports_provider';
import Root from '@src/components/root';
import DetailsRegion from '@src/components/ui_v2/layout/details_region';
import PageContainer from '@src/components/ui_v2/layout/page_container';

import Router from './router';
import Sidemenu from './sidemenu';

const MultiEntityReportsPage = ({ index }: { index: string }) => {
  const isPortfolioFeatureEnabled = useUserFeatureFlag(window.Docyt.Common.Constants.ENABLE_CREATE_PORTFOLIO_OPTION, true);
  return (
    <Root>
      <MultiEntityReportsProvider>
        <PageContainer sidemenu={ !isPortfolioFeatureEnabled ? <Sidemenu index={ index } /> : null }>
          <DetailsRegion>
            <Router />
          </DetailsRegion>
        </PageContainer>
      </MultiEntityReportsProvider>
    </Root>
  );
};

export default MultiEntityReportsPage;
