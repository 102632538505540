import React, { Children, useMemo, isValidElement, useRef, useEffect } from 'react';

import classNames from 'classnames';

import { TSection } from '@src/types/common';

import { useItemsSelector } from '@src/components/utils_v2/items_selector';

import ActionsArea from './actions_area';
import Breadcrumbs from './breadcrumbs';
import BulkActions from './bulk_actions';
import Tabs from './tabs';
import Title from './title';
import { useDetailsRegion } from '../details_region/hooks';

import styles from './styles.module.scss';

interface IHeaderRegionProps extends React.ComponentPropsWithoutRef<'div'> {
  section?: TSection;
}

const HeaderRegion = ({
  children,
  className,
  section,
  ...props
}: IHeaderRegionProps) => {
  const refElement = useRef<HTMLDivElement | null>(null);

  const classes = classNames(styles['header-region'], className);
  const { setHeaderRegionHeight } = useDetailsRegion();
  const { selected } = useItemsSelector(section);

  const [tabsContent, breadcrumbsContent, actionsAreaContent, titleContent, bulkActionsContent] = useMemo(() => {
    let tabs = null;
    let breadcrumbs = null;
    let bulkActions = null;
    let actionsArea = null;
    let title = null;

    Children.forEach(children, (child) => {
      if (isValidElement(child)) {
        if (child.type === Tabs) {
          tabs = child;
        } else if (child.type === Breadcrumbs) {
          breadcrumbs = child;
        } else if (child.type === ActionsArea) {
          actionsArea = child;
        } else if (child.type === Title) {
          title = child;
        } else if (child.type === BulkActions) {
          bulkActions = child;
        } else {
          throw new Error(
            'Only "HeaderRegion.Tabs", "HeaderRegion.Breadcrumbs", "HeaderRegion.ActionsArea", '
              + '"HeaderRegion.Title" and "HeaderRegion.BulkActions" components are allowed as '
              + 'children of "HeaderRegion"',
          );
        }
      }
    });

    if (tabs && title) {
      throw new Error('"HeaderRegion.Tabs" and "HeaderRegion.Title" components cannot be used at the same.');
    }

    return [tabs, breadcrumbs, actionsArea, title, bulkActions];
  }, [children]);

  useEffect(() => {
    setTimeout(() => {
      if (refElement.current) {
        setHeaderRegionHeight(refElement.current.clientHeight);
      }
    }, 50);
  }, [setHeaderRegionHeight]);

  return (
    <div ref={ refElement } className={ classes } { ...props }>
      { breadcrumbsContent && (
        <div className={ styles['breadcrumbs-content'] }>
          {breadcrumbsContent}
        </div>
      ) }

      { (tabsContent || actionsAreaContent || titleContent) && (
        <div className={ styles['action-content'] }>
          { selected.length === 0 ? (
            <>
              { titleContent }
              { tabsContent }
            </>
          ) : (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>{ bulkActionsContent }</>
          ) }
          { actionsAreaContent }
        </div>
      ) }
    </div>
  );
};

export default React.memo(HeaderRegion);
