import React from 'react';

import classNames from 'classnames';

import { TVendorPaymentStep } from '@src/types/vendor_payments';

import { CheckGreenIcon, ExclamationIcon } from '@src/components/utils/icomoon';
import {
  statusValue,
} from '@src/components/vendors/my_vendors/details/payments/utils';

import styles from './styles.module.scss';

type TVendorStatusStepProps = {
  step: TVendorPaymentStep,
  isActivated?: boolean,
  isChecked?: boolean,
  isProcess?: boolean,
  isProcessActivated?: boolean,
}

const VendorStatusStep = ({
  step,
  isActivated,
  isChecked,
  isProcess,
  isProcessActivated,
}: TVendorStatusStepProps) => {
  const stepClasses = classNames(
    styles.step,
    styles[`step-${step}`],
    { [styles.activated]: isActivated },
    { [styles.processActivated]: isProcessActivated },
    { [styles.checked]: isChecked },
    { [styles.process]: isProcess },
  );

  const stepCircleCheckMark = () => {
    if (!isProcess && isChecked) {
      return (
        <CheckGreenIcon className={ styles['check-mark-icon'] } />
      );
    }
    if (isProcess) {
      return null;
    }
    if (['failed', 'cancelled'].includes(step)) {
      return (
        <ExclamationIcon className={ styles['check-mark-icon'] } />
      );
    }
    return null;
  };

  const stepTextWrapperContent = () => {
    return (
      <div className={ styles['step-title'] }>
        { (statusValue(step)) }
      </div>
    );
  };

  return (
    <div className={ stepClasses }>
      <div className={ styles['step-content'] }>
        <span className={ styles['step-circle'] }>
          { stepCircleCheckMark() }
        </span>
        <div className={ styles['step-text-wrapper'] }>
          { stepTextWrapperContent() }
        </div>
      </div>
    </div>
  );
};

export default VendorStatusStep;
