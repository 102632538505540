import React, { useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';

import { IBusinessVendor } from '@src/types/business_vendors';
import { TID } from '@src/types/common';
import { LDFeatureForBusinessEnabled, LDFeatureValueForBusiness } from '@src/utils/config';

import { Button } from '@src/components/ui_v2/buttons';
import Tooltip from '@src/components/ui_v2/tooltip';
import {
  VendorProfileDetailIcon,
  QBOOnIcon,
  MultiBusinessIcon,
  QBOErrorIcon,
  QBOOffIcon,
  QBOBlockIcon,
} from '@src/components/utils/icomoon';

import AchBankAccountInfo from './vendor_ach_detail';
import AddressBook from './vendor_address_book_detail';
import VendorDetail from './vendor_detail';
import AchBankAction from '../action/ach_action';
import ActionProfile from '../action/profile_action';

import styles from '../styles.module.scss';

interface IProfileProps {
  businessId: TID,
  editLinkLedger: boolean,
  editProfile: boolean,
  vendor: IBusinessVendor,
  setEditLinkLedger: React.Dispatch<React.SetStateAction<boolean>>,
  setEditProfile: React.Dispatch<React.SetStateAction<boolean>>,
  setVendorData: React.Dispatch<React.SetStateAction<any>>,
}

const Profile = ({
  editLinkLedger,
  editProfile,
  vendor,
  businessId,
  setVendorData,
  setEditProfile,
  setEditLinkLedger,
}: IProfileProps) => {
  const isArchive = !!vendor.archivedAt;
  const [show, setShow] = useState<boolean>(false);
  const [dataVendor, setDataVendor] = useState<IBusinessVendor>(vendor);

  const [businessTabEnabled, setBusinessTabEnabled] = useState<string | boolean | undefined>(
    LDFeatureValueForBusiness(
      window.Docyt.Common.Constants.VENDOR_LINKED_BUSINESSES_FLAG,
      String(vendor.businessId),
    ),
  );

  const arrowClasses = classNames(
    `fa fa-caret-down ${styles.arrow}`,
    {
      [styles.active]: show,
    },
  );

  const qboStatusClasses = useCallback((status: boolean) => {
    return classNames(
      styles['qbo-status'],
      {
        [styles.on]:      status,
        [styles.disable]: !status,
      },
    );
  }, []);

  useEffect(() => {
    if (businessTabEnabled === undefined) {
      LDFeatureForBusinessEnabled(
        window.Docyt.Common.Constants.VENDOR_LINKED_BUSINESSES_FLAG,
        String(vendor.businessId),
      ).then((res) => {
        setBusinessTabEnabled(res);
      });
    }
  }, [
    businessTabEnabled,
    setBusinessTabEnabled,
    vendor.businessId,
  ]);

  const QboStatus = useCallback(() => {
    if (
      vendor.syncStatus === window.Docyt.Common.Constants.QBO_SYNC_STATUS.DISABLED
      && !vendor.qboId
    ) {
      return (
        <Tooltip.Hover content="Transaction not pushed to ledger">
          <div className={ styles.item }>
            <QBOBlockIcon fontSize={ 16 } />
            <p className={ qboStatusClasses(false) }>View in Ledger</p>
          </div>
        </Tooltip.Hover>
      );
    }

    if (
      vendor.qboError
      && vendor.qboError !== ''
      && vendor.syncStatus !== window.Docyt.Common.Constants.QBO_SYNC_STATUS.STARTED
    ) {
      return (
        <Tooltip.Hover content={ vendor.qboError }>
          <div className={ styles.item }>
            <QBOErrorIcon fontSize={ 16 } />
            <p className={ qboStatusClasses(false) }>View in Ledger</p>
          </div>
        </Tooltip.Hover>
      );
    }

    if (
      vendor.qboId
      && vendor.qboLink && vendor.qboLink !== ''
      && vendor.syncStatus !== window.Docyt.Common.Constants.QBO_SYNC_STATUS.STARTED
    ) {
      return (
        <Button className={ styles.item } variant="link" onClick={ () => window.open(vendor.qboLink) }>
          <QBOOnIcon fontSize={ 16 } />
          <p className={ qboStatusClasses(true) }>View in Ledger</p>
        </Button>
      );
    }

    return (
      <Tooltip.Hover
        content={
          vendor.syncStatus !== window.Docyt.Common.Constants.QBO_SYNC_STATUS.STARTED
            ? 'This vendor is not connected to QuickBooks'
            : 'QuickBooks sync has been initiated'
        }
      >
        <div className={ styles.item }>
          <QBOOffIcon fontSize={ 16 } />
          <p className={ qboStatusClasses(false) }>View in Ledger</p>
        </div>
      </Tooltip.Hover>
    );
  }, [vendor, qboStatusClasses]);

  const handleShowItem = useCallback(() => {
    setShow(!show);
  }, [show, setShow]);

  const handleDeleteBank = () => {
    setDataVendor({
      ...dataVendor,
      dwollaFundingSource: undefined,
    });
  };

  useEffect(() => {
    setDataVendor(vendor);
  }, [vendor]);

  return (
    <div className={ styles['vendor-profile-content'] }>
      <div className={ styles['vendor-profile-first-content-row'] }>
        <div className={ styles['section-content-container'] }>
          <div className={ styles.header }>
            <div className={ styles.item }>
              {vendor.imageUrl ? (
                <img
                  alt="Avatar"
                  className={ styles['avatar-detail'] }
                  src={ vendor.imageUrl }
                />
              ) : (
                <VendorProfileDetailIcon fontSize={ 24 } />
              )}
              <span className={ styles.title }>Vendor Details</span>
              {isArchive && (
              <span className={ styles['archived-label'] }>Archived</span>
              )}
            </div>
            <div className={ styles.item }>
              {vendor.isLinkedToBusinesses && businessTabEnabled && (
              <Tooltip.Hover content="This vendor is linked to multiple businesses" popoverParams={ { showDelay: 0 } }>
                <MultiBusinessIcon fontSize={ 20 } />
              </Tooltip.Hover>
              )}
              <QboStatus />
              <ActionProfile
                editLinkLedger={ editLinkLedger }
                editProfile={ editProfile }
                setEditLinkLedger={ setEditLinkLedger }
                setEditProfile={ setEditProfile }
                setVendorData={ setVendorData }
                vendor={ vendor }
              />
            </div>
          </div>
          <div className={ styles['section-content'] }>
            <VendorDetail show={ show } vendor={ vendor } />
            <div className={ styles['info-show-status'] }>
              <Button className={ styles.item } variant="link" onClick={ handleShowItem }>
                {show ? <p>Hide advanced info</p> : <p>Show advanced info</p>}
                <span className={ arrowClasses } />
              </Button>
            </div>
          </div>
        </div>

        <div className={ styles['section-content-container'] }>
          <div className={ styles.header }>
            <div className={ styles.item }>
              <span className={ styles['ach-title'] }>ACH Bank Account Info</span>
            </div>
            {dataVendor.dwollaFundingSource?.id && (
              <AchBankAction handleDeleteBank={ handleDeleteBank } vendor={ vendor } />
            )}
          </div>
          <div className={ styles['section-content'] }>
            <AchBankAccountInfo
              businessId={ businessId }
              setVendorData={ setVendorData }
              vendor={ dataVendor }
            />
          </div>
        </div>
      </div>
      <AddressBook
        businessId={ businessId }
        setVendorData={ setVendorData }
        vendor={ dataVendor }
      />
    </div>
  );
};

export default Profile;
