import React, { useCallback, useMemo, useState, useEffect } from 'react';

import flatten from 'lodash/flatten';

import toastr from '@lib/toastr';
import { useGetFinancialInstitutionConnectionInfiniteScroll } from '@src/hooks/queries/financial_institution_connection';
import { useURLParams } from '@src/hooks/url_params';
import { bankConnectionCenterPath } from '@src/routes';
import { TID } from '@src/types/common';
import { TFinancialInstitutionConnectionSortColumn } from '@src/types/financial_institution_connection';
import { ISortingParams } from '@src/types/sorting';
import { backboneNavigateToUrl } from '@src/utils/navigate';

import SideView from '@src/components/ui/side_view';
import Spinner from '@src/components/ui/spinner';

import useConnectFinancialInstitutionModal from './connect_financial_institution/modal';
import { IFinancialInstitutionConnectionFilter } from './filter';
import BlankList from './list_item/blank_list';
import { useEmployeeCardMappingList } from './list_item/employee_card_mapping/employee_card_mapping_list';
import { useEmployeeCardMappingModal } from './list_item/employee_card_mapping/employee_card_mapping_modal';
import List from './list_item/list';

const DefaultSorting: ISortingParams<TFinancialInstitutionConnectionSortColumn> = {
  orderColumn:    'name',
  orderDirection: 'asc',
};

interface IFinancialInstitutionConnectionDetailsProps {
  businessId?: TID,
  token?: string,
}

interface ITokenData {
  isCreateMappingRedirection: boolean;
  financialInstitutionBankAccountId: string;
  managementGroupId: string;
  reconciliationPaymentAccountId: string;
}

const FinancialInstitutionConnectionDetails = ({
  businessId,
  token,
}: IFinancialInstitutionConnectionDetailsProps): JSX.Element => {
  const { filter } = useURLParams<
    IFinancialInstitutionConnectionFilter,
    TFinancialInstitutionConnectionSortColumn
  >({
    section:        window.Docyt.Common.Constants.FINANCIAL_INSTITUTION_CONNECTION_TYPE,
    defaultSorting: DefaultSorting,
  });

  const [isRefetching, setIsRefetching] = useState<boolean>(false);

  const query = useGetFinancialInstitutionConnectionInfiniteScroll({ filter: filter.data });
  const financialInstitutionConnectionsPages = useMemo(() => {
    return query.data?.pages || [];
  }, [query.data?.pages]);

  const financialInstitutionConnections = useMemo(() => {
    return flatten(financialInstitutionConnectionsPages.map((item) => {
      return item.collection;
    }));
  }, [financialInstitutionConnectionsPages]);

  const noFilteredCount = query.data?.pages[0].meta.noFilteredCount || 0;

  const connectFinancialInstitutionModal = useConnectFinancialInstitutionModal();

  const tokenData: ITokenData | null = useMemo(() => {
    if (!token) return null;
    try {
      return JSON.parse(window.atob(token));
    } catch (error) {
      toastr.error(`Something went wrong: ${error}`, 'Error');
      return null;
    }
  }, [token]);

  const handleEmployeeCardMappingModalClose = useCallback(() => {
    if (tokenData && businessId) {
      backboneNavigateToUrl(`${bankConnectionCenterPath(businessId)}`);
    }
  }, [tokenData, businessId]);

  const {
    Component: EmployeeCardMappingModalComponent,
    props: employeeCardMappingModalProps,
    openWithValue: openEmployeeCardMappingModal,
  } = useEmployeeCardMappingModal({
    onDone:   handleEmployeeCardMappingModalClose,
    onCancel: handleEmployeeCardMappingModalClose,
  });

  const {
    Component: EmployeeCardMappingListComponent,
    props: employeeCardMappingListProps,
    openWithValue: openEmployeeCardMappingList,
  } = useEmployeeCardMappingList({
    onDone:   handleEmployeeCardMappingModalClose,
    onCancel: handleEmployeeCardMappingModalClose,
  });

  const refetchFIC = useCallback(() => {
    setIsRefetching(true);
    query.refetch().finally(() => {
      setIsRefetching(false);
    });
  }, [query]);

  const financialInstitutionBankAccountId = tokenData ? Number(tokenData.financialInstitutionBankAccountId) : undefined;
  const managementGroupId = tokenData ? Number(tokenData.managementGroupId) : undefined;
  const reconciliationPaymentAccountId = tokenData ? Number(tokenData.reconciliationPaymentAccountId) : undefined;

  useEffect(() => {
    if (tokenData && businessId && financialInstitutionBankAccountId) {
      if (tokenData.isCreateMappingRedirection) {
        openEmployeeCardMappingModal({
          reconciliationPaymentAccountId,
          financialInstitutionBankAccountId,
          businessId,
        });
      } else {
        openEmployeeCardMappingList({
          reconciliationPaymentAccountId,
          financialInstitutionBankAccountId,
        });
      }
    }
  }, [businessId, openEmployeeCardMappingModal, financialInstitutionBankAccountId, reconciliationPaymentAccountId, openEmployeeCardMappingList, tokenData]);

  return (
    <div>
      <SideView.Provider>
        <connectFinancialInstitutionModal.Component
          { ...connectFinancialInstitutionModal.props }
          financialInstitutionConnections={ financialInstitutionConnections }
        />
        {isRefetching && (<Spinner />)}
        {
          noFilteredCount === 0 && !query.isLoading
            ? (
              <BlankList
                onConnectFinancialInstitution={ connectFinancialInstitutionModal.open }
              />
            ) : (
              <List
                businessId={ businessId }
                filter={ filter }
                financialInstitutionConnections={ financialInstitutionConnections }
                query={ query }
                refetchFinancialConnectionList={ refetchFIC }
                onConnectFinancialInstitution={ connectFinancialInstitutionModal.open }
              />
            )
        }

        {managementGroupId && (
          <EmployeeCardMappingModalComponent
            { ...employeeCardMappingModalProps }
            managementGroupId={ managementGroupId }
            refetchFinancialConnectionList={ refetchFIC }
          />
        )}
        <EmployeeCardMappingListComponent
          { ...employeeCardMappingListProps }
          refetchFinancialConenctionList={ refetchFIC }
        />
        <SideView.Render />
      </SideView.Provider>
    </div>
  );
};

export default FinancialInstitutionConnectionDetails;
