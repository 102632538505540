import { TID } from '@src/types/common';
import { TFayeEventCallback } from '@src/types/docyt_events/common';
import { IJournalEntryFetchedFayeEvent } from '@src/types/docyt_events/journal_entry_fetched';

import { useFayeChannel } from './common';

export const useJournalEntryFetchedFayeChannel = (
  qboID: string,
  businessId: TID,
  callback: TFayeEventCallback<IJournalEntryFetchedFayeEvent>,
) => {
  useFayeChannel(
    `/journal_entry_qbo_id-${businessId}-${qboID}`,
    callback,
  );
};
