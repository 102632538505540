import React, { ComponentPropsWithoutRef, useCallback, useRef } from 'react';

import DatePicker, { CalendarContainer, CalendarContainerProps } from 'react-datepicker';

import { TDateRange } from '@src/types/common';
import {
  DATEFNS_DATE_FORMAT,
  MOMENT_MONTH_FORMAT,
  endOfWeekApiDate,
  formatApiDate,
  parseApiDate,
  startOfWeekApiDate,
} from '@src/utils/date_helpers';
import { uiStyleProps } from '@src/utils/ui_style_helpers';

import DateRangeInnerInput from './date_range_inner_input';
import PeriodSelector from './date_range_period_selector';
import { TPeriod } from './types';

import 'react-datepicker/dist/react-datepicker.css';

import styles from './styles.module.scss';

interface IDateRangeInputProps extends Omit<ComponentPropsWithoutRef<'div'>, 'onChange'> {
  value?: TDateRange;
  placeholder?: string;
  endPlaceholder?: string;
  minDate?: string;
  maxDate?: string;
  onChange?: (value: TDateRange) => void;
  hidePeriods?: boolean;
  filterDate?(startDate: Date, endDate: Date): boolean;
  hideClear?: boolean;
  hideAllTime?: boolean;
  periodType?: 'daily' | 'weekly' | 'monthly';
}

const DateRangeInput = ({
  hideAllTime,
  placeholder = 'Start Date – End Date',
  value,
  onChange,
  hidePeriods,
  filterDate,
  periodType = 'daily',
  ...props
}: IDateRangeInputProps) => {
  const pickerRef = useRef<DatePicker>(null);
  const [classes] = uiStyleProps(styles['date-range-input'], props);

  const handleChange = useCallback(
    ([startDate, endDate]: [Date | null, Date | null]) => {
      if (!onChange) return;

      const apiStartDate = formatApiDate(startDate);
      const apiEndDate = formatApiDate(endDate);

      if (value?.startDate === apiStartDate && value?.endDate === apiEndDate) return;

      if (periodType === 'weekly') {
        const startOfWeek = apiStartDate ? startOfWeekApiDate(parseApiDate(apiStartDate)) : '';
        const endOfWeek = apiEndDate ? endOfWeekApiDate(parseApiDate(apiEndDate)) : '';
        onChange({ startDate: startOfWeek, endDate: endOfWeek });
      } else {
        onChange({ startDate: apiStartDate, endDate: apiEndDate });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value, onChange],
  );

  const handlePeriodSelected = useCallback(
    (period: TPeriod) => {
      handleChange([period.startDate || null, period.endDate || null]);

      pickerRef.current?.setOpen(false);
    },
    [handleChange],
  );

  // I need to place this component here because there is no another way to pass
  // additional properties to the container because we pass only container function
  // and it's rendered inside of date picker.
  const DataRangeContainer = useCallback(
    ({ children, className }: CalendarContainerProps) => {
      return (
        <div className={ styles['date-input-container'] }>
          <div className={ styles['date-input-calendar'] }>
            <CalendarContainer className={ className }>{ children }</CalendarContainer>
          </div>
          {!hidePeriods && (
            <PeriodSelector
              hideAllTime={ hideAllTime }
              minDate={ props.minDate }
              onPeriodSelected={ handlePeriodSelected }
            />
          )}
        </div>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handlePeriodSelected, hidePeriods],
  );

  const handleClear = useCallback(() => {
    handleChange([null, null]);
  }, [handleChange]);

  return (
    <DatePicker
      ref={ pickerRef }
      selectsRange
      autoComplete="off"
      calendarContainer={ DataRangeContainer }
      className={ classes }
      customInput={ <DateRangeInnerInput hideClear={ props.hideClear } onClear={ handleClear } /> }
      dateFormat={ periodType === 'monthly' ? MOMENT_MONTH_FORMAT : DATEFNS_DATE_FORMAT }
      endDate={ parseApiDate(value?.endDate) }
      filterDate={
        (filterDate
          && value?.startDate
          && ((endDate) => filterDate(parseApiDate(value?.startDate)!, endDate)))
          || undefined
      }
      maxDate={ parseApiDate(props.maxDate) }
      minDate={ parseApiDate(props.minDate) }
      placeholderText={ placeholder }
      popperClassName={ styles['date-input-popper'] }
      showMonthYearPicker={ periodType === 'monthly' }
      startDate={ parseApiDate(value?.startDate) }
      onChange={ handleChange }
    />
  );
};

const MemoizedDateRangeInput = React.memo(DateRangeInput);

export { MemoizedDateRangeInput as default };
