import React, { useCallback, useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { useController, useForm } from 'react-hook-form';

import { useUploadVendorProfileLogo } from '@src/hooks/queries/vendors';
import { IBusinessVendor } from '@src/types/business_vendors';

import { Button } from '@src/components/ui_v2/buttons';
import Form from '@src/components/ui_v2/form';
import { CheckboxInput, SelectInput, TOption } from '@src/components/ui_v2/inputs';
import QueryStatus from '@src/components/utils/query_status';

import { vendorProfileEditValidation, IVendorProfileParams } from './schema';

import styles from '../../styles.module.scss';

interface IVendorProfileEditFormProps {
  formId: string,
  vendor: IBusinessVendor,
  onSubmit: (data: IVendorProfileParams) => void,
}

const selectOptions: TOption[] = [
  {
    label: 'Docyt ACH',
    value: 'Docyt ACH',
  },
  {
    label: 'Docyt Check',
    value: 'Docyt Check',
  },
];

const VendorProfileEditForm = ({ formId, vendor, onSubmit }: IVendorProfileEditFormProps) => {
  const fileRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [warning, setWarningText] = useState<string>('');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<TOption | null>(null);

  const classes = classNames(styles['form-item'], styles['checkbox-item']);

  const uploadLogoQuery = useUploadVendorProfileLogo(file as File);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IVendorProfileParams>({
    defaultValues: {
      name:                           vendor.name,
      preferredPaymentMethod:         vendor.preferredPaymentMethod,
      website:                        vendor.website,
      email:                          vendor.email,
      phone:                          vendor.phone,
      fax:                            vendor.fax,
      eligibility1099:                vendor.eligibility1099,
      useTaxes:                       vendor.useTaxes,
      isAiInvoiceLineItemsExtraction: vendor.isAiInvoiceLineItemsExtraction,
    },
    mode:     'onBlur',
    resolver: yupResolver(vendorProfileEditValidation),
  });

  const logoImageUrlController = useController({
    name: 'imageUrl',
    control,
  });

  const paymentMethodController = useController({
    name: 'preferredPaymentMethod',
    control,
  });

  const eligibilityController = useController({
    name: 'eligibility1099',
    control,
  });

  const taxesController = useController({
    name: 'useTaxes',
    control,
  });

  const extractionController = useController({
    name: 'isAiInvoiceLineItemsExtraction',
    control,
  });

  const logoController = useController({
    name: 'logo',
    control,
  });

  const logoTypeController = useController({
    name: 'logoType',
    control,
  });

  const handleFileBrowser = () => {
    fileRef?.current?.click();
  };

  useEffect(() => {
    logoImageUrlController.field.onChange(vendor.imageUrl);
    setSelectedPaymentMethod(
      selectOptions.find((option: TOption) => (
        option.value === paymentMethodController.field.value
      )) as TOption | null,
    );
  }, [
    vendor,
    setSelectedPaymentMethod,
    paymentMethodController,
    logoImageUrlController.field,
  ]);

  useEffect(() => {
    if (uploadLogoQuery.isSuccess) {
      const imgUrl = `https://${window.configData.vendor_logos_bucket}.s3.amazonaws.com/${uploadLogoQuery.data}`;
      logoImageUrlController.field.onChange(imgUrl);
      logoController.field.onChange(uploadLogoQuery.data);
      logoTypeController.field.onChange(window.configData.logo_types.BUSINESS_VENDOR);
    }

    setFile(undefined);
  }, [
    uploadLogoQuery,
    setFile,
    logoImageUrlController.field,
    logoController.field,
    logoTypeController.field,
  ]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if (
        e.target.files[0]?.type !== 'image/jpeg'
        && e.target.files[0]?.type !== 'image/png'
        && e.target.files[0]?.type !== 'image/jpg'
      ) {
        setWarningText('File format is not supported.');
        return;
      }
      setWarningText('');

      setFile(e.target.files[0]);
    }
  };

  const handleSelectEligibility = useCallback((checked: boolean) => {
    eligibilityController.field.onChange(checked);
  }, [eligibilityController]);

  const handleSelectTaxes = useCallback((checked: boolean) => {
    taxesController.field.onChange(checked);
  }, [taxesController]);

  const handleSelectExtraction = useCallback((checked: boolean) => {
    extractionController.field.onChange(checked);
  }, [extractionController]);

  const handleChangePaymentMethod = useCallback((option: TOption | null) => {
    setSelectedPaymentMethod(option);
    paymentMethodController.field.onChange(option?.value);
  }, [paymentMethodController]);

  return (
    <>
      <Form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
        <div className={ styles['form-edit-content'] }>
          <div>
            <div className={ styles['display-section'] }>
              <div className={ styles['avatar-content'] }>
                <input
                  ref={ fileRef }
                  accept=".png, .jpeg, .jpg"
                  className={ styles['display-none'] }
                  type="file"
                  onChange={ handleFileChange }
                />
                <Button variant="link" onClick={ handleFileBrowser }>
                  <img
                    alt="Avatar"
                    className={ styles.avatar }
                    src={ logoImageUrlController.field.value }
                  />
                </Button>
                <p className={ styles['warning-msg'] }>{ warning }</p>
              </div>
              <div className={ styles['form-content'] }>
                <p className={ styles['label-field'] }>Display Name</p>
                <div className={ styles['value-field'] }>
                  <Form.TextField
                    hiddenLabel
                    { ...register('name') }
                    className={ styles['field-bg'] }
                    error={ errors.name?.message }
                    label=""
                    placeholder="Small, Simpler Name"
                  />
                </div>
              </div>
            </div>
            <div className={ styles['section-line'] }>
              <p className={ styles['line-txt'] }>PAYEE PAYMENT INFO</p>
            </div>
            <div className={ styles['form-item'] }>
              <div className={ classNames(styles['form-content'], styles['m-b-10']) }>
                <p className={ classNames(styles['label-field'], styles['m-t-0']) }>Preferred Payment Method</p>
                <div className={ styles['value-field'] }>
                  <SelectInput
                    hideClear
                    options={ selectOptions }
                    placeholder="Select"
                    styles={ {
                      control: (provided) => ({
                        ...provided,
                        'background':   'rgb(0 0 0 / 4%)',
                        'borderRadius': '6px',
                        'border':       'none',
                        'cursor':       'pointer',
                        'color':        '#242b33',
                        'outline':      'none',
                        'borderColor':  '#242b33',
                        'boxShadow':    'none',
                        '&:hover':      {
                          borderColor: '#242b33',
                        },
                      }),
                    } }
                    value={ selectedPaymentMethod }
                    onChange={ handleChangePaymentMethod }
                  />
                </div>
              </div>
            </div>
            <div className={ styles['section-line'] }>
              <p className={ styles['line-txt'] }>PAYEE CONTACT INFO</p>
            </div>
            <div className={ styles['form-item'] }>
              <div className={ styles['form-content'] }>
                <p className={ styles['label-field'] }>Website</p>
                <div className={ styles['value-field'] }>
                  <Form.TextField
                    { ...register('website') }
                    hiddenLabel
                    className={ styles['field-bg'] }
                    label=""
                    placeholder="www.website.com"
                  />
                </div>
              </div>
              <div className={ styles['form-content'] }>
                <p className={ styles['label-field'] }>Email</p>
                <div className={ styles['value-field'] }>
                  <Form.TextField
                    { ...register('email') }
                    hiddenLabel
                    className={ styles['field-bg'] }
                    label=""
                    placeholder="vendor@email.com"
                  />
                </div>
              </div>
              <div className={ styles['form-content'] }>
                <p className={ styles['label-field'] }>Phone</p>
                <div className={ styles['value-field'] }>
                  <Form.TextField
                    { ...register('phone') }
                    hiddenLabel
                    className={ styles['field-bg'] }
                    label=""
                    placeholder="Phone Number"
                  />
                </div>
              </div>
              <div className={ styles['form-content'] }>
                <p className={ styles['label-field'] }>Fax</p>
                <div className={ styles['value-field'] }>
                  <Form.TextField
                    { ...register('fax') }
                    hiddenLabel
                    className={ styles['field-bg'] }
                    label=""
                    placeholder="Fax Number"
                  />
                </div>
              </div>
            </div>
            <div className={ styles['section-line'] }>
              <p className={ styles['line-txt'] }>TAX INFO</p>
            </div>
            <div className={ classes }>
              <CheckboxInput
                checked={ eligibilityController.field.value }
                title="Track Vendor for 1099"
                value={ eligibilityController.field.value ? 1 : 0 }
                onChange={ handleSelectEligibility }
              />
              <CheckboxInput
                checked={ taxesController.field.value }
                title="Track Vendor for Use Taxes"
                value={ taxesController.field.value ? 1 : 0 }
                onChange={ handleSelectTaxes }
              />
            </div>

            { vendor.canAccessAiInvoiceLineItemsExtraction && (
              <>
                <div className={ styles['section-line'] }>
                  <p className={ styles['line-txt'] }>OTHER INFO</p>
                </div>
                <div className={ classes }>
                  <CheckboxInput
                    checked={ extractionController.field.value }
                    title="AI Invoice Line Items Detection"
                    value={ extractionController.field.value ? 1 : 0 }
                    onChange={ handleSelectExtraction }
                  />
                  <p className={ styles['help-txt'] }>
                    This would help extracting the line items from an invoice automatically.
                    You can edit it. AI will still need to learn from manually inputted data.
                  </p>
                </div>
              </>
            ) }
          </div>
          <div className={ styles['form-btn-content'] }>
            <Button
              className={ styles['fomr-btn'] }
              form={ formId }
              type="submit"
              variant="primary"
            >
              Save Vendor
            </Button>
          </div>
        </div>
      </Form>
      <QueryStatus query={ uploadLogoQuery } />
    </>
  );
};

export default React.memo(VendorProfileEditForm);
