import React from 'react';

import { IBusinessVendor } from '@src/types/business_vendors';

import Section from '@src/components/utils_v2/section';

import { useVendorAddressBookCollection } from './hooks';
import Table from './options/address_option';

interface IVendorAddressBookProps
{
  businessVendor: IBusinessVendor,
  setVendorData: React.Dispatch<React.SetStateAction<IBusinessVendor>>,
}

const VendorAddressBook = ({
  businessVendor,
  setVendorData,
}: IVendorAddressBookProps) => {
  const collection = useVendorAddressBookCollection({ businessVendor });

  return (
    <Section.Provider section={ collection.section }>
      <Table
        infiniteQuery={ collection.query }
        records={ collection.records }
        setVendorData={ setVendorData }
        sorting={ collection.sorting }
        vendor={ businessVendor }
      />
    </Section.Provider>
  );
};

export default React.memo(VendorAddressBook);
