import React, { useCallback } from 'react';

import classNames from 'classnames';
import { useSetRecoilState } from 'recoil';

import { useRefreshReport } from '@src/hooks/queries/report_service/custom_reports';
import { useUpdateReportDatas } from '@src/hooks/queries/report_service/report_datas';
import { IReport } from '@src/types/report_service/report';
import { formatApiDate } from '@src/utils/date_helpers';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import Dropdown from '@src/components/ui_v2/dropdown';
import PeriodSelector from '@src/components/ui_v2/inputs/date_input/date_range_period_selector';
import { UpdateReportIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

import { reportUpdatingState } from '../../atoms';

import styles from '../styles.module.scss';

interface IUpdateReportActionProps {
  report: IReport,
  handleReportNavigation: () => void
}

const UpdateReportAction = ({
  report,
  handleReportNavigation
}: IUpdateReportActionProps): JSX.Element => {
  const updateReport = useUpdateReportDatas();
  const updateReportAllTime = useRefreshReport();
  const setReportUpdatingState = useSetRecoilState(reportUpdatingState);

  const renderCustomOption = () => {
    return (
      <Dropdown.Item
        className={ styles['date-range-period'] }
        onSelect={ handleReportNavigation }
      >
        <Dropdown.ItemLabel>Custom</Dropdown.ItemLabel>
      </Dropdown.Item>
    )
  }

  const handleUpdateReport = useCallback((period) => {
    const startDate = formatApiDate(period.startDate);
    const endDate = formatApiDate(period.endDate);
    if (period.label === 'All time') {
      updateReportAllTime.mutate(report.id, {
        onSuccess: () => {
          setReportUpdatingState(`${report.name}: Update in progress`);
        },
      });
    } else {
      updateReport.mutate({
        reportId: report.id,
        startDate,
        endDate,
        periodType: report.periodType,
      }, {
        onSuccess: () => {
          setReportUpdatingState(`${report.name}: Update in progress`);
        },
      });
    }
  }, [updateReport, updateReportAllTime, report.id, report.name, report.periodType, setReportUpdatingState]);

  const dropdownItemWithDateRange = classNames(
    styles['date-range-dropdown'],
    styles['dropdown-item'],
  );

  return (
    <>
      <ActionsDropdown.Submenu
        className={ dropdownItemWithDateRange }
        icon={ <UpdateReportIcon fontSize={ 18 } /> }
        title="Update Report"
      >
        <PeriodSelector
          hideCalenderMonths
          monthlyPeriods
          customOptions={ renderCustomOption }
          onPeriodSelected={ handleUpdateReport }
        />
      </ActionsDropdown.Submenu>
      <MutationStatus mutation={ updateReport } />
    </>
  );
};

export default UpdateReportAction;
