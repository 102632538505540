import { createContext, useContext } from 'react';

interface IExpandRegionContextProps {
  toggleStatus: boolean,
  setToggleStatus?: (status: boolean) => void,
}

const ExpandRegionContext = createContext<IExpandRegionContextProps | null>(null);

const useExpandRegion = (): IExpandRegionContextProps => {
  const context = useContext(ExpandRegionContext);

  if (!context) {
    return {
      toggleStatus: false,
    };
  }

  return context;
}

export { ExpandRegionContext, useExpandRegion, IExpandRegionContextProps };