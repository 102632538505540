import React, { useCallback } from 'react';

import { TID } from '@src/types/common';
import { IVendorPayment, TVendorPaymentsSortColumn } from '@src/types/vendor_payments';

import CollectionTable from '@src/components/ui_v2/collection_table';
import { ReceiptDocumentIcon, RevenueNavIcon } from '@src/components/utils/icomoon';
import { ApServiceLogoIcon } from '@src/components/utils/img_icons';

import InvoiceQueueFilter from '../filter';
import { IVendorPaymentsCollection } from '../hooks';
import StatusItem from '../status_item';
import SummaryInfoItem from '../summary_info_item/summary_info_item';
import { formatPaymentMethod, ICON_FONT_SIZE } from '../utils';

import styles from '../styles.module.scss';

interface IVendorPaymentsTableProps {
  bussinessId: TID,
  collection: IVendorPaymentsCollection
}

const VendorPaymentsTable = ({ bussinessId, collection }: IVendorPaymentsTableProps) => {
  const invoiceNoLabel = useCallback((vendorPayment: IVendorPayment) => {
    return (
      <SummaryInfoItem columnName="invoiceNo" vendorPayment={ vendorPayment } />
    );
  }, []);

  const paymentAmountLabel = useCallback((vendorPayment: IVendorPayment) => (
    vendorPayment?.amount !== null ? (
      <span className={ styles['payment-amount-label'] }>
        $
        {vendorPayment.amount}
      </span>
    ) : (
      <span>-</span>
    )
  ), []);

  const paymentStatusLabel = (vendorPayment: IVendorPayment) => {
    return (
      <StatusItem vendorPayment={ vendorPayment } />
    );
  };

  const charOfAccountLabel = useCallback((vendorPayment: IVendorPayment) => {
    return (
      <SummaryInfoItem columnName="chartOfAccount" vendorPayment={ vendorPayment } />
    );
  }, []);

  const accrualDateLabel = useCallback((vendorPayment: IVendorPayment) => {
    return (
      <SummaryInfoItem columnName="accrualDate" vendorPayment={ vendorPayment } />
    );
  }, []);

  const vendorPaymentIcon = useCallback((vendorPayment: IVendorPayment) => {
    if (vendorPayment.businessAdvisorType === 'receipts') {
      return <ReceiptDocumentIcon fontSize={ ICON_FONT_SIZE } />;
    }

    if (vendorPayment.businessAdvisorType === 'invoices') {
      return <ApServiceLogoIcon size="50%" />;
    }

    return <RevenueNavIcon className={ styles['icon-label'] } fontSize={ ICON_FONT_SIZE } />;
  }, []);

  return (
    <CollectionTable<IVendorPayment, TVendorPaymentsSortColumn>
      isRegionScroll
      className={ styles['payment-table'] }
      filter={ <InvoiceQueueFilter businessId={ bussinessId } /> }
      height="calc(100vh - 150px)"
      query={ collection.query }
      records={ collection.records }
      showSettings={ false }
      sorting={ collection.sorting }
    >
      <CollectionTable.TextColumn<IVendorPayment, TVendorPaymentsSortColumn>
        name="payment_date"
        sortColumn="payment_date"
        title="Payment Date"
        value={ (payment) => payment?.paymentDate || '-' }
        width="6%"
      />
      <CollectionTable.TextColumn<IVendorPayment, TVendorPaymentsSortColumn>
        hideValueTooltip
        name="invoiceNo"
        sortColumn="invoice_number"
        title="Docyt ID"
        value={ invoiceNoLabel }
        width="6%"
      />
      <CollectionTable.TextColumn<IVendorPayment, TVendorPaymentsSortColumn>
        name="docDate"
        sortColumn="document_date"
        title="Doc Date"
        value={ (payment) => payment?.documentDate || '-' }
        width="6%"
      />
      <CollectionTable.TextColumn<IVendorPayment, TVendorPaymentsSortColumn>
        hideValueTooltip
        name="paymentMethod"
        title="Payment Method"
        value={ (payment) => formatPaymentMethod(payment?.paymentMethod) || '-' }
        width="8%"
      />
      <CollectionTable.TextColumn<IVendorPayment, TVendorPaymentsSortColumn>
        hideValueTooltip
        name="paymentStatus"
        title="Payment Status"
        value={ paymentStatusLabel }
        width="8%"
      />
      <CollectionTable.TextColumn<IVendorPayment, TVendorPaymentsSortColumn>
        hideValueTooltip
        name="paymentAccount"
        title="Payment Account"
        value={ (payment) => (payment?.paymentAccountLast4 ? `**** ${payment?.paymentAccountLast4}` : '-') }
        width="8%"
      />
      <CollectionTable.TextColumn<IVendorPayment, TVendorPaymentsSortColumn>
        hideValueTooltip
        name="chartOfAccount"
        title="Chart of Account"
        value={ charOfAccountLabel }
        width="8%"
      />
      <CollectionTable.TextColumn<IVendorPayment, TVendorPaymentsSortColumn>
        hideValueTooltip
        name="accountNo"
        title="Account No"
        value={ (payment) => payment?.accountNumber || '-' }
        width="8%"
      />
      <CollectionTable.TextColumn<IVendorPayment, TVendorPaymentsSortColumn>
        hideValueTooltip
        name="accrualPeriod"
        title="Accrual Period"
        value={ accrualDateLabel }
        width="8%"
      />
      <CollectionTable.TextColumn<IVendorPayment, TVendorPaymentsSortColumn>
        hideValueTooltip
        name="amount"
        sortColumn="amount"
        title="Amount"
        value={ paymentAmountLabel }
        width="5%"
      />
      <CollectionTable.TextColumn<IVendorPayment, TVendorPaymentsSortColumn>
        hideValueTooltip
        name="icon"
        title=""
        value={ vendorPaymentIcon }
        width="3%"
      />
    </CollectionTable>
  );
};

export default React.memo(VendorPaymentsTable);
