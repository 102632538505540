import React, { useCallback, useState } from 'react';

import toastr from '@lib/toastr';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useUpdateReconciliationPaymentAccount } from '@src/hooks/queries/reconciliation_payment_accounts';
import { TAccountType, TID } from '@src/types/common';
import { IFinancialInstitutionBankAccount } from '@src/types/financial_institution_bank_account';
import { formatDate } from '@src/utils/date_helpers';

import { useConfirmLastFourModal } from '@src/components/banking_accounts/accounts/confirm_last_four_modal';
import SideView from '@src/components/ui/side_view';
import Spinner from '@src/components/ui/spinner';
import { WarningTriangleWhiteIcon } from '@src/components/utils/icomoon';

import { useConfirmImportStartingDateModal } from './confirm_import_starting_date_modal/modal';
import NewAccountForm from './new_account_form';
import { ISetupAccountData } from './schema';

import styles from '../../styles.module.scss';

interface ISetupAccountProps extends IUseModalProps<TOpenValue> {
  refetchFinancialConnectionList: () => void
}

type TOpenValue = {
  financialConnectionBankAccount: IFinancialInstitutionBankAccount,
  managementGroupId: TID,
}

const SetupAccount = ({
  openValue,
  isOpen,
  onCancel,
  onDone,
  refetchFinancialConnectionList,
}:ISetupAccountProps): JSX.Element => {
  const accountType:TAccountType = openValue?.financialConnectionBankAccount.accountType === 'credit' ? 'credit_card' : 'bank';
  const accountTypeName = accountType === 'credit_card' ? 'Credit Card' : 'Bank Account';
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [setupAccountData, setSetupAccountData] = useState<
  ISetupAccountData | undefined>(undefined);

  const confirmImportStartingDate = useConfirmImportStartingDateModal();
  const { openWithValue } = confirmImportStartingDate;

  const update = useUpdateReconciliationPaymentAccount();
  const { mutate } = update;

  const handleError = useCallback((response) => {
    setErrorMessage(response?.response?.data?.errors[0]);
  }, []);

  const onSuccessDone = useCallback(() => {
    toastr.success(`${accountTypeName} has been added successfully.`, 'Success');
    refetchFinancialConnectionList();
    onDone();
  }, [accountTypeName, refetchFinancialConnectionList, onDone]);

  // handle mapping of bank account/credit card with the fic bank account POST method
  const handleCreateMapping = useCallback((data?: ISetupAccountData) => {
    if (data === undefined) return;

    mutate({
      accountId:  data.reconciliationPaymentAccount.id,
      source:     'fic_listing',
      businessId: data.businessId,
      data:       {
        name:                              data.reconciliationPaymentAccount.name,
        isMultiEntity:                     data.isMultiEntity === 'true',
        startImportDate:                   formatDate(data.startImportDate, 'YYYY/MM/DD'),
        plaidStartImportDate:              formatDate(data.plaidStartImportDate, 'YYYY/MM/DD'),
        aggregatorStartImportDate:         formatDate(data.plaidStartImportDate, 'YYYY/MM/DD'),
        transactionImportType:             'auto',
        financialInstitutionBankAccountId: openValue?.financialConnectionBankAccount.id,
        last4:                             data.reconciliationPaymentAccount.last4,
      },
    }, {
      onSuccess: onSuccessDone,
      onError:   handleError,
    });
  }, [mutate, handleError, openValue?.financialConnectionBankAccount, onSuccessDone]);

  const confirmLast4Modal = useConfirmLastFourModal({
    onDone: () => handleCreateMapping(setupAccountData),
  });

  const handleSubmit = useCallback((data: ISetupAccountData) => {
    if (!accountType) return;

    if (data.reconciliationPaymentAccount?.last4 !== openValue?.financialConnectionBankAccount.last4) {
      setSetupAccountData(data);
      confirmLast4Modal.open();
    } else {
      handleCreateMapping(data);
    }
  }, [accountType, handleCreateMapping, confirmLast4Modal, openValue?.financialConnectionBankAccount]);

  const handleDone = useCallback((data: ISetupAccountData) => {
    if (data.reconciliationPaymentAccount.transactionImportType
      === window.Docyt.Common.Constants.TRANSACTION_IMPORT_TYPES.MANUAL
      && data.reconciliationPaymentAccount.transactionDocumentsCount > 0
      && data.reconciliationPaymentAccount.lastImportDate) {
      openWithValue({ data });
    } else {
      handleSubmit(data);
    }
  }, [openWithValue, handleSubmit]);

  return (
    <>
      <confirmImportStartingDate.Component
        { ...confirmImportStartingDate.props }
        onConfirm={ handleSubmit }
      />
      <confirmLast4Modal.Component { ...confirmLast4Modal.props } />
      <SideView.Form
        doneTitle={ `Map ${accountTypeName}` }
        isOpen={ isOpen }
        title={ `Map ${accountTypeName}` }
        onCancel={ onCancel }
      >
        { ({ formId }) => (
          <div
            className="map-bank-account-or-credit-card-view"
          >
            {
                update.isLoading && <Spinner />
              }
            {
                errorMessage && (
                  <div className={ styles['error-message-container'] }>
                    <SideView.Error>
                      <WarningTriangleWhiteIcon fontSize={ 18 } />
                      <div className="error-message">
                        <span>Account could not be mapped.</span>
                        <span>{ errorMessage }</span>
                      </div>
                    </SideView.Error>
                  </div>
                )
              }
            {
              openValue?.managementGroupId && (
                <NewAccountForm
                  accountType={ accountType }
                  formId={ formId }
                  managementGroupId={ openValue?.managementGroupId }
                  onSubmit={ handleDone }
                />
              )
            }
          </div>
        ) }
      </SideView.Form>
    </>
  );
};

const MemoizedSetupAccount = React.memo(SetupAccount);
const useSetupAccount = makeUseModal<typeof MemoizedSetupAccount, TOpenValue>(MemoizedSetupAccount);

export {
  useSetupAccount,
  MemoizedSetupAccount as default,
};
