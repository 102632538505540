import { TMongoID } from '@src/types/common';
import { IReportItem } from '@src/types/report_service/report_item';
import { getReportBTFEndPoint } from '@src/utils/config';
import { camelizeKeys } from '@src/utils/transform_keys';

import { apiGet } from '../helpers';

const getReportItems = (reportId: TMongoID): Promise<IReportItem[]> => {
  const endPoint = getReportBTFEndPoint(window.Docyt.Common.Constants.BTF_SERVICE_API_LIST_KEYS.REPORT_ITEMS);
  return apiGet(
    `/${endPoint}/api/v1/reports/${reportId}/items`,
  ).then((data) => camelizeKeys(data.items) as IReportItem[]);
};

const getReportByIdentifier = (reportId: string, reportIdentifier: string): Promise<IReportItem> => {
  return apiGet(
    `/reports/api/v1/reports/${reportId}/items/by_identifier?item_identifier=${reportIdentifier}`,
  ).then((data) => camelizeKeys(data.item) as IReportItem);
};

export {
  getReportItems,
  getReportByIdentifier,
};
