import { downloadFromS3, uploadFileToS3 } from '@src/requests/aws';
import { IAchTransaction } from '@src/types/accounts_payable/ach_transactions';
import { TDate, TID } from '@src/types/common';
import { IDocument, ISimpleDocument } from '@src/types/documents';
import { IRelatedDocument } from '@src/types/related_documents';
import { TServiceType } from '@src/types/services';
import { ISymmetricKey } from '@src/types/symmetric_keys';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiPost, apiPut, apiDelete } from './helpers';

const DEFAULT_EXTRACTED_FILE_NAME = 'document.pdf';

interface ICompleteDocumentUpload {
  fileContentType: string,
  finalFileKey: string,
  s3ObjectKey: string,
}

interface IDownloadDocumentParams {
  documentId: TID,
  type: 'original' | 'extracted'
}

interface IDownloadFileParams {
  documentIds: number[],
  type: 'original' | 'extracted'
}

interface IDownloadDocumentResponse {
  fileData: ArrayBuffer,
  fileName: string,
}

interface IDownloadFileResponse {
  fileData: any,
  fileName: string,
}

interface IPostDocumentParams {
  document: {
    originalFileName: string,
    fileContentType: string,
    storageSize?: number,
  },
  businessId: TID
  inboxDocument: boolean,
}

interface IUploadDocumentParams {
  documentParams: IPostDocumentParams,
  file: File,
}

interface IGetDocumentsParams {
  businessId?: TID,
  ownDocuments: boolean,
  standardDocumentId: TID,
}

interface IGetDocumentsResponse {
  meta: {
    totalCount: number,
  },
  collection: IDocument[],
}

interface IDeleteDocumentParams {
  id: TID,
}

interface IDeleteDocumentsParams {
  documentIds: TID[],
}

interface IGetDocumentRelatedDocumentsParams {
  id: TID,
}

interface IGetDocumentRelatedDocumentsResponse {
  meta: {
    totalCount: number,
  },
  collection: IRelatedDocument[],
}

interface IPutDocumentParams {
  id: TID,
  document: {
    quickbooksCustomerId: TID | undefined,
  }
}

interface IPutUpdateStarredFlagParams {
  id: TID,
  starredFlag?: boolean,
}

interface IGetDocumentsByTransactionIdParams {
  serviceId: TID,
  filters?: object,
}

interface IGetDocumentsByServiceDocumentIdsParams {
  serviceId: TID,
  ids: [TID],
}

interface IGetRelatedDocumentsByAchTransactionParams {
  serviceId: TID,
  document: IAchTransaction,
}

const getDocument = (id: number): Promise<IDocument> => {
  return apiGet(`/api/web/v1/documents/${id}`)
    .then((data) => camelizeKeys(data.document) as IDocument);
};

const getDocumentSymmetricKey = (documentId: number): Promise<ISymmetricKey> => {
  return apiGet(`/api/v1/documents/${documentId}/symmetric_key`)
    .then((data) => camelizeKeys(data.symmetric_key) as ISymmetricKey);
};

interface IMarkAsReadDocumentsParams {
  value: boolean,
  documentIds: TID[],
}

interface IMarkAsReadDocumentsResponse {
  viewedAt: TDate,
}

const markDocumentAsRead = (
  params: IMarkAsReadDocumentsParams,
): Promise<IMarkAsReadDocumentsResponse> => {
  return apiPut(
    '/api/web/v1/documents/mark_as_read',
    underscoreKeys({ ...params, value: String(params.value) }),
  ).then((data) => camelizeKeys(data) as IMarkAsReadDocumentsResponse);
};

interface IMoveDocumentsToTrashParams {
  value: boolean,
  documentIds: TID[],
}

const moveDocumentsToTrash = (
  params: IMoveDocumentsToTrashParams,
): Promise<void> => {
  return apiPut(
    '/api/v1/documents/move_to_trash',
    underscoreKeys({ ...params, value: String(params.value) }),
  );
};

interface IForwardDocumentsParams {
  businessId: TID,
  documentIds: number[],
  standardDocumentId: TID,
}

interface IForwardDocumentResponse {
  documents: ISimpleDocument[],
}

const forwardDocuments = (
  params: IForwardDocumentsParams,
): Promise<IForwardDocumentResponse> => {
  const requestParams = {
    businessId:     params.businessId,
    oldDocumentIds: params.documentIds,
    document:       {
      standardDocumentId: params.standardDocumentId,
    },
  };

  return apiPost(
    '/api/v1/documents/forward_documents',
    underscoreKeys(requestParams),
  ).then((data) => camelizeKeys(data) as IForwardDocumentResponse);
};

interface IForwardDocumentsToServiceParams {
  businessId: TID,
  serviceType: TServiceType,
  documentIds: number[],
}

const forwardDocumentsToService = (
  params: IForwardDocumentsToServiceParams,
): Promise<void> => {
  return apiPost(
    '/api/v1/documents/forward_to_services',
    underscoreKeys(params),
  );
};

const postDocument = (params: IPostDocumentParams): Promise<IDocument> => {
  return apiPost(
    '/api/v1/documents',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data.document) as IDocument);
};

const putCompleteDocumentUpload = (
  documentId: TID,
  params: ICompleteDocumentUpload,
): Promise<void> => {
  return apiPut(
    `/api/v1/documents/${documentId}/complete_upload`,
    underscoreKeys({ document: params }),
  );
};

const downloadDocument = (params: IDownloadDocumentParams): Promise<IDownloadDocumentResponse> => {
  return getDocument(params.documentId).then((document) => {
    let s3ObjectKey: string | undefined;
    let fileName: string | undefined;

    if (params.type === 'original') {
      s3ObjectKey = document.originalFileKey;
      fileName = <string>(document.computedOriginalFilename || document.originalFileKey);
    } else {
      s3ObjectKey = document.finalFileKey;
      fileName = document.name || DEFAULT_EXTRACTED_FILE_NAME;
    }

    if (!s3ObjectKey) {
      throw new Error(window.Docyt.Common.Constants.Messages.DOCUMENT_DOWNLOAD_FAILED);
    }

    return getDocumentSymmetricKey(params.documentId).then((symmetricKey) => {
      return downloadFromS3({ s3ObjectKey: s3ObjectKey!, symmetricKey });
    }).then(({ fileData }) => {
      return {
        fileData,
        fileName: fileName || DEFAULT_EXTRACTED_FILE_NAME,
      };
    });
  });
};

const downloadDocuments = (params: IDownloadFileParams): Promise<IDownloadFileResponse> => {
  return apiGet(
    '/api/v1/documents/download_zip_file',
    underscoreKeys(params),
  ).then((data) => {
    const today = new Date();
    const strDate = (`0${today.getDate()}`).slice(-2);
    const strMonth = (`0${today.getMonth() + 1}`).slice(-2);
    const suffix = `${strMonth}${strDate}${today.getFullYear()}`;

    return {
      // eslint-disable-next-line no-undef
      fileData: AWS.util.base64.decode(data),
      fileName: `Docyt_${suffix}.zip`,
    };
  });
};

const generateKeyForS3 = (document: IDocument, file: File) => {
  if (document.originalFileName) {
    return `${document.id}-${document.originalFileName}`;
  }

  return `${document.id}-${file.name}`;
};

const uploadDocument = (params: IUploadDocumentParams): Promise<void> => {
  return postDocument(params.documentParams)
    .then((document) => {
      return getDocumentSymmetricKey(document.id)
        .then((symmetricKey) => {
          return uploadFileToS3({
            s3ObjectKey: generateKeyForS3(document, params.file),
            file:        params.file,
            symmetricKey,
          });
        }).then(({ s3ObjectKey }) => {
          return putCompleteDocumentUpload(
            document.id,
            {
              s3ObjectKey,
              finalFileKey:    s3ObjectKey,
              fileContentType: params.file.type,
            },
          );
        });
    });
};

const getDocuments = (
  params: IGetDocumentsParams,
): Promise<IGetDocumentsResponse> => {
  return apiGet(
    '/api/web/v1/documents',
    underscoreKeys({
      ...params,
    }),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      ...cdata,
      collection: cdata.documents as IDocument[],
    };
  });
};

const deleteDocument = (
  params: IDeleteDocumentParams,
): Promise<void> => {
  return apiDelete(
    `api/web/v1/documents/${params.id}`,
  );
};

const deleteDocuments = (
  params: IDeleteDocumentsParams,
): Promise<void> => {
  return apiDelete(
    'api/web/v1/documents/destroy_batch',
    underscoreKeys(params),
  );
};

const getDocumentRelatedDocuments = (
  params: IGetDocumentRelatedDocumentsParams,
): Promise<IGetDocumentRelatedDocumentsResponse> => {
  return apiGet(
    `/api/v1/documents/${params.id}/related_documents`,
    underscoreKeys({
      ...params,
    }),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      meta: {
        totalCount: cdata.count,
      },
      collection: cdata.relatedDocuments as IRelatedDocument[],
    };
  });
};

const getDocumentsByTransactionId = (
  params: IGetDocumentsByTransactionIdParams,
): Promise<IGetDocumentsResponse> => {
  return apiGet(
    '/api/v1/documents/by_service_payment_transaction_id',
    underscoreKeys({
      ...params,
    }),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      ...cdata,
      collection: cdata.documents as IDocument[],
    };
  });
};

const getDocumentsByServiceDocumentIds = (
  params: IGetDocumentsByServiceDocumentIdsParams,
): Promise<IGetDocumentsResponse> => {
  return apiGet(
    '/api/v1/documents/by_service_document_ids',
    underscoreKeys({
      ...params,
    }),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      ...cdata,
      collection: cdata.documents as IDocument[],
    };
  });
};

const getRelatedDocumentsByAchTransaction = (
  params: IGetRelatedDocumentsByAchTransactionParams,
): Promise<IGetDocumentsResponse> => {
  if (params.document.status === 'cancelled') {
    return getDocumentsByServiceDocumentIds({
      serviceId: params.serviceId,
      ids:       params.document.serviceDocumentIds,
    });
  }
  return getDocumentsByTransactionId({
    serviceId: params.serviceId,
    filters:   {
      servicePaymentTransactionId: params.document.servicePaymentTransactionId,
    },
  });
};

const putDocument = (
  params: IPutDocumentParams,
): Promise<void> => {
  return apiPut(
    `/api/v1/documents/${params.id}`,
    underscoreKeys(params),
  );
};

const putUpdateStarredFlag = (
  params: IPutUpdateStarredFlagParams,
): Promise<void> => {
  return apiPut(
    `/api/v1/documents/${params.id}/update_starred_flag`,
    underscoreKeys(params),
  );
};

interface IForwardToBankStatementsParams {
  id: number,
  businessId: TID,
  reconciliationPaymentAccountId?: TID,
  date?: TDate,
}

const forwardToBankStatements = (
  params: IForwardToBankStatementsParams,
): Promise<void> => {
  return apiPut(
    `/api/v1/documents/${params.id}/forward_to_bank_statements`,
    underscoreKeys(params),
  );
};

interface IForwardToBalanceSheetkStatementsParams {
  id: number,
  businessId: TID,
  chartOfAccountId?: TID,
  date?: TDate,
}

const forwardToBalanceSheetStatements = (
  params: IForwardToBalanceSheetkStatementsParams,
): Promise<void> => {
  return apiPut(
    `/api/v1/documents/${params.id}/forward_to_balance_sheet_statements`,
    underscoreKeys(params),
  );
};

export {
  downloadDocument,
  downloadDocuments,
  IDownloadDocumentParams,
  IDownloadDocumentResponse,
  IDownloadFileParams,
  IDownloadFileResponse,
  IForwardDocumentsParams,
  IForwardDocumentResponse,
  IForwardDocumentsToServiceParams,
  IMarkAsReadDocumentsParams,
  IMoveDocumentsToTrashParams,
  IMarkAsReadDocumentsResponse,
  IUploadDocumentParams,
  IGetDocumentsParams,
  IGetDocumentsResponse,
  IDeleteDocumentParams,
  IDeleteDocumentsParams,
  IGetDocumentRelatedDocumentsParams,
  IGetDocumentRelatedDocumentsResponse,
  IGetDocumentsByTransactionIdParams,
  IGetDocumentsByServiceDocumentIdsParams,
  IGetRelatedDocumentsByAchTransactionParams,
  IPutDocumentParams,
  IPutUpdateStarredFlagParams,
  IForwardToBankStatementsParams,
  IForwardToBalanceSheetkStatementsParams,
  forwardDocuments,
  forwardDocumentsToService,
  markDocumentAsRead,
  moveDocumentsToTrash,
  uploadDocument,
  getDocuments,
  deleteDocument,
  deleteDocuments,
  getDocument,
  getDocumentRelatedDocuments,
  getDocumentsByTransactionId,
  getDocumentsByServiceDocumentIds,
  getRelatedDocumentsByAchTransaction,
  putDocument,
  putUpdateStarredFlag,
  forwardToBankStatements,
  forwardToBalanceSheetStatements,
  getDocumentSymmetricKey,
};
