import { useMutation, useQuery, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { createPortfolio, deletePortfolioById, getPortfolioList, updatePortfolio } from '@src/requests/portfolio_service/portfolio';
import { TID } from '@src/types/common';
import { ICreateUpdatePortfolioParams, ICreateUpdatePortfolioResponse, IPortfolioData } from '@src/types/portfolio';

const useCreatePortfolio = () => {
  const queryClient = useQueryClient();
  
  return useMutation<ICreateUpdatePortfolioResponse, Error, ICreateUpdatePortfolioParams>(createPortfolio, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKey.portfolioList]);
    },
  });
};

const useUpdatePortfolio = () => {
  const queryClient = useQueryClient();
  
  return useMutation<ICreateUpdatePortfolioResponse, Error, ICreateUpdatePortfolioParams>(updatePortfolio, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKey.portfolioList]);
    },
  });
};

const useDeletePortfolio = () => {
  const queryClient = useQueryClient();
  
  return useMutation<void, Error, TID>(deletePortfolioById, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKey.portfolioList]);
    },
  });
};

const useGetPortfolio = () => {
  return useQuery<IPortfolioData, Error>(
    [QueryKey.portfolioList],
    () => getPortfolioList(),
  );
};

export {
  useCreatePortfolio,
  useUpdatePortfolio,
  useGetPortfolio,
  useDeletePortfolio,
};
