import React from 'react';

import pluralize from 'pluralize';

import { IVendorPayment } from '@src/types/vendor_payments';

import Tooltip from '@src/components/ui_v2/tooltip';
import {
  truncatedName,
} from '@src/components/vendors/my_vendors/details/payments/utils';

import SummaryDetail from './summary_detail';

import styles from '@src/components/vendors/my_vendors/details/payments/styles.module.scss';

interface IChartOfAccountLabelProps {
  vendorPayment: IVendorPayment;
  onMouseEnter: () => void;
  showTooltip: boolean | undefined;
}

const ChartOfAccountLabel = ({
  vendorPayment,
  onMouseEnter,
  showTooltip,
}: IChartOfAccountLabelProps): JSX.Element | null => {
  const chartOfAccount = vendorPayment.chartOfAccount;

  if (!Array.isArray(chartOfAccount)) {
    return <span>-</span>;
  }

  if (chartOfAccount.length === 1) {
    const account = chartOfAccount[0];

    if (typeof account === 'string') {
      return <span>{truncatedName(account, 25)}</span>;
    }

    if (Array.isArray(account)) {
      const splitCount = (account as string[]).length;
      return (
        <Tooltip.Hover
          isControl
          content={ (
            <SummaryDetail
              splitType
              vendorPayment={ vendorPayment }
              onMouseEnter={ onMouseEnter }
            />
) }
          isVisibleArrow={ false }
          showTooltip={ showTooltip }
          tooltipClassName={ styles['coa-summary-tooltip'] }
          variant="light"
        >
          <span className={ styles['multiple-payment-items'] } onMouseEnter={ onMouseEnter }>
            {`${splitCount} ${pluralize('split', splitCount)}`}
          </span>
        </Tooltip.Hover>
      );
    }
  }

  if (chartOfAccount.length > 1) {
    const chartCount = chartOfAccount.length;
    return (
      <Tooltip.Hover
        isControl
        content={ <SummaryDetail vendorPayment={ vendorPayment } onMouseEnter={ onMouseEnter } /> }
        isVisibleArrow={ false }
        showTooltip={ showTooltip }
        tooltipClassName={ styles['coa-summary-tooltip'] }
        variant="light"
      >
        <span className={ styles['multiple-payment-items'] } onMouseEnter={ onMouseEnter }>
          {`${chartCount} ${pluralize('chart of account', chartCount)}`}
        </span>
      </Tooltip.Hover>
    );
  }

  return <span>-</span>;
};

export default React.memo(ChartOfAccountLabel);
