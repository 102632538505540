import { IBusinessVendor } from '@src/types/business_vendors';
import { trimNumKeys } from '@src/utils/transform_keys';

import { apiPost } from './helpers';

const postAchBankAccount = (
  params: IBusinessVendor,
): Promise<void> => {
  return apiPost(
    '/api/v1/business_vendors/link_multiple_to_ach_bank',
    trimNumKeys({ ...params }),
  );
};

export {
  postAchBankAccount,
};
