import React, { Children, ReactNode, useEffect } from 'react';

import Breadcrumb from './breadcrumb';

import styles from './styles.module.scss';

interface IHeaderRegionBreadcrumbsProps {
  children: ReactNode;
}

const HeaderRegionBreadcrumbs = ({ children }: IHeaderRegionBreadcrumbsProps): JSX.Element => {
  useEffect(() => {
    Children.forEach(children, (child) => {
      if (child && typeof child === 'object' && 'type' in child) {
        if (child.type !== Breadcrumb) {
          throw new Error(
            'Only "HeaderRegion.Breadcrumb" components are allowed as children of "HeaderRegion.Breadcrumbs"',
          );
        }
      }
    });
  }, [children]);

  return (
    <div className={ styles.breadcrumbs }>
      {children}
    </div>
  );
};

export default React.memo(HeaderRegionBreadcrumbs);
