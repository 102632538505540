import React from 'react';

import { TID } from '@src/types/common';

import Root from '@src/components/root';

import Details from './financial_institution_connection_center_details/financial_institution_connection_center_details';

interface IFinancialInstitutionConnectionCenterPageProps {
  businessId?: TID,
  token?: string,
}

const FinancialInstitutionConnectionCenterPage = ({
  businessId,
  token,
}: IFinancialInstitutionConnectionCenterPageProps): JSX.Element => {
  return (
    <Root>
      <Details
        businessId={ businessId }
        token={ token }
      />
    </Root>
  );
};

export default FinancialInstitutionConnectionCenterPage;
