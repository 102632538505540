import { useEffect, useState } from 'react';

import { useQuilttConnector, ConnectorSDKEventType, useQuilttSession } from '@quiltt/react';

import toastr from '@lib/toastr';

import { IQuilltMeta } from '../types/financial_institution_connection';

interface IQuilltProps {
  handleOnSuccessCallback(metadata: IQuilltMeta): void,
  handleOnFailureCallback?(errorType: string): void
}

interface IReconnectionMeta {
  connectionId:string, institution?:string, session?:string
}

export default function useQuiltt({
  handleOnFailureCallback, handleOnSuccessCallback,
}: IQuilltProps) {
  const { importSession, revokeSession } = useQuilttSession();
  const [reConnectInstitution, setReConnectInstitution] = useState<IReconnectionMeta | undefined>();

  const { open } = useQuilttConnector(window.configData?.quiltt?.default_connector_id, {
    onExitSuccess: (metadata) => {
      handleOnSuccessCallback({
        ...metadata,
        type: 'quiltt',
      });
    },
    onExit: (errorType: ConnectorSDKEventType) => {
      if (errorType !== 'exited.successful') {
        if (handleOnFailureCallback) {
          setReConnectInstitution(undefined);
          handleOnFailureCallback('Something went wrong');
        }
      }
      revokeSession();
    },
    onEvent: (event:ConnectorSDKEventType) => {
      if (event === ConnectorSDKEventType.ExitAbort) {
        setReConnectInstitution(undefined);
        revokeSession();
      }
    },
    connectionId: reConnectInstitution?.connectionId ?? '',
    institution:  reConnectInstitution?.institution,
  });

  useEffect(() => {
    if (reConnectInstitution) {
      open();
    }
  }, [open, reConnectInstitution]);

  const loadQuiltUIForReconnect = async (reConnectInstitutionData: IReconnectionMeta) => {
    if (reConnectInstitutionData.session) {
      importSession(reConnectInstitutionData.session).then(() => {
        setReConnectInstitution({ ...reConnectInstitutionData });
      }).catch((err: Error) => {
        toastr.error(`Error: ${err.message}`, 'Error');
      });
    }
  };

  return {
    open,
    setReConnectInstitution,
    loadQuiltUIForReconnect,
  };
}
