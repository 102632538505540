import React from 'react';

import { TID } from '@src/types/common';

import BusinessProvider from '@src/components/providers/business_provider';
import JournalDrawer from '@src/components/reconciliation_center/journal_entries/header/journal_drawer';
import FormTable from '@src/components/reconciliation_center/journal_entries/header/table';
import useDrawer from '@src/components/reconciliation_center/journal_entries/header/use_drawer';
import Root from '@src/components/root';
import { Button } from '@src/components/ui_v2/buttons';
import DetailsRegion from '@src/components/ui_v2/layout/details_region';
import { AttachIcon } from '@src/components/utils/icomoon';

import DuplicateForm from './duplicate_form';
import Table from './table';

import styles from './header/styles.module.scss';

const JournalEntries: React.FC<{ businessId: TID }> = ({ businessId }) => {
  const { isOpen, open, close } = useDrawer();

  const [duplicateFrom, setDuplicateFrom] = React.useState<number|undefined>(undefined);

  const duplicate = React.useCallback((id: number) => {
    setDuplicateFrom(id);
    open();
  }, [open]);

  const addNew = React.useCallback(() => {
    setDuplicateFrom(undefined);
    open();
  }, [open]);

  return (
    <Root>
      <BusinessProvider businessId={ businessId }>
        <DetailsRegion>
          <DetailsRegion.Header className={ styles.container } title="Journal Entries">
            <Button variant="primary" onClick={ addNew }>
              <AttachIcon fontSize={ 16 } />
              Add
            </Button>
            <JournalDrawer drawerControl={ { isOpen, open, close } } title="Add Journal Entry">
              { duplicateFrom
                ? <DuplicateForm close={ close } duplicateFrom={ duplicateFrom } />
                : <FormTable close={ close } /> }
            </JournalDrawer>
          </DetailsRegion.Header>
          <Table duplicate={ duplicate } />
        </DetailsRegion>
      </BusinessProvider>
    </Root>
  );
};

export default JournalEntries;
