import React, { useCallback } from 'react';
import { IBusinessUser } from '@src/types/business_users';

import { UserAvatar } from '@src/components/ui/avatars';
import styles from './styles.module.scss';

interface IUserItemProps {
  user: IBusinessUser,
  onRemove: (user: any) => void,
}

const UserItem = ({
  user,
  onRemove,
}: IUserItemProps) => {
  const handleRemove = useCallback(() => {
    onRemove(user);
  }, [onRemove, user]);

  return (
    <div className="display-flex justify-content-between align-items-center m-t-10 m-b-10">
      <div className="display-flex align-items-center">
        <UserAvatar avatar={ user.avatar } mr={ 12 } size={ 38 } />
        <span className={ `width-100px m-r-12 ${styles.userName}` }>
          {user.parsedFullname}
        </span>
        <span className={ styles.userDetails }>
          {user.email}
          {' '}
          {user.phone !== undefined && user.phone !== null && (
            <span>
              {' '}
              |
              {user.phone}
            </span>
          )}
        </span>
      </div>
      <a role="button" tabIndex={ 0 } onClick={ handleRemove }>Remove</a>
    </div>
  );
};

export default UserItem;
