import { TDate, TID } from '@src/types/common';
import { IIntegration } from '@src/types/integrations/integrations';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiDelete, apiPut, apiPost } from '../helpers';

interface IGetIntegrationParams {
  businessId: TID,
  integrationId: TID | null | undefined,
}

interface IDisconnectIntegrationParams {
  integrationId: TID,
}

interface ISetIntegrationPausedParams {
  integrationId: TID,
  isPaused: boolean,
}

interface IExternalSystemConfig {
  location?: number| string | null,
}

interface IUpsertIntegrationResponse {
  emailAddress: string,
  externalSystemName: string
}

interface IUpsertIntegrationParams {
  businessId: TID,
  integration: {
    externalSystemId: TID,
    startDate: TDate,
    revenueReportTypeId?: TID,
    location?: string,
    externalSystemConfig: IExternalSystemConfig,
  },
  actionType: string
}

const getIntegration = (params: IGetIntegrationParams): Promise<IIntegration> => {
  return apiGet(`/integrations/api/v1/integrations/${params.integrationId}`, underscoreKeys(params))
    .then((data) => camelizeKeys(data.integration) as IIntegration);
};

const disconnectIntegration = (params: IDisconnectIntegrationParams): Promise<void> => {
  return apiDelete(
    `/integrations/api/v1/integrations/${params.integrationId}`,
  );
};

const setIntegrationPaused = (params: ISetIntegrationPausedParams): Promise<void> => {
  return apiPut(
    `/integrations/api/v1/integrations/${params.integrationId}/set_is_paused`,
    underscoreKeys(params),
  );
};

const UpsertIntegration = (
  params: IUpsertIntegrationParams,
): Promise<IUpsertIntegrationResponse> => {
  return apiPost(
    '/integrations/api/v1/integrations/upsert',
    underscoreKeys(params),
  ).then((data) => <any>camelizeKeys(data));
};


export {
  IGetIntegrationParams,
  getIntegration,
  disconnectIntegration,
  IDisconnectIntegrationParams,
  setIntegrationPaused,
  ISetIntegrationPausedParams,
  UpsertIntegration,
  IUpsertIntegrationParams,
  IUpsertIntegrationResponse
};
