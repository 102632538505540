import { TID } from '@src/types/common';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiDelete, apiGet, apiPost, apiPut } from './helpers';
import { IMultiBusinessReportDetail } from './report_service/multi_business_report';

export interface IGetMultiEntityReportsServiceResponse {
  consumerId: TID,
  id: TID,
  isReady: boolean,
  serviceEnabled: boolean
}

export interface IGetStandardCategoriesResponse {
  id: TID,
  name: string,
}

export interface IGetTemplatesResponse {
  id: string,
  category: string,
  draft: boolean,
  name: string,
  rank: TID,
  dependsOn: string[],
  standardCategoryIds: number[],
}

export interface IGetReportServicesByBusinessIdResponse {
  id: TID,
  businessId: TID,
  isReady: boolean,
  serviceEnabled: boolean,
  type: string,
}

export interface ICreateMultiEntityBusinessReportParams {
  multiBusinessReportServiceId: TID,
  name: string,
  reportServiceIds: number[],
  templateId: string,
  portfolioId?: string,
}

export const getMultiEntityReportsService = (): Promise<IGetMultiEntityReportsServiceResponse> => {
  return apiGet(
    'api/v1/multi_business_report_service',
  ).then((data) => camelizeKeys(data) as IGetMultiEntityReportsServiceResponse);
};

export const getStandardCategories = (): Promise<IGetStandardCategoriesResponse[]> => {
  return apiGet(
    'api/v1/standard_categories.json',
    underscoreKeys({ forUserAsAdminAndManager: true }),
  ).then((data) => camelizeKeys(data?.standard_categories) as IGetStandardCategoriesResponse[]);
};

export const getTemplates = (params: { 'standard_category_ids[]': number[] }): Promise<IGetTemplatesResponse[]> => {
  return apiGet(
    'reports/api/v1/templates',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data?.templates) as IGetTemplatesResponse[]);
};

export const getReportServicesByBusinessId = (
  params: { 'business_ids[]': number[] },
): Promise<IGetReportServicesByBusinessIdResponse[]> => {
  return apiGet(
    'api/v1/report_services/by_business_ids',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data?.report_services) as IGetReportServicesByBusinessIdResponse[]);
};

export const createMultiEntityBusinessReport = (
  params: ICreateMultiEntityBusinessReportParams,
): Promise<IMultiBusinessReportDetail> => {
  return apiPost(
    'reports/api/v1/multi_business_reports',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data.multi_business_report) as IMultiBusinessReportDetail);
};

export const updateMultiEntityReport = (params: IMultiBusinessReportDetail): Promise<IMultiBusinessReportDetail> => {
  return apiPut(
    `reports/api/v1/multi_business_reports/${params.id}`,
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data.multi_business_report) as IMultiBusinessReportDetail);
};

export const refreshMultiEntityReport = (id: string): Promise<IMultiBusinessReportDetail> => {
  return apiPost(
    `reports/api/v1/multi_business_reports/${id}/update_report`,
    underscoreKeys({}),
  ).then((data) => camelizeKeys(data.multi_business_report) as IMultiBusinessReportDetail);
};

export const deleteMultiEntityReport = (id: string): Promise<void> => {
  return apiDelete(`reports/api/v1/multi_business_reports/${id}`);
};
