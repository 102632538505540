import omit from 'lodash/omit';
import { StylesConfig } from 'react-select';

import { IInputCommonProps } from '../types';

import styles from './styles.module.scss';

interface IStyleParams {
  size?: IInputCommonProps['size'],
  minWidthOption?: IInputCommonProps['minWidthOption'],
}

const reactSelectStyles = ({ size = 'normal', minWidthOption }: IStyleParams): StylesConfig => {
  return {
    control: (provided, { isDisabled, isFocused }) => ({
      // Omit some styles because I want styles from `.form-control` to be applied
      ...omit(provided, [
        'backgroundColor',
        'borderColor',
        'borderStyle',
        'borderWidth',
        'boxShadow',
        'minHeight',
        'outline',
        '&:hover',
      ]),
      'background':   isDisabled ? styles['input-disabled-bgcolor'] : styles['input-bgcolor'],
      'border':       isFocused ? styles['input-focus-border'] : styles['input-border'],
      'borderRadius': styles['input-border-radius'],
      'color':        styles['input-text-color'],
      'cursor':       isDisabled ? 'not-allowed' : 'pointer',
      'fontStyle':    styles['input-font-style'],
      'fontWeight':   Number(styles['input-font-weight']),
      'fontSize':     size === 'compact' ? styles['input-compact-font-size'] : styles['input-font-size'],
      'height':       size === 'compact' ? styles['input-compact-height'] : styles['input-height'],
      'lineHeight':   size === 'compact' ? styles['input-compact-line-height'] : styles['input-line-height'],
      'padding':      0,
      '&:hover':      {
        background: styles['input-hover-bgcolor'],
        border:     isFocused ? styles['input-focus-border'] : styles['input-hover-border'],
      },
    }),
    clearIndicator: (provided) => ({
      ...provided,
      'alignItems':     'center',
      'color':          styles['input-clear-indicator-color'],
      'height':         styles['input-clear-indicator-height'],
      'width':          styles['input-clear-indicator-width'],
      'padding':        0,
      'justifyContent': 'center',
      '&:hover':        {
        color: styles['input-text-color'],
      },
    }),
    dropdownIndicator: (provided, { isDisabled }) => ({
      ...provided,
      'alignItems':     'center',
      'color':          isDisabled ? styles['input-dropdown-indicator-disabled-color'] : styles['input-text-color'],
      'height':         styles['input-dropdown-indicator-height'],
      'width':          styles['input-dropdown-indicator-width'],
      'padding':        0,
      'justifyContent': 'center',
      '&:hover':        {
        color: styles['input-text-color'],
      },
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      paddingRight: styles['input-padding-y'],
    }),
    input: (provided) => ({
      ...provided,
      marginTop:     0,
      marginBottom:  0,
      paddingTop:    0,
      paddingBottom: 0,
    }),
    menu: (provided) => ({
      ...provided,
      alignItems:    'flex-start',
      background:    styles['dropdown-bgcolor'],
      border:        styles['dropdown-border'],
      borderRadius:  styles['dropdown-border-radius'],
      boxShadow:     styles['dropdown-box-shadow'],
      cursor:        styles['select-menu-cursor'],
      display:       'flex',
      flexDirection: 'column',
      padding:       styles['select-menu-padding'],
      margin:        styles['select-menu-margin'],
      minWidth:      minWidthOption || styles['select-menu-width'],
      whiteSpace:    'nowrap',
      zIndex:        Number(styles['select-menu-z-index']),
    }),
    menuList: (provided) => ({
      ...provided,
      cursor:  styles['select-menu-cursor'],
      padding: 0,
      width:   '100%',
    }),
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      alignItems:   'center',
      background:   isFocused || isSelected ? styles['dropdown-item-selected-bgcolor'] : styles['dropdown-bgcolor'],
      display:      'flex',
      fontStyle:    'normal',
      fontWeight:   isFocused || isSelected ? Number(styles['dropdown-item-selected-font-weight']) : Number(styles['input-font-weight']),
      fontSize:     styles['dropdown-font-size'],
      gap:          styles['select-option-gap'],
      height:       styles['dropdown-item-height'],
      lineHeight:   styles['dropdown-line-height'],
      overflow:     'hidden',
      padding:      styles['select-item-padding'],
      textOverflow: 'ellipsis',
      whiteSpace:   'nowrap',

      color: styles['dropdown-item-color'],
    }),
    placeholder: (provided) => ({
      ...provided,
      color:      styles['input-placeholder-color'],
      whiteSpace: 'nowrap',
    }),
    singleValue: (provided) => ({
      ...provided,
      alignItems: 'center',
      display:    'flex',
      gap:        styles['select-value-gap'],
    }),
    valueContainer: (provided) => ({
      ...omit(provided, [
        'position',
      ]),
      padding: size === 'compact' ? styles['select-compact-value-padding'] : styles['select-value-padding'],

      [`& .${styles['icon-select-option-icon']}`]: {
        fontSize: size === 'compact' ? styles['select-compact-value-icon-size'] : styles['select-value-icon-size'],
        height:   size === 'compact' ? styles['select-compact-value-icon-size'] : styles['select-value-icon-size'],
        width:    size === 'compact' ? styles['select-compact-value-icon-size'] : styles['select-value-icon-size'],
        minWidth: size === 'compact' ? styles['select-compact-value-icon-size'] : styles['select-value-icon-size'],
      },
    }),
  };
};

const iconSelectStyles = (params: IStyleParams): StylesConfig => {
  return {
    ...reactSelectStyles(params),
    valueContainer: (provided, state) => ({
      ...reactSelectStyles(params).valueContainer!(provided, state),
      padding: params.size ? styles['select-compact-value-padding'] : styles['select-value-padding'],
    }),
  };
};

export {
  iconSelectStyles,
  reactSelectStyles,
};
