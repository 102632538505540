import React, { FC } from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { IGetTransactionServiceDocumentsResponse } from '@src/requests/transaction_service_documents';
import { ISorting } from '@src/types/sorting';
import {
  ITransactionServiceDocument,
  TTransfersSortColumn,
} from '@src/types/transaction_service_documents';

import QBOStatusIcon from '@src/components/common_v2/qbo_status_icon';
import { RECONCILIATION_CENTER_DRAWER_TABLE_HEIGHT } from '@src/components/reconciliation_center/month_end/utils';
import { AppLink } from '@src/components/ui_v2/buttons';
import CollectionTable from '@src/components/ui_v2/collection_table';
import { BotNotificationIcon, MoreInfoIcon, VerifiedManuallyIcon } from '@src/components/utils/icomoon';

import Filter from './filter';

interface UnmatchedTransfersTableProps {
  infiniteQuery: UseInfiniteQueryResult<IGetTransactionServiceDocumentsResponse, Error>,
  records: ITransactionServiceDocument[],
  sorting: ISorting<TTransfersSortColumn>,
}

const UnmatchedTransfersTable: FC<UnmatchedTransfersTableProps> =
  ({ records, sorting, infiniteQuery }) => {
    const verifiedTypeIcon = (document: ITransactionServiceDocument) => {
      if (document.verifiedByUser) {
        return <VerifiedManuallyIcon />;
      }
      return <BotNotificationIcon />;
    };

    const qboStatusIcon = (document: ITransactionServiceDocument) => {
      return <QBOStatusIcon fontSize={ 18 } model={ document } />;
    };

    const detailLink = (document: ITransactionServiceDocument) => {
      return (
        <AppLink newWindow href={ `/document/${document.documentId}` }>
          <MoreInfoIcon fontSize={ 16 } inColor="black" />
        </AppLink>
      );
    };

    return (
      <CollectionTable<ITransactionServiceDocument, TTransfersSortColumn>
        isRegionScroll
        filter={ <Filter /> }
        height={ RECONCILIATION_CENTER_DRAWER_TABLE_HEIGHT }
        query={ infiniteQuery }
        records={ records }
        sorting={ sorting }
      >
        <CollectionTable.DateColumn<
            ITransactionServiceDocument,
            TTransfersSortColumn
          >
          name="transaction_date"
          sortColumn="transaction_date"
          title="Date"
          value={ (document) => document.transactionDate }
        />

        <CollectionTable.TextColumn<
            ITransactionServiceDocument,
            TTransfersSortColumn
          >
          name="from_account"
          sortColumn="from_account"
          title="From Account"
          value={ (document) => document.fromAccountName }
        />

        <CollectionTable.TextColumn<
            ITransactionServiceDocument,
            TTransfersSortColumn
          >
          name="to_account"
          sortColumn="to_account"
          title="To Account"
          value={ (document) => document.toAccountName }
        />

        <CollectionTable.AmountColumn<
            ITransactionServiceDocument,
            TTransfersSortColumn
          >
          name="amount"
          sortColumn="amount"
          title="Amount"
          value={ (document) => document.amount }
          width="120px"
        />

        <CollectionTable.IconColumn<ITransactionServiceDocument>
          name="verified_type"
          title="Verified Type"
          value={ verifiedTypeIcon }
        />

        <CollectionTable.IconColumn<ITransactionServiceDocument>
          name="qbo_status"
          title="QBO Status"
          value={ qboStatusIcon }
        />

        <CollectionTable.IconColumn<ITransactionServiceDocument>
          name="document_detail"
          title="Document Detail"
          value={ detailLink }
        />
      </CollectionTable>
    );
  };

export default UnmatchedTransfersTable;
