import React, { useCallback } from 'react';

import { makeUseModal, IUseModalProps } from '@src/hooks/modal';
import { useDeleteFinancialInstitutionEmployeeMapping, useGetFinancialInstitutionAccountOwners } from '@src/hooks/queries/financial_institution_connection';
import { TID } from '@src/types/common';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import { Button, CancelModalButton } from '@src/components/ui/buttons';
import Spinner from '@src/components/ui/spinner';
import MutationStatus from '@src/components/utils/mutation_status';

import BaseEmployeeCardMappingModal from './base_employee_card_mapping_modal';
import EmployeeCardMappingTable from './employee_card_mapping_table';

import './employee_card_mapping.scss';

type TOpenValue = {
  reconciliationPaymentAccountId?: TID | undefined
  financialInstitutionBankAccountId: TID
}

interface IEmployeeCardMappingListProps extends IUseModalProps<TOpenValue> {
  refetchFinancialConenctionList: () => void;
}

const EmployeeCardMappingList: React.FC<IEmployeeCardMappingListProps> = ({
  isOpen,
  openValue,
  onDone,
  refetchFinancialConenctionList,
}) => {
  // Query to fetch account owners for the selected financial institution bank account and reconciliation payment account
  // Only enabled when modal is open and both IDs are available
  const accountOwnerQuery = useGetFinancialInstitutionAccountOwners({
    financialInstitutionBankAccountId: openValue?.financialInstitutionBankAccountId,
    reconciliationPaymentAccountId:    openValue?.reconciliationPaymentAccountId,
  }, {
    enabled: isOpen && !!openValue?.financialInstitutionBankAccountId && !!openValue?.reconciliationPaymentAccountId,
  });

  const deleteMapping = useDeleteFinancialInstitutionEmployeeMapping(
    openValue?.financialInstitutionBankAccountId ?? 0,
  );

  const handleDeleteMapping = useCallback(() => {
    if (!openValue?.reconciliationPaymentAccountId) return;

    deleteMapping.mutate({
      reconciliationPaymentAccountId: openValue.reconciliationPaymentAccountId,
    }, {
      onSuccess: () => {
        refetchFinancialConenctionList();
        onDone();
      },
    });
  }, [deleteMapping, openValue, onDone, refetchFinancialConenctionList]);

  const confirmModal = useConfirmDeleteModal({
    onDone: handleDeleteMapping,
  });

  const sortedAccountOwners = useCallback((a, b) => {
    // Sort entries with paymentAccount first
    if (a.paymentAccount && !b.paymentAccount) return -1;
    if (!a.paymentAccount && b.paymentAccount) return 1;
    return 0;
  }, []);

  const isLoading = accountOwnerQuery.isLoading;

  return (
    <BaseEmployeeCardMappingModal
      footerContent={ (
        <>
          <CancelModalButton
            className="delete-button-link"
            title="Delete Mapping"
            onClick={ confirmModal.open }
          />
          <Button bsColor="blue" onClick={ onDone }>
            Done
          </Button>
        </>
      ) }
      isOpen={ isOpen }
      title="Account Owners and Employee Card Mapping"
    >
      <MutationStatus
        mutation={ deleteMapping }
        successMessage="Mapping deleted successfully"
      />
      <confirmModal.Component
        { ...confirmModal.props }
        confirmStyle="danger"
        confirmTitle="Delete"
        text="Deleting the mapping will switch to manual transaction import. However, you can create a new mapping afterward."
        title="Delete Mapping"
      />
      {isLoading ? <Spinner /> : (
        <EmployeeCardMappingTable noBorder>
          {accountOwnerQuery.data?.financialInstitutionEmployeeMappings
            .sort(sortedAccountOwners)
            .map((row) => (
              <div key={ `mapping-row-${row.financialInstitutionBankAccountId}-${row.id}` } className="mapping-row">
                <div className="mapping-cell owner">
                  {row.accountOwner}
                </div>
                {row.paymentAccount && (
                  <>
                    <div className="mapping-cell business-name">
                      {row.childBusinessName}
                    </div>
                    <div className="mapping-cell employee-card">
                      {row.paymentAccount.displayName}
                    </div>
                  </>
                )}
                {!row.paymentAccount && (
                  <div className="mapping-cell-colspan">
                    Not mapped to any employee card
                  </div>
                )}
              </div>
            ))}
        </EmployeeCardMappingTable>
      )}
    </BaseEmployeeCardMappingModal>
  );
};

const useEmployeeCardMappingList = makeUseModal<typeof EmployeeCardMappingList, TOpenValue>(EmployeeCardMappingList);

export {
  EmployeeCardMappingList as default,
  useEmployeeCardMappingList,
};
