import React, { useCallback, useState } from 'react';

import classNames from 'classnames';

import { IVendorPayment } from '@src/types/vendor_payments';
import { capitalize } from '@src/utils/transform_keys';

import Tooltip from '@src/components/ui_v2/tooltip';
import { PaymentStatus } from '@src/components/vendors/my_vendors/details/payments/options';
import {
  paymentStatusVariant, truncatedName,
} from '@src/components/vendors/my_vendors/details/payments/utils';

import VendorPaymentStatus from './payment_status';

import styles from './styles.module.scss';

type TVendorPaymentStatusProps = {
  vendorPayment: IVendorPayment,
}

const VendorPaymentStatusItem = ({
  vendorPayment,
}: TVendorPaymentStatusProps) => {
  const [showTooltip, setShowTooltip] = useState<boolean|undefined>(undefined);

  const normalizedPaymentStatus =
  vendorPayment.paymentStatus === PaymentStatus.PAYMENT_ERROR
    ? PaymentStatus.PAYMENT_FAILED
    : vendorPayment.paymentStatus;

  const variant = paymentStatusVariant({ ...vendorPayment,
    paymentStatus: normalizedPaymentStatus });

  const classes = classNames(
    styles.label,
    'pointer',
    styles[`label-${variant}`],
  );

  const handleMouseEnter = useCallback(() => {
    const isDocytPaymentMethod =
    vendorPayment.paymentMethod === 'docyt_check'
    || vendorPayment.paymentMethod === 'docyt_ach';

    if (isDocytPaymentMethod) {
      setShowTooltip(variant !== 'failed');
    } else {
      setShowTooltip(false);
    }
  }, [vendorPayment.paymentMethod, variant]);

  const handleMouseLeave = useCallback(() => {
    setShowTooltip(false);
  }, [setShowTooltip]);

  const contentStatus = (
    <VendorPaymentStatus vendorPayment={ vendorPayment } />
  );

  return (
    <div
      className={ styles['label-container'] }
      onMouseLeave={ handleMouseLeave }
    >
      <Tooltip.Hover
        isControl
        content={ contentStatus }
        isVisibleArrow={ false }
        showTooltip={ showTooltip }
        tooltipClassName={ styles['status-tooltip'] }
        variant="light"
      >
        <span
          className={ classes }
          onMouseEnter={ handleMouseEnter }
        >
          {truncatedName(capitalize(normalizedPaymentStatus), 14)}
        </span>
      </Tooltip.Hover>
    </div>
  );
};

export default VendorPaymentStatusItem;
