import React from 'react';

import classNames from 'classnames';

import { TSearchDropdownIconOption } from '@src/components/global_header/client_view';
import Dropdown from '@src/components/ui_v2/dropdown';

import DropdownItem from './dropdown_item';
import InnerInput from './inner_input';
import SearchInput from './search_input';
import { TIconOption } from '../inputs/types';

import styles from './styles.module.scss';

interface ISearchDropdownInputProps extends
  Omit<React.ComponentPropsWithoutRef<typeof InnerInput>, 'onClear'>
{
  className?: string,
  options: TSearchDropdownIconOption[],
  value: TIconOption | null,
  searchPlaceholder: string,
  searchValue?: string,
  onSearch: (value: string) => void,
  onSelect: (value: TSearchDropdownIconOption) => void,
  openOnHover?: boolean,
  title?: string
}

const SearchDropdownInput = ({
  className,
  options,
  value,
  searchPlaceholder,
  searchValue,
  onSelect,
  onSearch,
  openOnHover = false,
  title,
  ...props
}: ISearchDropdownInputProps): JSX.Element => {
  const classes = classNames(
    styles['dropdown-input'],
    { [styles['hover-dropdown-input']]: openOnHover },
    className,
  );

  return (
    <Dropdown.Provider className={ classes }>
      <InnerInput
        value={ value }
        { ...props }
      />
      <Dropdown.Menu className={ styles['search-dropdown-menu'] }>
        {title && (
        <div className={ styles['header-box'] }>
          <span className={ styles['header-title'] }>
            { title }
          </span>
        </div>
        )}
        <SearchInput
          placeholder={ searchPlaceholder }
          value={ searchValue }
          onChange={ onSearch }
        />
        { options.map((option) => (
          <DropdownItem<TSearchDropdownIconOption>
            key={ option.value }
            option={ option }
            onSelect={ onSelect }
          />
        )) }
      </Dropdown.Menu>
    </Dropdown.Provider>
  );
};

export {
  ISearchDropdownInputProps,
  SearchDropdownInput as default,
};
