import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';

import {
  QBOSyncFilterField,
  VendorFilterField,
} from '@src/components/common_v2/filter_fields';
import { useVisibleColumnNames } from '@src/components/ui_v2/collection_table/hooks';
import Filter from '@src/components/ui_v2/filter';

import StarFlagFilterField from './fields/star_flag_filter_field';
import StatusFilterField from './fields/status_filter_field';
import InvoiceTypesFilterField from './fields/types_filter_field';

const InvoiceQueueFilter = () => {
  const business = useBusinessContext();
  const visibleColumnNames = useVisibleColumnNames();

  return (
    <Filter.TableContainer>
      { visibleColumnNames.includes('star_flag') && <StarFlagFilterField /> }
      { visibleColumnNames.includes('vendor_id') && (
        <VendorFilterField
          businessId={ business.id }
          name="vendor_id"
          placeholder="Search payee/vendor"
        />
      ) }
      { visibleColumnNames.includes('invoice_number') && (
        <Filter.TextField
          label="Invoice Number"
          name="invoice_number"
          placeholder="Invoice No"
        />
      ) }
      { visibleColumnNames.includes('is_expense_report') && (
        <InvoiceTypesFilterField
          invoiceTypesName="type"
          placeholder="All types"
        />
      ) }
      { visibleColumnNames.includes('invoice_date') && (
        <Filter.DateField
          name="invoice_date"
        />
      ) }
      { visibleColumnNames.includes('status') && (
        <StatusFilterField
          placeholder="All Statuses"
        />
      ) }
      { visibleColumnNames.includes('qbo_sync_status') && (
        <QBOSyncFilterField
          name="qbo_sync_status"
          placeholder="All ledger states"
        />
      ) }
      { visibleColumnNames.includes('amount') && (
        <Filter.AmountField
          name="amount"
          valueProps={ { fixedDecimalScale: false } }
        />
      ) }
    </Filter.TableContainer>
  );
};

export default React.memo(InvoiceQueueFilter);
