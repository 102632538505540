import React from 'react';

import { Button, CancelModalButton, IButtonProps } from '@src/components/ui/buttons';

import Footer from '../footer';

interface IModalStandardFooterProps {
  cancelTitle?: string,
  danger?: boolean,
  isProceedDisabled?: boolean,
  proceedTitle?: string,
  footerContent?: React.ReactNode,
  onCancel: () => void,
  onProceed: () => void,
  isLoading?: boolean,
}

const ModalStandardFooter = ({
  cancelTitle,
  danger,
  isProceedDisabled,
  proceedTitle = 'Proceed',
  footerContent,
  onCancel,
  onProceed,
  isLoading,
}: IModalStandardFooterProps) => {
  const buttonProps: IButtonProps = danger
    ? { bsStyle: 'danger' }
    : { bsColor: 'blue' };

  return (
    <Footer>
      <CancelModalButton
        title={ cancelTitle }
        onClick={ onCancel }
      />
      { footerContent }
      <Button
        { ...buttonProps }
        disabled={ isProceedDisabled }
        title={ proceedTitle }
        onClick={ onProceed }
        isLoading={ isLoading }
      />
    </Footer>
  );
};

export {
  IModalStandardFooterProps,
  ModalStandardFooter as default,
};
