import { TID } from '@src/types/common';
import { IMuitiReportDetailColumn, IMultiReportDetailBusiness } from '@src/types/report_service/multi_report_detail';
import { IReport } from '@src/types/report_service/report';
import { IReportItemAccountValue } from '@src/types/report_service/report_item_value_account';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from '../helpers';

interface IMultiBusinessReportItemAccountValuesParams {
  multiBusinessReportId: string,
  from: string,
  to: string,
  itemIdentifier: string,
}

interface IMultiBusinessReportItemAccountValuesResponse {
  aggregatedItemAccountValues: IReportItemAccountValue[],
  businessItemAccountValues: any[],
}

interface IMultiBusinessReportDetail {
  businesses: IMultiReportDetailBusiness[],
  columns: IMuitiReportDetailColumn[],
  reports: IReport[],
  id: string,
  multiBusinessReportServiceId: TID,
  name: string,
  templateId: string,
  lastUpdatedDate: string,
  reportServiceIds?: number[],
}

const getMultiBusinessReportDetail = (multiReportId: string): Promise<IMultiBusinessReportDetail> => {
  return apiGet(
    `/reports/api/v1/multi_business_reports/${multiReportId}`,
  ).then((data) => camelizeKeys(data.multi_business_report) as IMultiBusinessReportDetail);
};

const getMultiBusinessReportItemAccountValues = (
  params: IMultiBusinessReportItemAccountValuesParams,
): Promise<IMultiBusinessReportItemAccountValuesResponse> => {
  return apiGet(
    `/reports/api/v1/multi_business_reports/${params.multiBusinessReportId}`
      + '/multi_business_report_item_account_values/item_account_values',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);

    return {
      aggregatedItemAccountValues: cdata.aggregatedItemAccountValues as IReportItemAccountValue[],
      businessItemAccountValues:   cdata.businessItemAccountValues,
    };
  });
};

export const getMultiBusinessReports = (endPoint: string, params?: { portfolioId: string }): Promise<IMultiBusinessReportDetail[]> => {
  return apiGet(
    `/${endPoint}/api/v1/multi_business_reports`,
    params && underscoreKeys(params)
  ).then((data) => camelizeKeys(data.multi_business_reports) as IMultiBusinessReportDetail[]);
};

export {
  IMultiBusinessReportItemAccountValuesParams,
  IMultiBusinessReportItemAccountValuesResponse,
  IMultiBusinessReportDetail,
  getMultiBusinessReportItemAccountValues,
  getMultiBusinessReportDetail,
};
