import { TAmount } from '@src/types/common';

export type NumberFormatStyle = 'decimal' | 'currency' | 'percent';

const currencyFormater = (amount: TAmount | number) => {
  let value = Math.round(Number(`${amount}e2`));

  if (Number.isNaN(value)) return '+$0.00';

  const prefix = value < 0 ? '-' : '+';
  value = Math.abs(value);

  const intPart = Math.trunc(value / 100);
  let pointPart = String(value % 100);
  if (pointPart.length === 1) {
    pointPart = `0${pointPart}`;
  }

  const intPartStr = intPart.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return `${prefix}$${intPartStr}.${pointPart}`;
};

const currencyFormater2 = (amount: string | number) => {
  const value = Math.round(Number(`${amount}e2`));
  if (Number.isNaN(value)) return '$0.00';

  const positiveValue = Math.abs(value);
  const intPart = Math.trunc(positiveValue / 100);
  let pointPart = String(positiveValue % 100);
  if (pointPart.length === 1) {
    pointPart = `0${pointPart}`;
  }

  const intPartStr = intPart.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const result = `$${intPartStr}.${pointPart}`;

  return value >= 0 ? result : `(${result})`;
};

const currencyFormater3 = (amount: string | number) => {
  const value = Math.round(Number(`${amount}e2`));
  if (Number.isNaN(value)) return '$0.00';

  const positiveValue = Math.abs(value);
  const intPart = Math.trunc(positiveValue / 100);
  let pointPart = String(positiveValue % 100);
  if (pointPart.length === 1) {
    pointPart = `0${pointPart}`;
  }

  const intPartStr = intPart.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const result = `$${intPartStr}.${(pointPart)}`;

  return value >= 0 ? result : `-${result}`;
};

const currencyLocaleFormatter = (amount: TAmount | number | undefined | null) => {
  if (!amount) return '$0.00';
  const numberAmount = typeof amount === 'number' ? amount : parseFloat(amount);

  return numberAmount
    .toLocaleString(
      'en-US',
      {
        style:                 'currency',
        currency:              'USD',
        minimumFractionDigits: 2,
      },
    );
};

const localeFormatter = (amount: TAmount | number | undefined | null, style?: NumberFormatStyle) => {
  if (!amount) return '0';
  const numberAmount = typeof amount === 'number' ? amount : parseFloat(amount);

  return numberAmount
    .toLocaleString(
      'en-US',
      {
        style: style || 'decimal',
      },
    );
};

const numberToAmount = (amountNumber: number | undefined | null): TAmount | null => {
  if (amountNumber === undefined || amountNumber === null) return null;

  return amountNumber.toFixed(2);
};

const amountToPercent = (
  amount: TAmount | undefined | null,
  total: TAmount | undefined | null,
): number | null => {
  if (!amount || !total) return null;

  const amountNumber = parseFloat(amount);
  if (Number.isNaN(amountNumber)) return null;

  const totalNumber = parseFloat(total);
  if (Number.isNaN(totalNumber)) return null;
  if (totalNumber === 0) return null;

  return (amountNumber * 100) / totalNumber;
};

const percentToAmount = (
  percent: number | undefined | null,
  total: TAmount | undefined | null,
): TAmount | null => {
  if (percent === undefined || percent === null) return null;
  if (!total) return null;

  const totalNumber = parseFloat(total);
  if (Number.isNaN(totalNumber)) return null;

  const amount = (totalNumber * percent) / 100;

  return numberToAmount(amount);
};

export {
  amountToPercent,
  currencyFormater,
  currencyFormater2,
  currencyFormater3,
  currencyLocaleFormatter,
  numberToAmount,
  percentToAmount,
  localeFormatter,
};
