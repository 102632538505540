import React, { FC, useMemo } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { rcBankTransfersPath } from '@src/routes';
import { IBankAccountReconciliationItem } from '@src/types/bank_account_reconciliations';
import { TDate } from '@src/types/common';
import { TAmountFilter, TDateFilter, TFilterData } from '@src/types/filter';
import { IReconciliationPaymentAccount } from '@src/types/reconciliation_payment_accounts';

import DownloadIcon from '@src/components/reconciliation_center/month_end/download_action/download_icon';
import FooterContainer from '@src/components/reconciliation_center/month_end/drawers/footer_container';
import inDrawer from '@src/components/reconciliation_center/month_end/drawers/in_drawer';
import TotalAmount from '@src/components/reconciliation_center/month_end/drawers/total_amount';
import {
  useTransfersCollection,
} from '@src/components/reconciliation_center/month_end/drawers/unmatched_transfers/hooks';
import { AppLink } from '@src/components/ui_v2/buttons';
import { useFilterField } from '@src/components/ui_v2/filter';
import DetailsRegion from '@src/components/ui_v2/layout/details_region';
import TableSection from '@src/components/ui_v2/table_section';
import { MoreInfoIcon } from '@src/components/utils/icomoon';
import { useSection } from '@src/components/utils_v2/section';

import Table from './table';

import styles from '@src/components/reconciliation_center/month_end/drawers/styles.module.scss';

const UnmatchedTransfers: FC<{
  reconciliationItem: IBankAccountReconciliationItem,
  reconciliationPaymentAccounts: IReconciliationPaymentAccount[],
  startDate: TDate,
  endDate: TDate,
}> = ({
  reconciliationItem,
  /* eslint-disable @typescript-eslint/no-unused-vars */
  startDate,
  endDate,
  reconciliationPaymentAccounts,
  /* eslint-disable @typescript-eslint/no-unused-vars */
}) => {
  const business = useBusinessContext();
  const section = useSection();
  const queryParams = {
    filter: {
      all_state:              'true',
      reconciliation_item_id: reconciliationItem.id,
    },
  };

  const collection = useTransfersCollection({
    businessId: business.id,
    params:     queryParams,
    section,
  });

  const pages = collection.query.data?.pages || [];
  const totalAmount = pages[0]?.meta?.totalAmount;

  return (
    <>
      <DetailsRegion>
        <div className={ styles.content }>
          <TableSection>
            <Table
              infiniteQuery={ collection.query }
              records={ collection.records }
              sorting={ collection.sorting }
            />
          </TableSection>
        </div>
      </DetailsRegion>
      <FooterContainer>
        <TotalAmount amount={ totalAmount } />
      </FooterContainer>
    </>
  );
};

const Controls: FC<{
  reconciliationItem: IBankAccountReconciliationItem,
  reconciliationPaymentAccounts: IReconciliationPaymentAccount[],
  startDate: TDate,
  endDate: TDate,
}> = ({
  reconciliationItem,
  reconciliationPaymentAccounts,
  startDate,
  endDate
}) => {
  const business = useBusinessContext();
  
  const section = useSection();
  const [rangeDate] = useFilterField<TFilterData, TDateFilter>('transaction_date', section);
  const [fromAccountId] = useFilterField('from_account_id', section);
  const [toAccountId] = useFilterField('to_account_id', section);
  const [amount] = useFilterField<TFilterData, TAmountFilter>('amount', section);
  
  const unmatchedTransfersPathStr = useMemo(() => {
    let transactionDateParam = '';
    let accountParam = '';
    let amountParam = '';

    if (rangeDate && rangeDate.gte !== '' && rangeDate.lte !== '') {
      transactionDateParam = `&transaction_date_start=${rangeDate.gte}&transaction_date_end=${rangeDate.lte}`;
    } else {
      transactionDateParam = `&transaction_date_start=${startDate}&transaction_date_end=${endDate}`;
    }

    if (fromAccountId) {
      accountParam = `&from_account=${encodeURIComponent(
        reconciliationPaymentAccounts.find((f: IReconciliationPaymentAccount) => f.id === Number(fromAccountId))?.name as string
      )}`;
    }

    if (toAccountId) {
      accountParam += `&to_account=${encodeURIComponent(
        reconciliationPaymentAccounts.find((f: IReconciliationPaymentAccount) => f.id === Number(toAccountId))?.name as string
      )}`;
    }

    if (amount) {
      if (amount.val && amount.val !== '') {
        amountParam = `&amount_val=${encodeURIComponent(amount.val)}`;
      }

      if (amount.gte && amount.gte !== '') {
        amountParam = `&amount_min=${encodeURIComponent(amount.gte)}`;
      }

      if (amount.lte && amount.lte !== '') {
        amountParam += `&amount_max=${encodeURIComponent(amount.lte)}`;
      }
    }

    return `${rcBankTransfersPath(business.id)}?reconciliation_item_id=${
      reconciliationItem.id
    }&matched_type=false${transactionDateParam}${accountParam}${amountParam}`;
  }, [
    reconciliationPaymentAccounts,
    rangeDate,
    fromAccountId,
    toAccountId,
    amount,
    business.id,
    reconciliationItem.id,
    startDate,
    endDate,
  ]);

  const queryParams = { filter: { reconciliation_item_id: reconciliationItem.id } };

  return (
    <>
      <AppLink newWindow href={ unmatchedTransfersPathStr }>
        <MoreInfoIcon fontSize={ 18 } inColor="black" />
        { ' ' }
        Full View
      </AppLink>
      <DownloadIcon
        exportParams={ queryParams as object }
        fontSize={ 18 }
        inColor="black"
        type="unmatched_transfers"
      />
    </>
  );
};

export default inDrawer(UnmatchedTransfers, Controls, {
  title:       'Unmatched Transfers',
  sectionName: 'month-end-unmatched-transfers',
});
