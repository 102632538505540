import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useLDBusinessFeatureQuery } from '@src/hooks/queries/launch_darkly_features';

import { Button } from '@src/components/ui_v2/buttons';

import { useAddReportTypeModal } from './add_report_type_modal';

const AddRevenueSystem = () => {
  const business = useBusinessContext();
  const addReportTypeModal = useAddReportTypeModal();
  const featureQuery = useLDBusinessFeatureQuery(
    business.id,
    window.Docyt.Common.Constants.REVENUE_CENTER_NEW_REVENUE_MAPPING,
  );

  if (!featureQuery.data) return null;
  return (
    <>
      <addReportTypeModal.Component
        { ...addReportTypeModal.props }
      />
      <Button
        variant="link"
        onClick={ addReportTypeModal.open }
      >
        Add Mapping
      </Button>
    </>
  );
};

export default AddRevenueSystem;
