import React, { useCallback } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { viewReportRefreshLogs } from '@src/routes';
import { IReport } from '@src/types/report_service/report';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import GridListIcon from '@src/components/utils/icomoon/grid-list';

import styles from '../styles.module.scss';

interface IReportRefreshViewActionProps {
  report: IReport,
}

const ReportRefreshViewAction = ({
  report,
}: IReportRefreshViewActionProps): JSX.Element => {
  const business = useBusinessContext();

  const handleSelect = useCallback(() => {
    const url = viewReportRefreshLogs(business.id, report.id);
    window.open(url, '_blank');
  }, [business, report]);

  return (
    <ActionsDropdown.Action
      className={ styles['dropdown-item'] }
      icon={ <GridListIcon fontSize={ 18 } /> }
      title="Refresh Log"
      onSelect={ handleSelect }
    />
  );
};

export default ReportRefreshViewAction;
