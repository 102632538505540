import React from 'react';

import { useLocalStorage } from '@src/hooks/storage';

import Tooltip from '@src/components/ui_v2/tooltip';
import { CaretIcon } from '@src/components/utils/fa_icons';

import styles from './styles.module.scss';

interface IServiceSidebarSubmenuProps {
  children?: React.ReactNode;
  expandedKey: string;
  title: string;
}

const ServiceSubmenu = ({
  children,
  expandedKey,
  title,
}: IServiceSidebarSubmenuProps): JSX.Element | null => {
  const expand = useLocalStorage(expandedKey, true);

  const handleExpand = () => {
    expand.setValue(!expand.storedValue);
  };

  return (
    <>
      <li>
        <a
          role="button"
          tabIndex={ 0 }
          onClick={ handleExpand }
        >
          <span className={ styles['submenu-icon-spacing'] }>
            {expandedKey && (expand.storedValue ? (
              <CaretIcon ml={ 10 } variant="up" />
            ) : (
              <CaretIcon ml={ 10 } variant="down" />
            ))}
          </span>
          <div className={ styles['content-wrapper'] }>
            <div>
              <Tooltip.Hover className={ styles['submenu-item-container'] } content={ title }>
                <span className={ styles['submenu-item-title'] }>{title}</span>
              </Tooltip.Hover>
            </div>
          </div>
        </a>
      </li>
      {expand.storedValue && children}
    </>
  );
};

export default React.memo(ServiceSubmenu);
