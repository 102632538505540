import React from 'react';

import classNames from 'classnames';

import { useExpandRegion } from './hooks';

import styles from './styles.module.scss';

interface IExpandRegionProps extends React.ComponentPropsWithoutRef<'div'> {}

const ExpandRegion = ({ children, className, ...props }: IExpandRegionProps) => {
  const { toggleStatus } = useExpandRegion();
  const classes = classNames(
    className,
    { [styles['expand-container']]: toggleStatus }
  );

  return (
    <div className={ classes } { ...props }>
      { children }
    </div>
  );
};

export default React.memo(ExpandRegion);
