import { number, object, string } from 'yup';

import { TDate, TID } from '@src/types/common';

interface IPushIntegrationConnectValues {
  startDate: TDate,
  revenueReportTypeId: TID | undefined
  location: string,
}

const addPushIntegrationConnectValidation = object({
  startDate:           string().trim().label('Start Date').required(),
  revenueReportTypeId: number().nullable().label('Revenue Mapping').required(),
  location:            string().trim().label('Location').required(),
});

export {
  IPushIntegrationConnectValues,
  addPushIntegrationConnectValidation,
};
