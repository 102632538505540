import { TID, TOrderDirection, TUUID, TDate } from '@src/types/common';
import {
  IMetric,
  TMetricSortColumn,
} from '@src/types/metrics';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiPost, apiPut } from './helpers';

interface IGetMetricsParams {
  standardIndustryId: number,
  businessId: number,
  isIndustryMetrics?: boolean,
  filters?: object,
  orderColumn?: TMetricSortColumn,
  orderDirection?: TOrderDirection,
  page?: number,
}

interface IShowMetricParams {
  id?: TUUID,
}

interface IGetMetricsResponse {
  meta: {
    totalCount: number,
  },
  collection: IMetric[],
}

interface IGetMetricsTotalCountResponse {
  totalCount: number
}

interface IAddMetricsParams {
  businessId: TID,
  name: string,
  startDate: string,
  frequency: string,
  dataType: string,
  inputDataMode: string,
  standardIndustryId: TID,
}

interface IUpdateMetricParams {
  id: TUUID,
  startDate?: string,
  businessId: TID,
}

interface IGetCategorizedMetricsParams {
  standardIndustryId: TID,
  businessId: TID,
  from: TDate | undefined,
  to: TDate
}

interface IGetCategorizedMetricsResponse {
  businessMetrics: IMetric[],
  laborMetrics: IMetric[]
}

const getMetrics = (
  params: IGetMetricsParams,
): Promise<IGetMetricsResponse> => {
  return apiGet(
    'metrics/api/v1/business_metrics',
    underscoreKeys({
      ...params,
      isIndustryMetrics: params.isIndustryMetrics ? 'true' : undefined,
    }),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      ...cdata,
      collection: cdata.businessMetrics as IMetric[],
    };
  });
};

const getMetricsTotalCount = (
  params: IGetMetricsParams,
): Promise<IGetMetricsTotalCountResponse> => {
  return apiGet(
    'metrics/api/v1/business_metrics/total_count',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return cdata;
  });
};

const showMetric = (
  params: IShowMetricParams,
): Promise<IMetric> => {
  return apiGet(
    `metrics/api/v1/business_metrics/${params.id}`,
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return cdata.businessMetric as IMetric;
  });
};

const addMetric = (
  params: IAddMetricsParams,
): Promise<void> => {
  return apiPost(
    'metrics/api/v1/business_metrics',
    underscoreKeys(params),
  );
};

const updateMetric = (
  params: IUpdateMetricParams,
): Promise<void> => {
  return apiPut(
    `metrics/api/v1/business_metrics/${params.id}`,
    underscoreKeys(params),
  );
};

const getCategorizedMetrics = (
  params: IGetCategorizedMetricsParams,
): Promise<IGetCategorizedMetricsResponse> => {
  return apiGet(
    'metrics/api/v1/business_metrics/categorized_metrics',
    underscoreKeys(params),
  ).then((data) => {
    return {
      businessMetrics: camelizeKeys(data.categorized_metrics.business_metrics),
      laborMetrics:    camelizeKeys(data.categorized_metrics.labor_metrics),
    } as IGetCategorizedMetricsResponse;
  });
};

export {
  IGetMetricsParams,
  IShowMetricParams,
  IGetMetricsResponse,
  IGetMetricsTotalCountResponse,
  IAddMetricsParams,
  IUpdateMetricParams,
  IGetCategorizedMetricsParams,
  IGetCategorizedMetricsResponse,
  getMetrics,
  getMetricsTotalCount,
  showMetric,
  addMetric,
  updateMetric,
  getCategorizedMetrics,
};
