import React, { Children, ReactNode, useEffect } from 'react';

import Tab from './tab';

import styles from './styles.module.scss';

interface IHeaderRegionTabsProps {
  children: ReactNode,
}

const HeaderRegionTabs = ({ children }: IHeaderRegionTabsProps): JSX.Element => {
  useEffect(() => {
    Children.forEach(children, (child) => {
      if (child && typeof child === 'object' && 'type' in child) {
        if (child.type !== Tab) {
          throw new Error(
            'Only "HeaderRegion.Tab" components are allowed as children of "HeaderRegion.Tabs"',
          );
        }
      }
    });
  }, [children]);

  return (
    <div className={ styles['tabs-content'] }>
      {children}
    </div>
  );
};

export default React.memo(HeaderRegionTabs);
