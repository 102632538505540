import React, { useCallback } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { editMappingPath } from '@src/routes';
import { IReport, TReportCategory } from '@src/types/report_service/report';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { MappingIcon } from '@src/components/utils/icomoon';

import styles from '../styles.module.scss';

interface IEditMappingActionProps {
  report: IReport,
  category?: TReportCategory,
}

const EditMappingAction = ({
  report,
  category,
}: IEditMappingActionProps): JSX.Element | null => {
  const business = useBusinessContext();

  const renderRedirect = useCallback(() => {
    return editMappingPath(business.id, report.slug);
  }, [business.id, report.slug]);

  if (!report.editMappingDisabled && category !== 'basic') {
    return (
      <ActionsDropdown.LinkAction
        className={ styles['dropdown-item'] }
        href={ renderRedirect() }
        icon={ <MappingIcon fontSize={ 18 } /> }
        title="View Mapping"
      />
    );
  }

  return null;
};

export default EditMappingAction;
