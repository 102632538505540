import React, { useState, useEffect, useRef, useCallback } from 'react';

import toastr from '@lib/toastr';
import { useDownloadDocument } from '@src/hooks/queries/documents';
import { createAndDownloadBlobFile } from '@src/utils/download_file';

import styles from '../styles.module.scss';

interface IChatDocument {
  fileContentType: string;
  id: number;
  originalFileName: string;
  state: string;
  storageSize: number;
}

export const UploadFileView: React.FC<IChatDocument> = ({
  originalFileName,
  storageSize,
  fileContentType,
  state,
  id,
}) => {
  const [showDocumentMenu, setShowDocumentMenu] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const downloadDocument = useDownloadDocument();

  const { mutate } = downloadDocument;
  const handleDownload = useCallback((type: 'original' | 'extracted') => {
    toastr.success(
      window.Docyt.Common.Constants.Messages.DOCUMENT_DOWNLOADING,
      'Download in progress',
    );
    mutate(
      { documentId: id, type },
      { onSuccess: (data) => createAndDownloadBlobFile(data.fileData, data.fileName) },
    );
  }, [mutate, id]);

  // Handle click outside to close menu
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current
        && buttonRef.current
        && !menuRef.current.contains(event.target as Node)
        && !buttonRef.current.contains(event.target as Node)
      ) {
        setShowDocumentMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getFileIcon = () => {
    if (state === 'uploading') {
      return <span className="color-icon icon-xls-file file-icon" />;
    }
    if (state === 'converted' && fileContentType === 'image/png') {
      return (
        <span className="color-icon icon-xls-file file-icon icon-photo-file" />
      );
    }
    if (state === 'converted' && fileContentType === 'application/pdf') {
      return (
        <span className="color-icon icon-xls-file file-icon icon-pdf-file" />
      );
    }
    return <span className="color-icon icon-xls-file file-icon icon-empty-file" />;
  };

  const formatFileSize = (bytes: number): string => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${Math.round(bytes / 1024 ** i)} ${sizes[i]}`;
  };

  return (
    <div className={ `${styles.messageFileUploadWrapper} message-file-upload-wrapper` }>
      <button
        aria-label="Download original file"
        className="message-file-upload-image show-document-js"
        type="button"
        onClick={ () => handleDownload('original') }
      >
        {getFileIcon()}
      </button>

      <div className="message-file-upload-info">
        {state === 'uploading' ? (
          <>
            <p className="message-file-upload-name">{originalFileName}</p>
            <p className="message-file-upload-progress progress-upload">
              0% of
              {' '}
              {formatFileSize(storageSize)}
            </p>
            <div className="upload-file-statusbar">
              <div className="file-status-line" style={ { width: '0%' } } />
            </div>
          </>
        ) : (
          <>
            <a className="show-document-js pointer">
              <p className="message-file-upload-name">{originalFileName}</p>
            </a>
            <p className="message-file-document-info">
              {formatFileSize(storageSize)}
            </p>
            <div className="message-document-download-progress">
              <p className="message-document-download-progress-info">
                0% of
                {' '}
                {formatFileSize(storageSize)}
              </p>
              <div className="upload-file-statusbar">
                <div className="file-status-line" style={ { width: '0%' } } />
              </div>
            </div>
          </>
        )}
      </div>

      <div className="message-file-upload-icon">
        {state === 'uploading' ? (
          <button
            aria-label="Cancel upload"
            className="cancel-upload-button"
            type="button"
          >
            <i className="fa fa-times-circle-o cancel-upload" />
          </button>
        ) : (
          <>
            <button
              ref={ buttonRef }
              aria-expanded={ showDocumentMenu }
              aria-label="Document menu"
              className={ styles['document-menu-button'] }
              type="button"
              onClick={ () => setShowDocumentMenu(!showDocumentMenu) }
            >
              <i className="fa fa-caret-down triangle-item document-triangle-item" />
            </button>
            <div
              ref={ menuRef }
              className="client__mes-menu-bar document-context-menu"
              style={ { display: showDocumentMenu ? 'block' : 'none' } }
            >
              <button
                className={ styles['document-menu-item'] }
                type="button"
              >
                <i className="icon icon-download" />
                <span>Save to Business Documents</span>
              </button>
              <button
                className={ styles['document-menu-item'] }
                type="button"
              >
                <i className="icon icon-download" />
                <span>Save to Contact Documents</span>
              </button>
              <button
                className={ styles['document-menu-item'] }
                type="button"
              >
                <i className="icon icon-download" />
                <span>Save to My Documents</span>
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UploadFileView;
