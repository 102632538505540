import { useState, useEffect } from 'react';

import { TID } from '@src/types/common';
import {
  fetchLDFeatureFlagValueAndCached,
} from '@src/utils/config';

const useManagementGroupFeatureFlag = (featureFlag: string, managementGroupId: TID) => {
  const managementGroupIdStr = String(managementGroupId);
  const [flagEnabled, setFlagEnabled] = useState<string | boolean | undefined>(
    undefined
  );
  useEffect(() => {
    if (flagEnabled === undefined) {
      fetchLDFeatureFlagValueAndCached(featureFlag, managementGroupIdStr, 'management-group').then((res) => {
        setFlagEnabled(res);
      });
    }
  }, [flagEnabled, featureFlag, managementGroupIdStr]);

  return flagEnabled;
};

export default useManagementGroupFeatureFlag;
