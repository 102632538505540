import { TID } from '@src/types/common';
import { IRevenueCategories } from '@src/types/revenue_categories';
import { IRevenueReportType } from '@src/types/revenue_report_types';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiPost } from './helpers';

interface IGetRevenueReportTypesParams {
  businessId: TID,
  onlyActive?: boolean,
}

interface IGetRevenueCategoriesParams {
  businessId: TID,
  section: string,
}

interface IGetRevenueReportTypesResponse {
  collection: IRevenueReportType[],
}

interface IGetRevenueCategoriesResponse {
  collection: IRevenueCategories[],
}
const getRevenueReportTypes = (
  params: IGetRevenueReportTypesParams,
): Promise<IGetRevenueReportTypesResponse> => {
  return apiGet(
    '/api/v1/revenue_report_types',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);

    return {
      collection: cdata.revenueReportTypes as IRevenueReportType[],
    };
  });
};

const getRevenueCategories = (
  params: IGetRevenueCategoriesParams,
): Promise<IGetRevenueCategoriesResponse> => {
  return apiGet(
    '/api/v1/chart_of_accounts/revenue_categories',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);

    return {
      collection: cdata.chartOfAccounts as IRevenueCategories[],
    };
  });
};

interface IDataCategory {
  id: number;
  isDebit: boolean;
}
interface ICreateRevenueReportTypeParams {
  revenueId: TID
  revenueReportType: ICreateRevenueReportTypeData,
}
interface ICreateRevenueReportTypeData {
  name:string
  frequency: string
  splitRevenueReceivables: boolean,
  categoryIds: IDataCategory[];
  taxIds: IDataCategory[];
  ledgerIds: IDataCategory[];
}

interface ICreateRevenueReportTypeResponse {
  revenueReportType: IRevenueReportType
}

const createRevenueReportType = (
  params: ICreateRevenueReportTypeParams,
) : Promise<ICreateRevenueReportTypeResponse> => {
  return apiPost(
    `/api/v1/revenue_services/${params.revenueId}/revenue_report_types`,
    underscoreKeys(params),
  ).then((data) => {
    return camelizeKeys(data) as ICreateRevenueReportTypeResponse;
  });
};

export {
  IGetRevenueReportTypesParams,
  IGetRevenueReportTypesResponse,
  getRevenueReportTypes,
  ICreateRevenueReportTypeParams,
  ICreateRevenueReportTypeResponse,
  createRevenueReportType,
  IGetRevenueCategoriesParams,
  IGetRevenueCategoriesResponse,
  getRevenueCategories,
};
