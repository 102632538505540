import { TID } from '@src/types/common';
import { TFayeEventCallback } from '@src/types/docyt_events/common';

import { useFayeChannel } from './common';

export const useChatsFayeChannel = (
  chatId: TID,
  callback: TFayeEventCallback<any>,
) => {
  return useFayeChannel(
    `/chats/${chatId}`,
    callback,
  );
};
