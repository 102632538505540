import { useCallback, useState, useMemo } from 'react';

import { useRevenueServiceContext } from '@src/hooks/contexts/revenue_service_context';
import { useGetRevenueCategories } from '@src/hooks/queries/revenue_report_types';

const useStepManager = (initialStep: number = 1) => {
  const [step, setStep] = useState<number>(initialStep);
  const nextStep = useCallback(() => setStep((prev) => prev + 1), []);
  const previousStep = useCallback(() => setStep((prev) => Math.max(prev - 1, 1)), []);
  return { step, nextStep, previousStep };
};

const useRevenueCategoriesData = (businessId: number) => {
  const revenueService = useRevenueServiceContext();

  const category = useGetRevenueCategories({ businessId, section: 'categories' });
  const tax = useGetRevenueCategories({ businessId, section: 'taxes' });
  const otherLedger = useGetRevenueCategories({ businessId, section: 'other_ledgers' });

  const categories = useMemo(() => {
    return (
      category.data?.collection?.map((value) => ({
        label: value.name,
        id:    value.id,
      })) || []
    );
  }, [category.data]);

  const taxes = useMemo(() => {
    const disabledIds = new Set(
      revenueService.revenueServiceCategories
        .filter((c) => c.section === 'other_ledgers')
        .map((c) => c.chartOfAccountId),
    );

    return (
      tax.data?.collection?.map((value) => ({
        label:    value.name,
        id:       value.id,
        disabled: disabledIds.has(value.id),
      })) || []
    );
  }, [tax.data, revenueService]);

  const otherLedgers = useMemo(() => {
    const disabledIds = new Set(
      revenueService.revenueServiceCategories
        .filter((c) => c.section === 'taxes')
        .map((c) => c.chartOfAccountId),
    );

    return (
      otherLedger.data?.collection?.map((value) => ({
        label:    value.name,
        id:       value.id,
        disabled: disabledIds.has(value.id),
      })) || []
    );
  }, [otherLedger.data, revenueService]);

  return { categories, taxes, otherLedgers };
};

export {
  useStepManager,
  useRevenueCategoriesData,
};
