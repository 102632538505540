import React, { Children, isValidElement, useEffect, useMemo, useRef } from 'react';

import classNames from 'classnames';

import FooterHint from './footer_hint';
import FooterMetrics from './footer_metrics';
import { useDetailsRegion } from '../hooks';

import styles from '../styles.module.scss';

interface IDetailsRegionFooterProps extends React.ComponentPropsWithoutRef<'div'> {}

const DetailsRegionFooter = ({ children, className, ...props }: IDetailsRegionFooterProps) => {
  const refElement = useRef<HTMLDivElement | null>(null);
  const classes = classNames(styles['details-region-footer'], className);

  const { setFooterHeight } = useDetailsRegion();

  const [hintContent, metricsContent] = useMemo(() => {
    let hint = null;
    let metrics = null;

    Children.forEach(children, (child) => {
      if (isValidElement(child)) {
        if (child.type === FooterHint) hint = child;
        metrics = Children.toArray(children).filter((itm: any) => itm.type === FooterMetrics);
      }
    });

    return [hint, metrics];
  }, [children]);

  useEffect(() => {
    setTimeout(() => {
      if (refElement.current) {
        setFooterHeight(refElement.current.clientHeight);
      }
    }, 50);
  }, [setFooterHeight]);

  return (
    <div ref={ refElement } className={ classes } { ...props }>
      { hintContent }
      { metricsContent && (
        <div className={ styles['metrics-content'] }>{ metricsContent }</div>
      )}
    </div>
  );
};

export default React.memo(DetailsRegionFooter);
