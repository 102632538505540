import React, { useMemo } from 'react';

import { useGetPortfolio } from '@src/hooks/queries/portfolio_services/portfolio_services';
import useUserFeatureFlag from '@src/hooks/use_user_feature_flag';
import { multiEntityReportsPath, portfolioDashboardPath, portfolioDashboardsWidgetPath } from '@src/routes';
import { IBusiness } from '@src/types/businesses';
import { TLaunchDarklyFeature } from '@src/types/launch_darkly';

import AddNewPortfolio from './add_new_portfolio';
import ServiceItem from '../header/module_header/module_header_item';
import ModuleHeaderMenu from '../header/module_header/module_header_menu';

import styles from '../header/navigation_styles.module.scss';

interface IServiceMenuProps {
  launchDarklyFeature?: TLaunchDarklyFeature,
  businesses: IBusiness[]
}

const NewPortfolioDropDownList = ({
  launchDarklyFeature,
  businesses,
}: IServiceMenuProps) => {
  const portfolioResp = useGetPortfolio();
  const isPortfolioFeatureEnabled = useUserFeatureFlag(window.Docyt.Common.Constants.ENABLE_CREATE_PORTFOLIO_OPTION, true);

  const portfolioList = useMemo(() => {
    return portfolioResp?.data?.portfolios || [];
  }, [portfolioResp.data]);

  const selectedPortfolioId = localStorage.getItem('portfolio-id');
  const getSelectedPortfolio = () => {
    if (portfolioList.length > 0) {
      const selectedPortfolio = portfolioList.filter(
        (item) => item.id === Number(selectedPortfolioId),
      );
      if (selectedPortfolio.length) {
        return selectedPortfolio[0].name.toUpperCase();
      }
    }
    if (isPortfolioFeatureEnabled) {
      return 'Select Portfolio';
    }
    return window.Docyt.Common.Constants.PORTFOLIO_DROPDOWN_TITLE.toUpperCase();
  };

  return (
    <div data-global-feature-toggle={ launchDarklyFeature }>
      <ModuleHeaderMenu
        activePlaceholder={ !selectedPortfolioId }
        isGlobalNavigationEnabled
        className="font-16 font-bold"
        focusToMyClient={ null }
        listClassName="portfolio-dropdown-list"
        showSelect={ false }
        title={ getSelectedPortfolio() }
      >
        <li className="p-t-8 p-b-4">
          <span className={ `m-l-18 m-b-16 ${styles['dropdown-item-title']}` }>
            { window.Docyt.Common.Constants.PORTFOLIO_DROPDOWN_TITLE.toUpperCase() }
          </span>
        </li>
        {!isPortfolioFeatureEnabled ? (
          <>
            <ServiceItem
              serviceUrl={ portfolioDashboardPath() }
              title={ window.Docyt.Common.Constants.PORTFOLIO_DROPDOWN_ITEMS.NEW_KPI_DASHBOARD }
            />
            <ServiceItem
              serviceUrl={ multiEntityReportsPath() }
              title={ window.Docyt.Common.Constants.PORTFOLIO_DROPDOWN_ITEMS.REPORTS }
            />
          </>
        ) : (
          <>
            {portfolioList.map((portfolio) => {
              return (
                <ServiceItem
                  key={ portfolio.name }
                  serviceUrl={ portfolioDashboardsWidgetPath(portfolio.id, portfolio.standardCategoryId) }
                  title={ portfolio.name }
                />
              );
            })}
          </>
        )}
        <AddNewPortfolio businesses={ businesses } />
      </ModuleHeaderMenu>
    </div>
  );
};

export default NewPortfolioDropDownList;
