import React, { FC } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { allTransactionsPath } from '@src/routes';
import { IBankAccountReconciliationItem } from '@src/types/bank_account_reconciliations';

import DownloadIcon from '@src/components/reconciliation_center/month_end/download_action/download_icon';
import FooterContainer from '@src/components/reconciliation_center/month_end/drawers/footer_container';
import { useTSDCollection } from '@src/components/reconciliation_center/month_end/drawers/hooks';
import inDrawer from '@src/components/reconciliation_center/month_end/drawers/in_drawer';
import TotalAmount from '@src/components/reconciliation_center/month_end/drawers/total_amount';
import { AppLink } from '@src/components/ui_v2/buttons';
import DetailsRegion from '@src/components/ui_v2/layout/details_region';
import TableSection from '@src/components/ui_v2/table_section';
import { MoreInfoIcon } from '@src/components/utils/icomoon';
import { useSection } from '@src/components/utils_v2/section';

import UncategorizedTransactionsTable from './table';

import styles from '@src/components/reconciliation_center/month_end/drawers/styles.module.scss';

interface UncategorizedTransactionsProps {
  reconciliationItem: IBankAccountReconciliationItem;
}

const UncategorizedTransactions: FC<UncategorizedTransactionsProps> = ({ reconciliationItem }) => {
  const business = useBusinessContext();
  const section = useSection();
  const queryParams = { reconciliation_item_id: reconciliationItem.id.toString() };

  const collection = useTSDCollection({
    businessId: business.id,
    params:     queryParams,
    section,
  });

  const pages = collection.query.data?.pages || [];
  const totalAmount = pages[0]?.meta?.totalAmount;

  return (
    <>
      <DetailsRegion>
        <div className={ styles.content }>
          <TableSection>
            <UncategorizedTransactionsTable
              infiniteQuery={ collection.query }
              queryParams={ queryParams }
              records={ collection.records }
              sorting={ collection.sorting }
            />
          </TableSection>
        </div>
      </DetailsRegion>
      <FooterContainer>
        <TotalAmount amount={ totalAmount } />
      </FooterContainer>
    </>
  );
};

const Controls: FC<UncategorizedTransactionsProps> = ({ reconciliationItem }) => {
  const business = useBusinessContext();

  const uncategorizedTransactionsPath = allTransactionsPath(
    business.id,
    { reconciliation_item_id: reconciliationItem.id },
  );

  const queryParams = { filter: { reconciliation_item_id: reconciliationItem.id } };

  return (
    <>
      <AppLink newWindow href={ uncategorizedTransactionsPath }>
        <MoreInfoIcon fontSize={ 18 } inColor="black" />
        { ' ' }
        Full View
      </AppLink>
      <DownloadIcon
        exportParams={ queryParams as object }
        fontSize={ 18 }
        inColor="black"
        type="uncategorized_transactions"
      />
    </>
  );
};

export default inDrawer(UncategorizedTransactions, Controls, {
  title:       'Uncategorized Transactions',
  sectionName: 'month-end-uncategorized-txns',
});
