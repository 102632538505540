import React, { useCallback, useEffect, useState } from 'react';

import toastr from '@lib/toastr';
import { industryOptions } from '@src/constants/industry_options';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useCreatePortfolio, useUpdatePortfolio } from '@src/hooks/queries/portfolio_services/portfolio_services';
import { getBusinessUsersForPortfolio } from '@src/requests/portfolio_service/portfolio';
import { portfolioDashboardsWidgetPath } from '@src/routes';
import { IBusiness } from '@src/types/businesses';
import { IPortfolio } from '@src/types/portfolio';

import Modal from '@src/components/ui/modal';
import { SelectInput, TextInput, TOption } from '@src/components/ui_v2/inputs';
import MultiSelectAddItem from '@src/components/ui_v2/inputs/multi_select_add_item';

interface ICreatePortfolioProps extends IUseModalProps {
  businesses: IBusiness[];
  onCreate?: () => void;
  isEdit?: boolean;
  portfolio?: IPortfolio;
}

const CreatePortfolio = React.memo(({
  isOpen,
  onCancel,
  onCreate,
  onDone,
  businesses,
  isEdit,
  portfolio,
}: ICreatePortfolioProps): JSX.Element => {
  const [refreshKey, setRefreshKey] = useState(0);
  const createPortfolio = useCreatePortfolio();
  const updatePortfolio = useUpdatePortfolio();
  const [portfolioName, setPortfolioName] = useState<string>('');
  const [selectedIndustry, setSelectedIndustry] = useState<TOption | null>(null);
  const [businessUserOptions, setBusinessUserOptions] = useState<TOption[]>([]);
  const [selectedIndustryBusiness, setSelectedIndustryBusiness] = useState<TOption[]>([]);
  const [industryBusinessData, setIndustryBusinessData] = useState<TOption[]>([]);
  const [selectedBusinessUsers, setSelectedBusinessUsers] = useState<TOption[]>([]);

  const handleOnChangeIndustry = (options: TOption | null) => {
    setSelectedIndustry(options);
    const indusBusiness = businesses.filter((business) => {
      return options?.value === `${business.standardCategoryId}`;
    });
    const tempBusiness = indusBusiness.map((item) => { return { label: item.displayName, value: `${item.id}` }; });
    setIndustryBusinessData(tempBusiness);
    setSelectedIndustryBusiness([]);
    return tempBusiness;
  };

  const handleOnChangeBusiness = (options: TOption[], portfolioData?: IPortfolio) => {
    setSelectedIndustryBusiness(options);
    if (options.length > 0) {
      getBusinessUsersForPortfolio({ businessIds: options.map((item) => Number(item.value)) }).then((response) => {
        if (response) {
          const tempBusinessUserOptions: TOption[] = [];
          response.businessUsers.forEach((businessUser) => {
            const businessUserOption = { label: `${businessUser.parsedFullname} (${businessUser.email})`, value: `${businessUser.id}` };
            tempBusinessUserOptions.push(businessUserOption);
          });
          setBusinessUserOptions(tempBusinessUserOptions);
          let selectedBusinessUsersOptions: TOption[] = [];
          if (portfolioData) {
            selectedBusinessUsersOptions = tempBusinessUserOptions.filter((businessUser) => {
              return portfolioData.viewerIds.some(
                (selectedBusinessUser) => String(selectedBusinessUser) === businessUser.value,
              );
            });
          } else {
            selectedBusinessUsersOptions = tempBusinessUserOptions.filter((businessUser) => {
              return selectedBusinessUsers.some(
                (selectedBusinessUser) => selectedBusinessUser.value === businessUser.value,
              );
            });
          }
          setSelectedBusinessUsers(selectedBusinessUsersOptions);
        }
      });
    } else {
      setBusinessUserOptions([]);
      setSelectedBusinessUsers([]);
    }
  };

  useEffect(() => {
    if (isEdit && portfolio) {
      setPortfolioName(portfolio.name);
      const industryOption = industryOptions.find((option) => option.value === portfolio.standardCategoryId.toString());
      setSelectedIndustry(industryOption || null);
      if (industryOption) {
        const tempBusiness = handleOnChangeIndustry(industryOption);

        if (tempBusiness.length > 0) {
          const businessOptions = tempBusiness.filter((businessOption) => (
            portfolio.businessIds.includes(Number(businessOption.value))
          ));
          handleOnChangeBusiness(businessOptions, portfolio);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businesses, portfolio, isEdit, refreshKey]);

  const resetFields = () => {
    setSelectedIndustry(null);
    setPortfolioName('');
    setIndustryBusinessData([]);
    setSelectedIndustryBusiness([]);
    setSelectedBusinessUsers([]);
    setBusinessUserOptions([]);
  };

  const handleCancel = useCallback(() => {
    resetFields();
    setRefreshKey((pre) => pre + 1);
    onCancel();
  }, [onCancel]);

  const [isApiInProgress, setIsApiInProgress] = useState(false);

  const isDisabled = portfolioName.trim() === ''
    || !selectedIndustry
    || selectedIndustryBusiness.length === 0 || isApiInProgress;

  const handleSuccess = () => {
    resetFields();
    setIsApiInProgress(false);
    onCreate?.();
    const message = isEdit ? 'Portfolio Updated Successfully' : 'Portfolio Created Successfully';
    toastr.success(message, 'Success!');
    onDone();
  };

  const getPortfolioParams = () => {
    return {
      portfolio: {
        name:               portfolioName,
        standardCategoryId: Number(selectedIndustry?.value),
        businessIds:        selectedIndustryBusiness.map((item) => Number(item.value)),
        viewerIds:          selectedBusinessUsers.map((item) => Number(item.value)),
      },
    };
  };

  const handleProceed = () => {
    if (isDisabled) return;

    setIsApiInProgress(true);

    const reportSetting = getPortfolioParams();

    createPortfolio.mutate(reportSetting, {
      onSuccess: (response) => {
        handleSuccess();
        Backbone.history.navigate(
          portfolioDashboardsWidgetPath(
            response.portfolio.id,
            response.portfolio.standardCategoryId,
          ),
          { trigger: true },
        );
      },
      onError: (err) => {
        setIsApiInProgress(false);
        toastr.error(err.message, 'Error!');
        onDone();
      },
    });
  };

  const handleUpdate = () => {
    if (!portfolio || isDisabled) return;

    setIsApiInProgress(true);

    const portfolioParams = getPortfolioParams();

    const reportSetting = {
      id: portfolio.id,
      ...portfolioParams,
    };
    updatePortfolio.mutate(reportSetting, {
      onSuccess: () => {
        handleSuccess();
        setRefreshKey((pre) => pre + 1);
      },
      onError: (err) => {
        setIsApiInProgress(false);
        toastr.error(err.message, 'Error!');
        onDone();
      },
    });
  };

  const modalTitle = isEdit ? 'Edit Portfolio' : 'Add New Portfolio';
  const buttonTitle = isEdit ? 'Update' : 'Add';

  return (
    <Modal.Standard
      isLoading={ isApiInProgress }
      isProceedDisabled={ isDisabled }
      proceedTitle={ buttonTitle }
      show={ isOpen }
      title={ modalTitle }
      onCancel={ handleCancel }
      onProceed={ isEdit ? handleUpdate : handleProceed }
    >
      {() => (
        <div className="download-report-setting">
          <div className="inputRow m-b-15">
            <span className="inputTitle">Portfolio Name:</span>
            <div className="export-input">
              <TextInput
                placeholder="Enter name"
                value={ portfolioName }
                onChange={ (e) => setPortfolioName(e.target.value) }
              />
            </div>
          </div>

          <div className="inputRow m-b-15">
            <span className="inputTitle">Select Industry:</span>
            <SelectInput
              className="export-input"
              isDisabled={ isEdit }
              options={ industryOptions }
              placeholder="Select Industry"
              value={ selectedIndustry }
              onChange={ handleOnChangeIndustry }
            />
          </div>

          <div className="inputRow m-b-15">
            <span className="inputTitle">Select Business(s):</span>
            <div className="export-input">
              <MultiSelectAddItem
                addItem
                hideSelectAllOption
                menuClassName="dropdown-menu-fix-height"
                options={ industryBusinessData }
                placeholder="Select Business"
                value={ selectedIndustryBusiness }
                onChange={ handleOnChangeBusiness }
              />
            </div>
          </div>

          <div className="inputRow m-b-15">
            <span className="inputTitle">Select Users:</span>
            <div className="export-input">
              <MultiSelectAddItem
                addItem
                hideSelectAllOption
                menuClassName="dropdown-menu-fix-height width-auto"
                options={ businessUserOptions }
                placeholder="Select Users"
                value={ selectedBusinessUsers }
                onChange={ setSelectedBusinessUsers }
              />
            </div>
          </div>
        </div>
      )}
    </Modal.Standard>
  );
});

CreatePortfolio.displayName = 'CreatePortfolio';

const useCreatePortfolioModal = makeUseModal(
  CreatePortfolio,
);

export {
  CreatePortfolio as default, ICreatePortfolioProps, useCreatePortfolioModal,
};
