import React, { useState, useRef, useCallback, useEffect } from 'react';

import { IChatParticipant } from '@src/requests/bank_account_reconciliations';

import MentionList from './mention_list';

import styles from '../styles.module.scss';

interface IMentionTag {
  id: number;
  name: string;
  avatar: string;
  role: string;
}

interface IMessagePart {
  type: 'text' | 'mention';
  content: string;
  mentionData?: IMentionTag;
}

interface IMentionInputProps {
  chatMembers: IChatParticipant[];
  messageInputRef: React.RefObject<HTMLDivElement>;
  onMessagePartsChange: (parts: IMessagePart[]) => void;
  onSend: () => void;
  initialMessage?: string;
}

export const MentionInput: React.FC<IMentionInputProps> = ({
  chatMembers,
  messageInputRef,
  onMessagePartsChange,
  onSend,
  initialMessage,
}) => {
  const [showMentionList, setShowMentionList] = useState(false);
  const [mentionAnchor, setMentionAnchor] = useState({ top: 0, left: 0 });
  const [mentionList, setMentionList] = useState<IMentionTag[]>([]);
  const [selectedMentionIndex, setSelectedMentionIndex] = useState(0);
  const [mentionFilterText, setMentionFilterText] = useState<string | null>(null);
  const [messageParts, setMessageParts] = useState<IMessagePart[]>([{ type: 'text', content: '' }]);
  const [cursorPosition, setCursorPosition] = useState(0);
  const mentionListRef = useRef<HTMLDivElement>(null);

  // Build mention list
  const buildMentionList = useCallback(() => {
    const tags: IMentionTag[] = [];
    chatMembers.forEach((member) => {
      // @ts-ignore - window.Docyt is defined globally
      if (member.id === window.Docyt?.currentAdvisor?.get('id')) return;

      const memberName = [member.firstName, member.middleName, member.lastName]
        .filter(Boolean)
        .join(' ');
      const displayName = /\S/.test(memberName) ? memberName : (member.email || member.phone);

      tags.push({
        name:   displayName || '',
        avatar: member.avatar?.avatarUrl || '',
        role:   member.role ? `@${member.role.toLowerCase()}` : ' ',
        id:     member.id,
      });
    });

    return tags;
  }, [chatMembers]);

  // Handle input changes
  const handleInput = (e: React.FormEvent<HTMLDivElement>) => {
    const selection = window.getSelection();
    if (!selection) return;

    const range = selection.getRangeAt(0);
    const currentNode = range.startContainer;
    const currentPosition = range.startOffset;

    let textBeforeCursor = '';
    const nodes = Array.from(e.currentTarget.childNodes);
    const currentNodeIndex = nodes.findIndex((node) => node === currentNode);

    // Process text before cursor
    nodes.forEach((node, index) => {
      if (index > currentNodeIndex) return;

      if (node === currentNode) {
        textBeforeCursor += (node.textContent || '').slice(0, currentPosition);
      } else if (node instanceof Element && node.classList.contains('atwho-inserted')) {
        textBeforeCursor += ' ';
      } else {
        textBeforeCursor += node.textContent || '';
      }
    });

    const isAtSymbolTyped = textBeforeCursor.slice(-1) === '@';

    setCursorPosition(currentPosition);

    if (isAtSymbolTyped) {
      const rect = range.getBoundingClientRect();
      const inputRect = messageInputRef.current?.getBoundingClientRect();

      if (inputRect) {
        setMentionAnchor({
          top:  rect.top - inputRect.top - 250,
          left: rect.left - inputRect.left,
        });
      }

      setMentionList(buildMentionList());
      setShowMentionList(true);
    } else {
      const lastAtIndex = textBeforeCursor.lastIndexOf('@');
      if (lastAtIndex >= 0) {
        const textAfterAt = textBeforeCursor.slice(lastAtIndex + 1);
        if (/\s/.test(textAfterAt) || textAfterAt === '') {
          setShowMentionList(false);
        } else {
          setMentionFilterText(textAfterAt);
        }
      } else {
        setShowMentionList(false);
      }
    }

    // Parse message parts - modify this logic
    const parts: IMessagePart[] = [];
    let currentText = '';

    // First check if there are mention parts in the initial messageParts
    const existingMentions = messageParts.filter((part) => part.type === 'mention');

    // Create a mapping to track processed mentions
    const processedMentions = new Set<string>();

    nodes.forEach((node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        currentText += node.textContent || '';
      } else if (node instanceof Element && node.classList.contains('atwho-inserted')) {
        if (currentText) {
          parts.push({ type: 'text', content: currentText });
          currentText = '';
        }

        const mentionText = node.textContent || '';
        const mentionMatch = mentionText.match(/@(.+)/);
        if (mentionMatch) {
          const name = mentionMatch[1].trim();
          // First try to find a match from existing mentions
          const existingMention = existingMentions.find((m) => m.mentionData?.name === name
            || m.content.includes(`name:${name}`)
            || m.content.includes(`role:${name.toUpperCase()}`));

          if (existingMention && !processedMentions.has(name)) {
            parts.push(existingMention);
            processedMentions.add(name);
          } else {
            // If no existing mention found, create a new one
            const member = chatMembers.find((m) => {
              const memberName = [m.firstName, m.middleName, m.lastName]
                .filter(Boolean)
                .join(' ');
              return memberName === name;
            });

            if (member) {
              parts.push({
                type:    'mention',
                content: member.firstName === 'ADMIN' || member.firstName === 'all'
                  ? `$[role:${member.firstName}]$`
                  : `$[uid:${member.id},name:${name}]$`,
                mentionData: {
                  id:     member.id,
                  name,
                  avatar: member.avatar?.avatarUrl || '',
                  role:   member.role || '',
                },
              });
            }
          }
        }
      }
    });

    if (currentText) {
      parts.push({ type: 'text', content: currentText });
    }

    setMessageParts(parts);
    onMessagePartsChange(parts);
  };

  // Handle mention selection
  const handleSelectMention = (tag: IMentionTag) => {
    const currentText = messageParts[messageParts.length - 1].content;
    const lastAtIndex = currentText.lastIndexOf('@');

    if (lastAtIndex >= 0 && typeof window !== 'undefined') {
      const beforeAt = currentText.slice(0, lastAtIndex);
      const afterAt = currentText.slice(cursorPosition);

      const newParts: IMessagePart[] = [
        ...messageParts.slice(0, -1),
        { type: 'text', content: beforeAt },
        {
          type:    'mention',
          content: tag.name === 'ADMIN' || tag.name === 'all'
            ? `$[role:${tag.name}]$`
            : `$[uid:${tag.id},name:${tag.name}]$`,
          mentionData: tag,
        },
        { type: 'text', content: ' ' },
      ];

      if (afterAt.trim() && afterAt.trim() !== '@') {
        newParts.push({ type: 'text', content: afterAt.trimLeft() });
      }

      setMessageParts(newParts);
      onMessagePartsChange(newParts);
      setShowMentionList(false);
      setMentionFilterText(null);

      if (messageInputRef.current) {
        const html = newParts.map((part) => {
          if (part.type === 'mention') {
            // eslint-disable-next-line max-len
            return `<span class="atwho-inserted" data-atwho-at-query="@" contenteditable="false">@${part.mentionData?.name}</span>`;
          }
          return part.content;
        }).join('');

        messageInputRef.current.innerHTML = html;
        const spaceNode = window.document.createTextNode('\u00A0');
        messageInputRef.current.appendChild(spaceNode);

        const selection = window.getSelection();
        if (selection && window.document) {
          const range = window.document.createRange();
          range.setStartAfter(spaceNode);
          range.collapse(true);
          selection.removeAllRanges();
          selection.addRange(range);
          messageInputRef.current.focus();
        }
      }
    }
  };

  // Get filtered mention list
  const getFilteredMentionList = useCallback(() => {
    if (!mentionFilterText) return mentionList;
    return mentionList.filter((tag) => tag.name.toLowerCase().includes(mentionFilterText.toLowerCase()));
  }, [mentionList, mentionFilterText]);

  // Handle keyboard events
  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (showMentionList) {
      const filteredList = getFilteredMentionList();
      switch (e.key) {
        case 'Enter': {
          e.preventDefault();
          const selectedMention = filteredList[selectedMentionIndex];
          if (selectedMention) {
            handleSelectMention(selectedMention);
          }
          break;
        }
        case 'ArrowUp': {
          e.preventDefault();
          setSelectedMentionIndex((prevIndex) => {
            const newIndex = prevIndex > 0 ? prevIndex - 1 : filteredList.length - 1;
            return newIndex;
          });
          break;
        }
        case 'ArrowDown': {
          e.preventDefault();
          setSelectedMentionIndex((prevIndex) => {
            const newIndex = prevIndex < filteredList.length - 1 ? prevIndex + 1 : 0;
            return newIndex;
          });
          break;
        }
        case 'Escape': {
          e.preventDefault();
          setShowMentionList(false);
          setSelectedMentionIndex(0);
          break;
        }
        default:
          break;
      }
    } else if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSend();
    }
  };

  // Reset selected index when filter text changes
  useEffect(() => {
    setSelectedMentionIndex(0);
  }, [mentionFilterText]);

  useEffect(() => {
    if (initialMessage && messageInputRef.current) {
      // Correctly convert text to HTML display
      const processedMessage = initialMessage.replace(
        /\$\[(?:uid:(\d+),name:([^\]]+)|role:(ADMIN|all))\]\$/g,
        (match, uid, name, role) => {
          if (role) {
            return (
              '<span class="atwho-inserted" contenteditable="false" '
              + `data-mention-type="role">@${role.toLowerCase()}</span>`
            );
          }
          return `<span class="atwho-inserted" contenteditable="false" data-mention-id="${uid}">@${name}</span>`;
        },
      );

      messageInputRef.current.innerHTML = processedMessage;

      // But here the messageParts parsing is after HTML setting
      const parts: IMessagePart[] = [];
      const matches = initialMessage.match(/\$\[(?:uid:\d+,name:[^\]]+|role:(?:ADMIN|all))\]\$|[^$]+/g) || [];

      matches.forEach((part) => {
        const userMentionMatch = part.match(/\$\[uid:(\d+),name:([^\]]+)\]\$/);
        const roleMentionMatch = part.match(/\$\[role:(ADMIN|all)\]\$/);

        if (userMentionMatch) {
          const [, uid, name] = userMentionMatch;
          const member = chatMembers.find((m) => m.id.toString() === uid);
          if (member) {
            parts.push({
              type:        'mention',
              content:     part,
              mentionData: {
                id:     member.id,
                name,
                avatar: member.avatar?.avatarUrl || '',
                role:   member.role || '',
              },
            });
          }
        } else if (roleMentionMatch) {
          const [, role] = roleMentionMatch;
          const member = chatMembers.find((m) => m.firstName === role);
          if (member) {
            parts.push({
              type:        'mention',
              content:     part,
              mentionData: {
                id:     member.id,
                name:   role,
                avatar: member.avatar?.avatarUrl || '',
                role:   member.role || '',
              },
            });
          }
        } else {
          parts.push({ type: 'text', content: part });
        }
      });

      setMessageParts(parts);
      onMessagePartsChange(parts);

      // Add non-breaking spaces
      const spaceNode = window.document.createTextNode('\u00A0');
      messageInputRef.current.appendChild(spaceNode);

      // Move cursor to end
      const selection = window.getSelection();
      if (selection && window.document) {
        const range = window.document.createRange();
        range.setStartAfter(spaceNode);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  }, [initialMessage, messageInputRef, chatMembers, onMessagePartsChange]);

  return (
    <>
      <div
        ref={ messageInputRef }
        contentEditable
        aria-label="Message input"
        className={ styles['chat-input'] }
        placeholder="Type your message"
        role="textbox"
        tabIndex={ 0 }
        onInput={ handleInput }
        onKeyDown={ handleKeyPress }
      />

      {showMentionList && (
        <MentionList
          ref={ mentionListRef }
          mentionFilterText={ mentionFilterText }
          mentionList={ mentionList }
          selectedIndex={ selectedMentionIndex }
          style={ {
            left: mentionAnchor.left,
          } }
          onSelect={ handleSelectMention }
        />
      )}
    </>
  );
};

export default MentionInput;
