import React, { useCallback } from 'react';

import classNames from 'classnames';

import { bankConnectionCenterPath } from '@src/routes';
import { IPaymentAccount } from '@src/types/payment_accounts';

import { LinkButton } from '@src/components/ui/buttons';
import Tooltip from '@src/components/ui_v2/tooltip';
import { InfoIcon } from '@src/components/utils/icomoon';

import styles from './styles.module.scss';

interface IEmployeeCardMappingStatusFieldProps {
  paymentAccount: IPaymentAccount;
}

const EmployeeCardMappingStatusField = ({
  paymentAccount,
}: IEmployeeCardMappingStatusFieldProps): JSX.Element => {
  // Opens employee card mapping modal in a new tab by encoding mapping details as a token in the URL.
  // The token contains management group ID, payment account ID, financial institution bank account ID,
  // and a flag indicating this is for creating a new mapping.
  const handleCreateMapping = useCallback(() => {
    const token = window.btoa(JSON.stringify({
      managementGroupId:                 paymentAccount.managementGroupId,
      reconciliationPaymentAccountId:    paymentAccount.parentRpaId ?? paymentAccount.reconciliationPaymentAccountId,
      financialInstitutionBankAccountId: paymentAccount.financialInstitutionBankAccountId,
      isCreateMappingRedirection:        true,
    }));
    window.open(`${bankConnectionCenterPath(paymentAccount.businessId)}/token/${token}`, '_blank');
  }, [paymentAccount]);

  // Opens a new tab to view existing employee card mappings by encoding mapping details as a token in the URL.
  // The token contains payment account ID, financial institution bank account ID,
  // and a flag indicating this is for viewing existing mappings.
  const handleViewMappings = useCallback(() => {
    const token = window.btoa(JSON.stringify({
      reconciliationPaymentAccountId:    paymentAccount.parentRpaId ?? paymentAccount.reconciliationPaymentAccountId,
      financialInstitutionBankAccountId: paymentAccount.financialInstitutionBankAccountId,
      isCreateMappingRedirection:        false,
    }));
    window.open(`${bankConnectionCenterPath(paymentAccount.businessId)}/token/${token}`, '_blank');
  }, [paymentAccount]);

  return (
    <div className={ styles['employee-card-mapping-status-field'] }>
      <div className={ styles['employee-card-account-owner-label'] }>
        Account Owner
      </div>
      <div className={ styles['employee-card-account-owner-status-field-value'] }>
        {paymentAccount?.isFetchingAccountOwnerInformation ? (
          <div className={ styles['employee-card-account-owner-status'] }>
            {paymentAccount?.mappingStatus}
            <Tooltip.Hover content="We are currently fetching the account owners based on the transactions. This might take hours to fetch." variant="dark">
              <div className={ styles['employee-card-account-owner-info-icon'] }>
                <InfoIcon />
              </div>
            </Tooltip.Hover>
          </div>
        ) : (
          <>
            {!paymentAccount.isDisabled && (
              <div className={ styles['employee-card-account-owner'] }>
                {paymentAccount.accountOwner}
              </div>
            )}
            <div className={ styles['mapping-button'] }>
              <LinkButton
                className={ classNames(styles['link-button'], {
                  [styles['view-mapping']]:   !paymentAccount?.isMapRequired,
                  [styles['create-mapping']]: paymentAccount?.isMapRequired,
                }) }
                title={ !paymentAccount?.isMapRequired ? 'View Mapping' : 'Create Mapping' }
                onClick={ !paymentAccount?.isMapRequired ? handleViewMappings : handleCreateMapping }
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EmployeeCardMappingStatusField;
