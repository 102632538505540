import { useState, useCallback } from 'react';

const useLocalStorage = <Type>(
  key: string,
  initialValue: Type,
) => {
  const [storedValue, setStoredValue] = useState<Type>(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch {
      return initialValue;
    }
  });

  const setValue = useCallback((value: Type) => {
    setStoredValue(value);
    if (typeof window !== 'undefined') {
      window.localStorage.setItem(key, JSON.stringify(value));
    }
  }, [key]);
  return { storedValue, setValue };
};

export { useLocalStorage };
