import { useMutation } from 'react-query';

import { TID } from '@src/types/common';
import { IMetricsService } from '@src/types/services';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, authorizedApiRequest } from './helpers';

const getMetricsServiceByBusinessId = (businessId: number): Promise<IMetricsService> => {
  return apiGet(
    '/api/v1/metrics_services/by_business_id',
    underscoreKeys({ businessId }),
  ).then((data) => camelizeKeys(data.metrics_service) as IMetricsService);
};

interface IGetMetricsServiceTemplateExcel {
  businessId: TID,
  standardIndustryId: TID,
}

const getMetricsServiceTemplateExcel = async (params: IGetMetricsServiceTemplateExcel): Promise<Blob> => {
  const url = '/metrics/api/v1/business_metrics/download_metric_template';
  const queryParams = new URLSearchParams({
    business_id:          params.businessId.toString(),
    standard_industry_id: params.standardIndustryId.toString(),
  });
  return authorizedApiRequest({
    url:          `${url}?${queryParams.toString()}`,
    method:       'GET',
    responseType: 'blob',
    params,
  })
    .then((blob) => {
      return blob;
    })
    .catch(async (error) => {
      if (error.response && error.response.data instanceof Blob) {
        try {
          const errorText = await error.response.data.text();
          const jsonError = JSON.parse(errorText);
          return Promise.reject(jsonError);
        } catch {
          return Promise.reject(new Error('Failed to parse error response as JSON'));
        }
      }
      return Promise.reject(error);
    });
};

const useMetricsServiceTemplateExcel = () => {
  return useMutation<Blob, Error, IGetMetricsServiceTemplateExcel>(getMetricsServiceTemplateExcel);
};

export {
  getMetricsServiceByBusinessId,
  useMetricsServiceTemplateExcel,
};
