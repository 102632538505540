import { TID } from '@src/types/common';
import { IReconciliationCenter } from '@src/types/reconciliation_centers';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from './helpers';

interface IGetReconciliationCenterByBusinessIdParams {
  businessId: TID,
}

interface IGetReconciliationCenterByBusinessIdResponse {
  reconciliationCenter: IReconciliationCenter,
}

interface IGetReconciliationCenterExceptionMessagesResponse {
  id: TID,
  businessName: string,
  createdAt: string,
  message: string,
  notificationType: TID,
}

const getReconciliationCenterByBusinessId = (
  params: IGetReconciliationCenterByBusinessIdParams,
): Promise<IGetReconciliationCenterByBusinessIdResponse> => {
  return apiGet(
    '/api/v1/reconciliation_centers/by_business_id',
    underscoreKeys(params),
  ).then((data) => {
    return camelizeKeys(data) as IGetReconciliationCenterByBusinessIdResponse;
  });
};

const getReconciliationCenterExceptionMessages = (): Promise<IGetReconciliationCenterExceptionMessagesResponse[]> => {
  return apiGet(
    '/api/web/v1/advisor/notifications?page=1',
  ).then((data) => {
    return camelizeKeys(data.notifications) as IGetReconciliationCenterExceptionMessagesResponse[];
  });
};

export {
  IGetReconciliationCenterByBusinessIdParams,
  IGetReconciliationCenterByBusinessIdResponse,
  IGetReconciliationCenterExceptionMessagesResponse,
  getReconciliationCenterByBusinessId,
  getReconciliationCenterExceptionMessages,
};
