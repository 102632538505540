import React from 'react';

import pluralize from 'pluralize';

import { IVendorPayment } from '@src/types/vendor_payments';

import Tooltip from '@src/components/ui_v2/tooltip';

import SummaryDetail from './summary_detail';

import styles from '@src/components/vendors/my_vendors/details/payments/styles.module.scss';

interface IAccrualDateLabelProps {
  vendorPayment: IVendorPayment;
  onMouseEnter: () => void;
  showTooltip: boolean | undefined;
}

const AccrualDateLabel = ({
  vendorPayment,
  onMouseEnter,
  showTooltip,
}: IAccrualDateLabelProps): JSX.Element | null => {
  const accrualDates = (vendorPayment.accrualDate || []).filter((date) => date !== null);

  if (accrualDates.length === 0) {
    return <span>NA</span>;
  }

  if (accrualDates.length === 1) {
    return <span>{accrualDates[0]}</span>;
  }

  return (
    <Tooltip.Hover
      isControl
      content={ <SummaryDetail vendorPayment={ vendorPayment } onMouseEnter={ onMouseEnter } /> }
      isVisibleArrow={ false }
      showTooltip={ showTooltip }
      tooltipClassName={ styles['coa-summary-tooltip'] }
      variant="light"
    >
      <span className={ styles['multiple-payment-items'] } onMouseEnter={ onMouseEnter }>
        {`${accrualDates.length} ${pluralize('accrual period', accrualDates.length)}`}
      </span>
    </Tooltip.Hover>
  );
};

export default React.memo(AccrualDateLabel);
