import React from 'react';

interface IEmployeeCardMappingTableProps {
  noBorder?: boolean;
  children: React.ReactNode;
}

const EmployeeCardMappingTable: React.FC<IEmployeeCardMappingTableProps> = ({
  noBorder,
  children,
}) => {
  return (
    <div className="mapping-table">
      <div className={ `mapping-header ${noBorder ? 'no-border' : ''}` }>
        <div className="header-cell">Account Owner</div>
        <div className="header-cell">Business</div>
        <div className="header-cell">Employee Card</div>
      </div>
      <div className="mapping-body">
        {children}
      </div>
    </div>
  );
};

export default EmployeeCardMappingTable;
