import { TID } from "@src/types/common";
import {
  ICreateUpdatePortfolioParams,
  ICreateUpdatePortfolioResponse,
  IGetBusinessUsersForPortfolioParams,
  IGetPortfolioDashboardParams,
  IGetPortfolioDashboardResponse,
  IGetPortfolioDashboardsParams,
  IGetPortfolioDashboardsResponse,
  IPortfolioBusinessUsersResponse,
  IPortfolioData
} from "@src/types/portfolio";
import { camelizeKeys, underscoreKeys } from "@src/utils/transform_keys";

import { apiDelete, apiGet, apiPost, apiPut } from "../helpers";

const createPortfolio = (params: ICreateUpdatePortfolioParams):
  Promise<ICreateUpdatePortfolioResponse> => {
  return apiPost(
    '/api/v1/portfolios',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data) as ICreateUpdatePortfolioResponse);
};

const updatePortfolio = (params: ICreateUpdatePortfolioParams):
  Promise<ICreateUpdatePortfolioResponse> => {
  return apiPut(
    `/api/v1/portfolios/${params.id}`,
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data) as ICreateUpdatePortfolioResponse);
};

const getPortfolioList = (): Promise<IPortfolioData> => {
  return apiGet('/api/v1/portfolios')
    .then((data) => camelizeKeys(data) as IPortfolioData);
};

const getPortfolioDashboards = (
  params: IGetPortfolioDashboardsParams,
): Promise<IGetPortfolioDashboardsResponse> => {
  return apiGet(
    `/dashboards/api/v1/portfolio_dashboards`,
    underscoreKeys({
      ...params,
    }),
  ).then((data) => camelizeKeys(data) as IGetPortfolioDashboardsResponse);
};

const getPortfolioDashboardById = (
  params: IGetPortfolioDashboardParams,
): Promise<IGetPortfolioDashboardResponse> => {
  return apiGet(
    `/dashboards/api/v1/portfolio_dashboards/${params.id}`,
  ).then((data) => camelizeKeys(data) as IGetPortfolioDashboardResponse);
};

const deletePortfolioById = (
  id: TID,
): Promise<void> => {
  return apiDelete(
    `/api/v1/portfolios/${id}`,
  );
};

const getBusinessUsersForPortfolio = (
  params: IGetBusinessUsersForPortfolioParams,
): Promise<IPortfolioBusinessUsersResponse> => {
  return apiGet(
    '/api/v1/business_users/search',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data) as IPortfolioBusinessUsersResponse);
};


export {
  createPortfolio,
  updatePortfolio,
  getPortfolioList,
  getPortfolioDashboardById,
  getPortfolioDashboards,
  deletePortfolioById,
  getBusinessUsersForPortfolio,
}
