import React, { FC } from 'react';

import { useFormContext } from 'react-hook-form';

import { FormValues } from '@src/types/adjustment_entries';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import { TrashcanIcon } from '@src/components/utils/icomoon';

interface TableRowDeleteProps {
  index: number
  remove: (index: number) => void
}

const isRowEmpty = (row: Record<any, any>) => {
  return Object.keys(row).every((key) => {
    const value = row[key];
    return value === undefined
      || value === null
      || (typeof value === 'string' && !value.trim());
  });
};

const TableRowDelete: FC<TableRowDeleteProps> = ({ index, remove }) => {
  const confirm = useConfirmDeleteModal({ onDone: () => remove(index) });
  const { getValues } = useFormContext<FormValues>();
  const click = () => {
    const row = getValues(`rows.${index}`);
    if (isRowEmpty(row)) {
      remove(index);
    } else {
      confirm.open();
    }
  };

  return (
    <>
      <confirm.Component
        confirmTitle="Delete"
        text="Are you sure you want to delete this Chart of Account row?"
        title="Delete Journal Entry Line"
        { ...confirm.props }
      />
      <TrashcanIcon pointer onClick={ click } />
    </>
  );
};

export default TableRowDelete;
