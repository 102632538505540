import * as yup from 'yup';
import { object, string, array } from 'yup';

import { IBusinessVendor } from '@src/types/business_vendors';
import { TID } from '@src/types/common';

const businessAchAccountValidation = object({
  businessIds:          array().of(yup.number()).min(1, 'Business(es) is required.').required('Business(es) is required.'),
  receiverType:         string().oneOf(['individual', 'business'], 'Receiver Type is required.'),
  businessName:         string().trim().required('Receiver Business Name is required.'),
  name:                 string().trim().required('Business Name is required.'),
  email:                string().email('Enter a valid email address').required('Email Address is required.'),
  accountNumber:        string().trim().required('Account Number is required.').matches(/^[0-9]+$/, 'Enter a valid bank account number'),
  accountConfirmNumber: string()
    .oneOf([yup.ref('accountNumber'), null], 'Bank account number does not match')
    .required('Confirm Account Number is required.'),
  routingNumber: string().trim().required('Routing Number is required.').matches(/^[0-9]+$/, 'Enter a valid routing number'),
});

const businessAddressBookValidation = object({
  address: object().shape({
    name:           string().trim().required('Name is required.').max(40, "The 'Check Payable to' field must not exceed 40 characters."),
    addressLine1:   string().trim().required('Address is required.').max(50, "The 'Address' field must not exceed 50 characters."),
    department:     string().trim().nullable().max(40, "The 'Department' field must not exceed 40 characters."),
    company:        string().trim().max(40, "The 'Company' field must not exceed 40 characters."),
    addressCity:    string().trim().required('City is required.'),
    addressState:   string().trim().required('State is required.'),
    addressZip:     string().trim().required('Zip is required.').matches(/^\d{5}(-\d{4})?$/, 'ZIP Code must be in a valid ZIP or ZIP+4'),
    addressCountry: string().trim().required('Country is required.'),
  }),
});

interface IBusinessAchAccountInput extends IBusinessVendor {
  businessIds: TID[],
  businessName: string,
  receiverType: string,
  name: string,
  email: string,
  accountNumber: string,
  accountConfirmNumber: string,
  routingNumber: string,
  agreement: boolean,
  firstName: string,
  lastName: string,
}

interface IAddress {
  addressCity: string;
  addressCountry: string;
  addressLine1: string;
  addressLine2: string;
  addressState: string;
  addressZip: string;
  department: string;
  businessId: number;
  name: string;
  company: string;
  isAuthorized?:boolean;
  vendorId: number;
}

interface IUpdateAddress extends IAddress {
  addressableId: number;
  addressableType: string;
  createdAt: string;
  id: number;
  isGlobal: boolean;
  isLobVerified: boolean;
  updatedAt: string;
  isVerified: boolean;
  createdBy: string;
  department: string;
  addedOn: string;
  addedBy: string;
}

interface IBusinessAddressBookInput {
  address: IAddress;
}

interface IUpdateAddressBookInput {
  address: IUpdateAddress;
}

export {
  IBusinessAchAccountInput,
  IBusinessAddressBookInput,
  IUpdateAddressBookInput,
  businessAchAccountValidation,
  businessAddressBookValidation,
};
