/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useEffect, useMemo } from 'react';

import { IReportData } from '@src/types/report_service/report_data';

import Text from '@src/components/ui/text';

interface IReportUpdateBannerProps {
  reportDatas: IReportData[],
  update: boolean,
  setUpdate: React.Dispatch<React.SetStateAction<boolean>>
}

const ReportUpdateBanner = ({
  reportDatas,
  update,
  setUpdate
}: IReportUpdateBannerProps): JSX.Element => {
  useEffect(() => {
    const updatingDatas = reportDatas.filter((reportData) => (
      reportData.updateState
      === window.Docyt.Common.Constants.CUSTOM_REPORT_STATE.UPDATE_STATE_QUEUED
      || reportData.updateState
      === window.Docyt.Common.Constants.CUSTOM_REPORT_STATE.UPDATE_STATE_STARTED
    ));
    setUpdate(updatingDatas.length !== 0);
  }, [reportDatas, setUpdate]);

  const { isFailed, errMsg, isValidationFailed, validationError } = useMemo(() => {
    const failedData = reportDatas.filter((reportData) => (
      reportData.updateState
      === window.Docyt.Common.Constants.CUSTOM_REPORT_STATE.UPDATE_STATE_FAILED
      || (reportData.errorMsg && reportData.errorMsg !== '')
    ));
    const failed = failedData.length !== 0;

    const validationFailedDatas = reportDatas.filter((reportData) => (
      reportData.validationResult && reportData.validationResult !== '' && reportData.validationResult !== 'OK'
    ));
    return {
      isFailed: failed,
      errMsg: failedData[0]?.errorMsg || '',
      isValidationFailed: validationFailedDatas.length > 0,
      validationError: validationFailedDatas[0]?.validationResult || ''
    };
  }, [reportDatas]);

  if (update) {
    return (
      <div className="report-status-panel m-t-20">
        <Text as="label" className="m-l-10 m-b-0" fontSize={ 14 }>
          { window.Docyt.Common.Constants.Messages.CUSTOM_REPORT_UPDATING_STATE }
        </Text>
      </div>
    );
  }

  if (isFailed) {
    // TODO: We should manage it with error status. Need backend efforts for this.
    if (errMsg === window.Docyt.Common.Constants.Messages.REPORT_CHANGES_DETECTED) {
      return (
        <div className="report-status-panel m-t-20">
          <Text as="label" className="m-l-10 m-b-0">{errMsg}</Text>
        </div>
      );
    }
    return (
      <div className="report-status-panel warning m-t-20">
        <Text as="label" className="m-l-10 m-b-0 font-light in-black-600">
          {/* eslint-disable-next-line react/no-danger */}
          <Text as="label" className="m-b-0" dangerouslySetInnerHTML={ { __html: errMsg } } />
        </Text>
      </div>
    );
  }

  if (isValidationFailed) {
    return (
      <div className="report-status-panel warning m-t-20">
        <Text as="label" className="m-l-10 m-b-0 font-light in-black-600">
          {/* eslint-disable-next-line react/no-danger */}
          <Text as="label" className="m-b-0" dangerouslySetInnerHTML={ { __html: validationError } } />
        </Text>
      </div>
    );
  }

  return (
    <div className="report-status-panel m-t-20">
      <Text as="label" className="m-l-10 m-b-0">This report was generated</Text>
    </div>
  );
};

export default ReportUpdateBanner;
