import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';

import { GroupChannel, Member, MemberListQuery, MutedMemberFilter } from '@sendbird/chat/groupChannel';
import { sendbirdSelectors, useSendbirdStateContext } from '@sendbird/uikit-react';

import toastr from '@lib/toastr';
import { useRegionScrollSpy } from '@src/hooks/scroll';

import userDefault from '@assets/images/user_default.png';

import { NumberOfInitialMemberList } from '../../constant';

import styles from '../../styles.module.scss';

interface IMemberListProps {
  channelUrl: string;
}

const MemberList = ({
  channelUrl,
}: IMemberListProps) => {
  const [members, setMembers] = useState<Member[]>([]);
  const [isStopLoading, setIsStopLoading] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [channel, setChannel] = useState<GroupChannel | null>(null);
  const [memberListQuery, setMemberListQuery] = useState<MemberListQuery| null>(null);
  const context = useSendbirdStateContext();
  const sdk = sendbirdSelectors.getSdk(context);
  const ref = useRef<HTMLDivElement>(null);

  const fetchMembers = useCallback(async () => {
    if ((isStopLoading && channelUrl === channel?.url) || isFetching) return;
    if (sdk && sdk.groupChannel) {
      try {
        setIsFetching(true);
        let membersofChannel: Member[] = [];
        if (!memberListQuery || channelUrl !== channel?.url) {
          const newChannel = await sdk.groupChannel.getChannel(channelUrl);
          setChannel(newChannel);
          const newMemberListQuery = await newChannel.createMemberListQuery(
            { limit: NumberOfInitialMemberList, mutedMemberFilter: MutedMemberFilter.UNMUTED },
          );
          setMemberListQuery(newMemberListQuery);
          membersofChannel = await newMemberListQuery?.next();
        } else {
          membersofChannel = await memberListQuery?.next();
        }
        if (membersofChannel?.length === 0) {
          setIsStopLoading(true);
        } else {
          setIsStopLoading(false);
        }
        if (channelUrl !== channel?.url) {
          setMembers([...membersofChannel]);
        } else if (membersofChannel) {
          setMembers([...members, ...membersofChannel]);
        }
      } catch (error) {
        toastr.error('Error fetching members:', 'Error');
      } finally {
        setIsFetching(false);
      }
    }
  }, [sdk, channelUrl, channel, isFetching, members, isStopLoading, memberListQuery]);

  useLayoutEffect(() => {
    fetchMembers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelUrl]);

  useRegionScrollSpy({
    elementRef:      ref,
    onBottomReached: fetchMembers,
  });

  return (
    <div ref={ ref } className={ styles['member-list-container'] }>
      {members?.map((item) => (
        <div key={ item.userId } className={ styles['member-list'] }>
          <div className={ styles['member-avatar'] }>
            <img
              alt={ item.nickname }
              src={ item.profileUrl }
              onError={ ({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = userDefault;
              } }
            />
          </div>
          <div className={ styles['member-title'] }>{item.nickname}</div>
        </div>
      ))}
    </div>
  );
};

export default MemberList;
