import React from 'react';

import Root from '@src/components/root';

import ModulePortfolioHeaderActions from './module_portfolio_header_actions';

const ModulePortfolioHeaderPage = () => {
  return (
    <Root>
      <ModulePortfolioHeaderActions />
    </Root>
  );
};

export default ModulePortfolioHeaderPage;
