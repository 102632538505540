import React from 'react';

import moment from 'moment';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useInboxServiceContext } from '@src/hooks/contexts/inbox_service_context';
import { useGetTransactionMessages } from '@src/hooks/queries/bank_account_reconciliations';
import { useGetDocument } from '@src/hooks/queries/documents';
import { getInboxServiceDocuments } from '@src/requests/inbox_service_documents';

import { useUploadDocumentModal } from '@src/components/common/upload_document';

import styles from '../styles.module.scss';

interface IChatAddDocumentProps {
  documentId: number;
}

const ChatAddDocumentAction: React.FC<IChatAddDocumentProps> = ({
  documentId,
}) => {
  const business = useBusinessContext();
  const upload = useUploadDocumentModal();
  const messagesQuery = useGetTransactionMessages(documentId);
  const { data: document } = useGetDocument(documentId);
  const { chatId } = document || {};
  const service = useInboxServiceContext();

  const handleUploadDone = async () => {
    try {
      if (upload.props.onCancel) {
        upload.props.onCancel();
      }

      const documents = await getInboxServiceDocuments({
        inboxServiceId: service.id,
      });

      const latestDocument = documents?.collection[0];

      if (latestDocument?.id) {
        // use faye to send the message to the chat
        // @ts-ignore
        window.Docyt.fayeClient.publish(`/chats/${chatId}`, {
          document_id:      latestDocument?.id,
          auth_token:       localStorage.getItem('oauth_access_token'),
          client_timestamp: moment().unix(),
          type:             'web',
        });
        await messagesQuery.refetch();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching latest message:', error);
    }
  };

  return (
    <>
      <upload.Component
        { ...upload.props }
        business={ business }
        onDone={ handleUploadDone }
      />
      <div
        aria-label="Add Document"
        className={ styles['menu-button'] }
        role="button"
        tabIndex={ 0 }
        onClick={ upload.open }
      >
        <i className="icon icon-plus" />
      </div>
    </>
  );
};

export default ChatAddDocumentAction;
