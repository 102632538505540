import React, { useCallback } from 'react';

import toastr from '@lib/toastr';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useUploadAttachment } from '@src/hooks/queries/joural_entries';
import { useGetAdjustmentEntryByParams } from '@src/requests/adjustment_entries';
import { IAdjustmentEntry } from '@src/types/adjustment_entries';
import { IBusiness } from '@src/types/businesses';

import { CancelModalButton } from '@src/components/ui/buttons';
import Modal from '@src/components/ui/modal';
import { NewDocumentIcon } from '@src/components/utils/img_icons';
import MutationStatus from '@src/components/utils/mutation_status';

import Dropzone from './upload_journal_entry_dropzone';
import Input from './upload_journal_entry_input';

interface IUploadJournalEntryProps extends IUseModalProps {
  business: IBusiness,
  attachments: { filename: string }[];
}

const getQueryParams = () => {
  const params = new URLSearchParams(window.location.search);
  const qboId = params.get('qbo_id') || undefined;
  const docytId = params.get('docyt_id') || undefined;
  return { qboId, docytId };
};

const UploadJournalEntry = ({
  business,
  isOpen,
  onCancel,
  onDone,
  attachments,
}: IUploadJournalEntryProps): JSX.Element => {
  const businessId = business.id;
  const params = getQueryParams();
  const { data } = useGetAdjustmentEntryByParams(params, businessId);
  const adjustmentEntry = data?.adjustmentEntry as IAdjustmentEntry;
  const upload = useUploadAttachment();
  const { mutate } = upload;
  const handleFilesSelected = useCallback((files: File[]) => {
    const hasDuplicateFiles =
      files.some((file) => attachments.some((attachment) => attachment.filename === file.name));

    if (hasDuplicateFiles) {
      return toastr.error('Same attachment has already been added.', 'Error');
    }
    return files.forEach((file) => {
      mutate({
        attachmentParams: {
          record_type: 'AdjustmentEntry',
          record_id:   adjustmentEntry.id,
          name:        file.name,
          business_id: businessId,
        },
        file,
        documentId: adjustmentEntry.documentId,
      }, {
        onSuccess: onDone,
      });
    });
  }, [mutate, onDone, businessId, adjustmentEntry, attachments]);

  return (
    <>
      <MutationStatus mutation={ upload } />
      <Modal dialogClassName="upload-file-modal" show={ isOpen } title="Upload File">
        <Modal.Body>
          <Dropzone isUploading={ upload.isLoading } onFilesSelected={ handleFilesSelected }>
            {
              !upload.isLoading
                ? (
                  <div className="text-center">
                    <NewDocumentIcon />
                    <p className="m-t-20 font-16">Drag and drop file here.</p>
                    <p className="m-t-10 m-b-10 font-16">OR</p>
                    <Input onFilesSelected={ handleFilesSelected } />
                  </div>
                ) : (
                  <div className="text-center p-t-70">
                    <p className="file-upload-status-text font-18">Uploading...</p>
                  </div>
                )
            }
          </Dropzone>
        </Modal.Body>
        <Modal.Footer>
          <div style={ { display: 'flex', justifyContent: 'end' } }>
            <CancelModalButton onClick={ onCancel } />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const useUploadJournalEntryModal = makeUseModal(UploadJournalEntry);

export {
  IUploadJournalEntryProps,
  useUploadJournalEntryModal,
  UploadJournalEntry as default,
};
