import React, { ReactNode } from 'react';

import styles from './styles.module.scss';

interface IHeaderRegionActionsAreaProps {
  children: ReactNode;
}

const HeaderRegionActionsArea = ({ children }: IHeaderRegionActionsAreaProps): JSX.Element => {
  return (
    <div className={ styles.area }>
      {children}
    </div>
  );
};

export default React.memo(HeaderRegionActionsArea);
