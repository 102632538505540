import { object, string } from 'yup';

interface IDataCategory {
  id: number;
  isDebit: boolean;
}
interface IAddReportTypeValues {
  name: string
  frequency: string
  split_revenue_receivables: boolean
  category_ids: IDataCategory[];
  tax_ids: IDataCategory[];
  ledger_ids: IDataCategory[];
}

const addReportTypeValidation = object({
  name: string().max(55).trim().label('Revenue Mapping Name')
    .required(),
  frequency:                 string(),
  split_revenue_receivables: string(),
});

export {
  addReportTypeValidation,
  IAddReportTypeValues,
};
