import React from 'react';

import { IVendorPayment, TVendorPaymentStep } from '@src/types/vendor_payments';

import VendorStatusStep from './payment_steps';

import styles from './styles.module.scss';

type TVendorStatusProps = {
  vendorPayment: IVendorPayment,
  showTitle?: boolean,
};

const statusOrder: TVendorPaymentStep[] = [
  'Created',
  'Mailed',
  'In transit',
  'In local area',
  'Processed for delivery',
  'Cleared',
];

const statusOrderAch: TVendorPaymentStep[] = [
  'Created',
  'Processing',
  'Completed',
];

const VendorStatus = ({
  vendorPayment,
  showTitle = true,
}: TVendorStatusProps) => {
  const isOrderAch = vendorPayment.paymentMethod === 'docyt_ach';
  const activeStatusOrder = isOrderAch ? statusOrderAch : statusOrder;

  const currentStatusIndex = activeStatusOrder.indexOf(
    vendorPayment.paymentStatus as TVendorPaymentStep,
  );

  return (
    <div>
      {showTitle && (
        <div className={ styles['status-title'] }>Payment Status</div>
      )}

      {activeStatusOrder.map((status, index) => {
        const isCurrentStep = index === currentStatusIndex;

        const isFinished =
        vendorPayment.paymentStatus === 'Cleared'
        || vendorPayment.paymentStatus === 'Completed';

        const isProcessActivated = isCurrentStep && !isFinished;
        const isProcessingStep = isCurrentStep && !isFinished;

        const isActivated = index < currentStatusIndex;
        const isChecked = index < currentStatusIndex;

        return (
          <VendorStatusStep
            key={ status }
            isActivated={ isFinished || isActivated }
            isChecked={ isFinished || isChecked }
            isProcess={ isProcessingStep }
            isProcessActivated={ isProcessActivated }
            step={ status as TVendorPaymentStep }
          />
        );
      })}
    </div>
  );
};

export default VendorStatus;
