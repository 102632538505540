import React from 'react';

import { TUUID } from '@src/types/common';

import DashboardUnavailable from '@src/components/portfolio_dashboard/dashboard/dashboard_unavailable';
import Root from '@src/components/root';

import DashboardMainPage from './dashboard_page';

interface IPortfolioDashboardProps {
  dashboardId?: TUUID,
  widgetId?: string,
}

const PortfolioDashboardPage = ({
  dashboardId,
  widgetId,
}: IPortfolioDashboardProps): JSX.Element => {
  return (
    <Root>
      {
        dashboardId
          ? (
            <DashboardMainPage
              dashboardId={ dashboardId }
              widgetId={ widgetId }
            />
          ) : (
            <DashboardUnavailable />
          )
      }
    </Root>
  );
};

export default PortfolioDashboardPage;
