import React from 'react';

import pluralize from 'pluralize';

import { IVendorPayment } from '@src/types/vendor_payments';

import { AppLink } from '@src/components/ui_v2/buttons';
import Tooltip from '@src/components/ui_v2/tooltip';

import SummaryDetail from './summary_detail';

import styles from '@src/components/vendors/my_vendors/details/payments/styles.module.scss';

interface IInvoiceNoLabelProps {
  vendorPayment: IVendorPayment;
  onMouseEnter: () => void;
  showTooltip: boolean | undefined;
}

const InvoiceNoLabel = ({
  vendorPayment,
  onMouseEnter,
  showTooltip,
}: IInvoiceNoLabelProps): JSX.Element | null => {
  if (vendorPayment.documentId.length === 1) {
    return (
      <AppLink newWindow className={ styles['invoice-no'] } href={ `/document/${vendorPayment.documentId[0]}` }>
        <span className={ styles.invoice_no }>{vendorPayment.documentId[0]}</span>
      </AppLink>
    );
  }

  return (
    <Tooltip.Hover
      isControl
      content={ <SummaryDetail vendorPayment={ vendorPayment } onMouseEnter={ onMouseEnter } /> }
      isVisibleArrow={ false }
      showTooltip={ showTooltip }
      tooltipClassName={ styles['document-summary-tooltip'] }
      variant="light"
    >
      <span className={ styles['multiple-payment-items'] } onMouseEnter={ onMouseEnter }>
        {`${vendorPayment.documentId.length} ${pluralize('invoice', vendorPayment.documentId.length)}`}
      </span>
    </Tooltip.Hover>
  );
};

export default React.memo(InvoiceNoLabel);
