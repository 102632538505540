import React, { useCallback } from 'react';

import { TDate, TUUID } from '@src/types/common';

import { Button } from '@src/components/ui_v2/buttons';

import { useAssignDateModal } from '../modal/assign_date';

interface AssignDateActionProps {
  metricsReportId: TUUID
  preSelectedDate?: TDate
  metricReportStatus: string
}

const AssignDateAction = ({
  metricsReportId,
  preSelectedDate,
  metricReportStatus,
}: AssignDateActionProps) => {
  const add = useAssignDateModal();

  const isDisabled = [
    'success',
    'date_assigned',
    'processing',
    'queued',
    'pending',
  ].includes(metricReportStatus);

  const handleOpenModal = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      add.open();
    },
    [add],
  );

  return (
    <>
      <add.Component
        { ...add.props }
        metricsReportId={ metricsReportId }
        preSelectedDate={ preSelectedDate }
      />
      <Button disabled={ isDisabled } variant="ghost" onClick={ handleOpenModal }>
        Assign Date
      </Button>
    </>
  );
};

export default AssignDateAction;
