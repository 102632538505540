import React, { useCallback } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useExportReportData } from '@src/hooks/queries/report_service/custom_reports';
import { IAdvancedReportsFileParams } from '@src/requests/report_service/custom_reports';
import { businessReportExportPath } from '@src/routes';
import { TID } from '@src/types/common';
import {  IReport, TReportCategory } from '@src/types/report_service/report';
import { endOfMonthApiDate, formatApiDate, formatDate, parseApiDate, startOfMonthApiDate } from '@src/utils/date_helpers';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { budgetsSelectAddValueType, forecastSelectAddValueType, hideZeroRowsType, multiSelectAddValueType } from '@src/components/ui_v2/filter/atoms';
import { ExportAsExcelIcon } from '@src/components/utils/icomoon';

import { reportDataPeriodType, reportFilePreparing, reportFilterDateRange } from '../../atoms';

import styles from '../styles.module.scss';


interface IExportReportAsExcelActionProps {
  businessId: TID,
  category?: TReportCategory,
  report: IReport
}

const ExportReportAsExcelAction = ({
  businessId,
  category,
  report
}: IExportReportAsExcelActionProps): JSX.Element => {
  const isReportsExportEnabled = window.configData.launch_darkly_enabled_global_features
    .includes(window.Docyt.Common.Constants.BUSINESS_REPORTS_DOWNLOAD_ENABLED);
  const periodStatus = useRecoilValue(reportDataPeriodType);
  const setExcelFilePreparing = useSetRecoilState(reportFilePreparing);
  const hideZeroRows = useRecoilValue(hideZeroRowsType);
  const reportDateRange = useRecoilValue(reportFilterDateRange);
  const multiValue = useRecoilValue(multiSelectAddValueType);
  const budgetsSelectValue = useRecoilValue(budgetsSelectAddValueType);
  const forecastSelectValue = useRecoilValue(forecastSelectAddValueType);
  const downloadReports = useExportReportData();
  const { mutate } = downloadReports;

  const handleOldExportClick = useCallback((drillDown: string) => {
    let params: IAdvancedReportsFileParams;

    if (report.templateId === window.Docyt.Common.Constants.CUSTOM_REPORT_TYPE.DEPARTMENTAL_REPORT) {
      params = {
        export_type:        'report',
        report_id:          report.id,
        start_date:         startOfMonthApiDate(parseApiDate(reportDateRange.gte)),
        end_date:           endOfMonthApiDate(parseApiDate(reportDateRange.lte)),
        include_drill_down: drillDown !== '',
        file_type:          'xls',
        filter: {
          is_zero_rows_hidden: hideZeroRows,
          accounting_class_id: '',
          budget:               budgetsSelectValue.length !== 0 ? budgetsSelectValue[0] : null,
          forecast:             forecastSelectValue.length !== 0 ? forecastSelectValue[0] : null,
          ...(budgetsSelectValue.length !== 0 ? {[`checkbox-budget-${budgetsSelectValue[0]}`]: true} : {}),
          ...(forecastSelectValue.length !== 0 ? {[`checkbox-forecast-${forecastSelectValue[0]}`]: true} : {})
        }
      };
    } else {
      params = {
        export_type:          'report',
        report_id:            report.id,
        start_date:           startOfMonthApiDate(parseApiDate(reportDateRange.gte)),
        end_date:             endOfMonthApiDate(parseApiDate(reportDateRange.lte)),
        include_drill_down:   drillDown !== '',
        is_daily:             false,
        file_type:            'xls',
        filter:               {}
      };
  
      if (periodStatus === window.Docyt.Common.Constants.CUSTOM_REPORT_PERIOD.DAILY) {
        params.is_daily = true
        params.start_date = formatApiDate(formatDate(reportDateRange.gte));
        params.end_date = formatApiDate(formatDate(reportDateRange.lte));
        params.filter = {
          is_zero_rows_hidden: hideZeroRows
        }
      } else {
        params.filter = {
          is_zero_rows_hidden:    hideZeroRows,
          budgets_comparison:     false,
          forecast_comparison:    false,
          last_year:              multiValue.includes('last_year'),
          per_available_room:     multiValue.includes('per_available_room'),
          per_occupied_room:      multiValue.includes('per_occupied_room'),
          percentage_column:      multiValue.includes('percentage_column'),
          previous_period_column: multiValue.includes('previous_period_column'),
          budget:                 budgetsSelectValue.length !== 0 ? budgetsSelectValue[0] : null,
          forecast:               forecastSelectValue.length !== 0 ? forecastSelectValue[0] : null,
          ...(budgetsSelectValue.length !== 0 ? {[`checkbox-budget-${budgetsSelectValue[0]}`]: true} : {}),
          ...(forecastSelectValue.length !== 0 ? {[`checkbox-forecast-${forecastSelectValue[0]}`]: true} : {})
        }
      };
    }

    mutate(params, { onSuccess: () => setExcelFilePreparing(true) });
  }, [
    setExcelFilePreparing,
    mutate,
    report.templateId,
    periodStatus,
    budgetsSelectValue,
    forecastSelectValue,
    hideZeroRows,
    multiValue,
    report.id,
    reportDateRange?.gte,
    reportDateRange?.lte,
  ]);

  const handleNewExportClick = useCallback(() => {
    Backbone.history.navigate(
      businessReportExportPath(businessId, category as TReportCategory, 'report', report.slug),
      { trigger: true },
    );
  }, [businessId, category, report.slug]);

  if (isReportsExportEnabled) {
    return (
      <ActionsDropdown.Action
        className={ styles['dropdown-item'] }
        icon={ <ExportAsExcelIcon fontSize={ 18 } /> }
        title="Export as Excel"
        onSelect={ handleNewExportClick }
      />
    )
  }

  if (report.editMappingDisabled) {
    return (
      <ActionsDropdown.Action
        className={ styles['dropdown-item'] }
        icon={ <ExportAsExcelIcon fontSize={ 18 } /> }
        title="Export as Excel"
        onSelect={ () => handleOldExportClick('') }
      />
    );
  }

  return (
    <ActionsDropdown.Submenu
      className={ styles['dropdown-item'] }
      icon={ <ExportAsExcelIcon fontSize={ 18 } /> }
      title="Export as Excel"
    >
      <ActionsDropdown.Action
        className={ styles['dropdown-item'] }
        title="Include drill down"
        onSelect={ () => handleOldExportClick('include') }
      />
      <ActionsDropdown.Action
        className={ styles['dropdown-item'] }
        title="Exclude drill down"
        onSelect={ () => handleOldExportClick('') }
      />
    </ActionsDropdown.Submenu>
  );
};

export default ExportReportAsExcelAction;
