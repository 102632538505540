import React from 'react';

import { IPaymentAccount } from '@src/types/payment_accounts';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { BillIcon } from '@src/components/utils/icomoon';

interface IEmployeeCardUnarchiveActionProps {
	employeeCardItem: IPaymentAccount,
}

const EmployeeCardUnarchiveAction = ({
  employeeCardItem,
}: IEmployeeCardUnarchiveActionProps): JSX.Element | null => {
  return (
    <ActionsDropdown.Action
      className="employee-card-action-item"
      icon={ <BillIcon /> }
      name="Unarchive Card"
	  // TODO: This will be dynamic. Rewrite the unarchive page in React.
      onClick={ () => employeeCardItem.id }
    />
  );
};

export default EmployeeCardUnarchiveAction;
