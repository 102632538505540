import React, { useEffect } from 'react';

import ChannelList from '@sendbird/uikit-react/ChannelList';

import { businessChatsPath } from '@src/routes';
import { backboneNavigateToUrl } from '@src/utils/navigate';

import ChannelListHeader from '@src/components/chat_central/components/channel_list/header';
import ChannelListItem from '@src/components/chat_central/components/channel_list/item';
import Loader from '@src/components/chat_central/components/channel_list/loader';
import { IChannelListProps, IRenderChannelPreviewProps } from '@src/components/chat_central/types';

import styles from '@src/components/chat_central/styles.module.scss';

interface IChannelListViewProps extends IChannelListProps {
  setChannelUrl: (channelUrl: string) => void;
  currentChannelUrl?: string;
  disableAutoSelect?: boolean;
}

const ChannelListView = (
  {
    currentChannelUrl,
    disableAutoSelect = false,
    setChannelUrl,
  }
  :IChannelListViewProps,
) => {
  const [gotChannelFromUrl, setGotChannelFromUrl] = React.useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const channelUrlFromParams = urlParams.get('channelUrl');
    if (channelUrlFromParams) {
      setChannelUrl(channelUrlFromParams);
      setGotChannelFromUrl(true);
      backboneNavigateToUrl(businessChatsPath());
    } else if (currentChannelUrl) {
      setChannelUrl(currentChannelUrl);
    }
  }, [currentChannelUrl, setChannelUrl]);

  return (
    <ChannelList
      className={ styles['chat-list-container'] }
      disableAutoSelect={ gotChannelFromUrl || disableAutoSelect }
      queries={ {
        channelListQuery: {
          customTypesFilter: ['business_channel'],
          includeEmpty:      true,
          limit:             30,
          order:             'latest_last_message',
        },
      } }
      renderChannelPreview={ (item: IRenderChannelPreviewProps) => {
        return <ChannelListItem { ...item } currentChannelUrl={ currentChannelUrl } />;
      } }
      renderHeader={ () => <ChannelListHeader /> }
      renderPlaceHolderLoading={ () => <Loader /> }
      onChannelSelect={ (channel) => {
        if (channel?.url) {
          setChannelUrl(channel.url);
        }
      } }
    />
  );
};

export default ChannelListView;
