import React, { ChangeEvent, DragEvent } from 'react';

import toastr from '@lib/toastr';

import { Button } from '@src/components/ui/buttons';
import Modal from '@src/components/ui/modal';
import { NewDocumentIcon } from '@src/components/utils/img_icons';

import styles from './styles.module.scss';

interface IUploadCSVProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setFiles: React.Dispatch<React.SetStateAction<Array<File>>>;
}

const UploadFile: React.FC<IUploadCSVProps> = (props) => {
  const { show, setShow, setFiles } = props;

  const close = () => {
    setShow(false);
  };

  const handleFileAddition = (file: File) => {
    setFiles((prevFiles) => {
      if (prevFiles.some((f) => f.name === file.name)) {
        toastr.error('Same attachment has already been added.', 'Error');
        return prevFiles;
      }
      toastr.success('File saved successfully.', 'Success');
      close();
      return [...prevFiles, file];
    });
  };

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      handleFileAddition(event.target.files[0]);
    }
  };

  const handleFileDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      handleFileAddition(event.dataTransfer.files[0]);
    }
  };

  return (
    <Modal show={ show }>
      <Modal.Body>
        <div className={ styles['upload-modal-title'] }>
          Upload File
        </div>
        <div
          className={ styles['csv-input-panel'] }
          onDragOver={ (e) => e.preventDefault() }
          onDrop={ handleFileDrop }
        >
          <NewDocumentIcon />
          <p>Drag and drop file here.</p>
          <p>OR</p>
          <label className={ styles['button-secondary'] } htmlFor="fileInput">
            <input
              id="fileInput"
              type="file"
              onChange={ handleFileSelect }
            />
            Select from your computer
          </label>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className={ styles['modal-footer'] }>
          <Button className={ styles['button-secondary'] } onClick={ close }>Cancel</Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadFile;
