import React, { FC } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IBankAccountReconciliation } from '@src/types/bank_account_reconciliations';
import { TDateFilter } from '@src/types/filter';

import TableSection from '@src/components/ui_v2/table_section/table_section';
import Section from '@src/components/utils_v2/section';

import { useAdjustmentEntriesCollection } from './hooks';
import Table from './table';

interface Props {
  reconciliation?: IBankAccountReconciliation,
  entryDate?: TDateFilter,
  duplicate?: (id: number) => void,
}

const Component: FC<Props> = ({ reconciliation, entryDate, duplicate }) => {
  const business = useBusinessContext();

  // eslint-disable-next-line max-len
  const collection = useAdjustmentEntriesCollection({ businessId: business.id, reconciliationId: reconciliation?.id, entryDate });

  return (
    <Section.Provider section={ collection.section }>
      <TableSection>
        <Table
          duplicate={ duplicate }
          infiniteQuery={ collection.query }
          reconciliation={ reconciliation }
          records={ collection.records }
          sorting={ collection.sorting }
        />
      </TableSection>
    </Section.Provider>
  );
};

export default Component;
