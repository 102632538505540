import React from 'react';

import { CheckboxInput, SelectInput } from '@src/components/ui_v2/inputs';

import styles from '../styles.module.scss';

interface ICategory {
  id: number;
  label: string;
  disabled?: boolean;
}

interface ISelection {
  id: number;
  isDebit: number;
  isChecked: boolean;
}

interface CategorySelectProps {
  categories: ICategory[];
  formState: ISelection[];
  type: number; // 1 = Revenue, 2 = Tax, 3 = Other Ledger
  handleCheckboxChange: (id: number, type: number) => void;
  handleIsDebitChange: (id: number, value: number, type: number) => void;
}

const options = [
  { value: 'Credit', label: 'Credit' },
  { value: 'Debit', label: 'Debit' },
];

const CategorySelect: React.FC<CategorySelectProps> = ({
  categories,
  formState,
  type,
  handleCheckboxChange,
  handleIsDebitChange,
}) => {
  return (
    <div className={ styles['category-wrapper'] }>
      {categories.map((category) => {
        const selectedCategory = formState.find((item) => item.id === category.id);

        return (
          <div key={ category.id } className={ styles['category-form-wrapper'] }>
            <CheckboxInput
              checked={ !!selectedCategory?.isChecked }
              disabled={ category.disabled }
              title={ category.label }
              onChange={ () => handleCheckboxChange(category.id, type) }
            />
            <SelectInput
              alwaysShowIndicator
              hideClear
              className={ styles['width-select'] }
              isDisabled={ category.disabled }
              isSearchable={ false }
              minWidthOption={ 100 }
              options={ options }
              value={ options.find((op) => op.value === (selectedCategory?.isDebit ? 'Debit' : 'Credit')) }
              onChange={ (e) => handleIsDebitChange(category.id, e?.value === 'Debit' ? 1 : 0, type) }
            />
          </div>
        );
      })}
    </div>
  );
};

export default CategorySelect;
