import { useMutation } from 'react-query';

import {
  postAchBankAccount,
} from '@src/requests/vendor_ach_bank_account';
import { IBusinessVendor } from '@src/types/business_vendors';

const useCreateAchBankAccount = () => {
  return useMutation<void, Error, IBusinessVendor>(
    postAchBankAccount,
  );
};

export {
  useCreateAchBankAccount,
};
