import React, { useCallback } from 'react';

import _ from 'lodash';

import toastr from '@lib/toastr';
import { useArchivePaymentAccount } from '@src/hooks/queries/payment_accounts';
import { IPaymentAccount } from '@src/types/payment_accounts';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { AccountArchiveIcon } from '@src/components/utils/icomoon';

interface IEmployeeCardArchiveActionProps {
  employeeCardItem: IPaymentAccount,
  refetchEmployeeCard(): void
}

const EmployeeCardArchiveAction = ({
  employeeCardItem,
  refetchEmployeeCard,
}: IEmployeeCardArchiveActionProps): JSX.Element | null => {
  const archivePaymentAccoutQuery = useArchivePaymentAccount();
  const { mutate: archivePaymentAccoutMutate } = archivePaymentAccoutQuery;

  const handleOnConfirm = useCallback(() => {
    archivePaymentAccoutMutate({
      id: employeeCardItem.id,
    }, {
      onSuccess: () => {
        refetchEmployeeCard();
        toastr.success(`Account ${employeeCardItem.name} archived successfully. You can unarchive it anytime to continue reimporting the transactions.`, 'Success');
      },
      onError: ({ response }) => {
        const errorMessageArray = response?.data.errors;
        if (_.isArray(errorMessageArray)) {
          toastr.error(errorMessageArray[0], 'Something went wrong');
        }
      },
    });
  }, [archivePaymentAccoutMutate, employeeCardItem.id, employeeCardItem.name, refetchEmployeeCard]);

  const ConfirmModal = useConfirmDeleteModal({
    onDone: handleOnConfirm,
  });

  const handleArchiveClick = () => {
    if (employeeCardItem.isMultiEntity) {
      toastr.error('This card cannot be archived as it is linked to multi-entity mapping. Please delete the mapping before proceeding.', 'Unable to Archive');
    } else {
      ConfirmModal.open();
    }
  };

  return (
    <>
      <ConfirmModal.Component
        { ...ConfirmModal.props }
        confirmStyle="danger"
        confirmTitle="Proceed"
        text={ (
          <>
            Are you sure you want to archive the card
            {' '}
            <strong>{employeeCardItem.name}</strong>
            ?
          </>
        ) }
        title="Archive Card"
      />
      <ActionsDropdown.Action
        className="employee-card-action-item"
        icon={ <AccountArchiveIcon /> }
        name="Archive Card"
        onClick={ () => handleArchiveClick() }
      />
    </>
  );
};

export default EmployeeCardArchiveAction;
