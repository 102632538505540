import { IRevenueServiceDocumentCategoryValue } from 'src/types/revenue_service_document_category_values';

import { TID, TOrderDirection, TUUID } from '@src/types/common';
import {
  IRevenueServiceDocument,
  IRevenueServiceDocumentsQueryFilter,
  TRevenueServiceDocumentsSortColumn,
  IRevenueServiceDocumentData,
} from '@src/types/revenue_service_documents';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet, apiPut, apiPost } from './helpers';

interface IGetRevenueServiceDocumentsParams {
  revenueServiceId: TID,
  revenueSystemId?: TUUID,
  paymentProcessorId?: TID,
  unassigned?: boolean,
  filter?: IRevenueServiceDocumentsQueryFilter,
  state?: string | string[],
  orderColumn?: TRevenueServiceDocumentsSortColumn,
  orderDirection?: TOrderDirection,
  page?: number,
  perPage?: number,
}

interface IGetRevenueServiceDocumentsResponse {
  meta: {
    totalCount: number,
  },
  collection: IRevenueServiceDocument[],
}

interface IPutRevenueServiceDocumentParams {
  id: number,
  data: IRevenueServiceDocumentData
}

interface IVerifyRevenueServiceDocumentParams {
  id: TID;
  data: {
    verifyZeroRevenueReceivables?: boolean;
  };
}

interface IUpsertRevenueServiceDocumentCategoryValuesParams {
  // id: TID,
  revenueServiceDocumentId: TID,
  value: number,
  revenueReportTypeCategoryId: TID,
}

interface IPutRevenueServiceDocumentResponse {
  revenueServiceDocument: IRevenueServiceDocument,
}

const putRevenueServiceDocument = (
  params: IPutRevenueServiceDocumentParams,
): Promise<IPutRevenueServiceDocumentResponse> => {
  return apiPut(
    `/api/v1/revenue_service_documents/${params.id}`,
    underscoreKeys(params.data),
  ).then((data) => {
    return camelizeKeys(data) as IPutRevenueServiceDocumentResponse;
  });
};

const verifyRevenueServiceDocument = (
  params: IVerifyRevenueServiceDocumentParams,
): Promise<IPutRevenueServiceDocumentResponse> => {
  return apiPut(
    `/api/v1/revenue_service_documents/${params.id}/verify`,
    underscoreKeys(params.data),
  ).then((data) => {
    return camelizeKeys(data) as IPutRevenueServiceDocumentResponse;
  });
};

const getRevenueServiceDocuments = (
  params: IGetRevenueServiceDocumentsParams,
): Promise<IGetRevenueServiceDocumentsResponse> => {
  return apiGet(
    '/api/v1/revenue_service_documents',
    underscoreKeys(params),
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);

    return {
      meta:       cdata.meta,
      collection: cdata.revenueServiceDocuments as IRevenueServiceDocument[],
    };
  });
};

const getRevenueServiceDocument = (
  id: TID,
): Promise<IRevenueServiceDocument> => {
  return apiGet(
    `/api/v1/revenue_service_documents/${id}`,
  ).then((data) => camelizeKeys(data.revenue_service_document) as IRevenueServiceDocument);
};

const upsertRevenueServiceDocumentCategoryValues = (
  params: IUpsertRevenueServiceDocumentCategoryValuesParams,
): Promise<IRevenueServiceDocumentCategoryValue> => {
  return apiPost(
    `/api/v1/revenue_service_documents/${params.revenueServiceDocumentId}`
      + '/revenue_service_document_category_values/upsert',
    underscoreKeys(params),
  ).then((data) => camelizeKeys(data) as IRevenueServiceDocumentCategoryValue);
};

export {
  IGetRevenueServiceDocumentsParams,
  IGetRevenueServiceDocumentsResponse,
  IPutRevenueServiceDocumentParams,
  IPutRevenueServiceDocumentResponse,
  IVerifyRevenueServiceDocumentParams,
  IUpsertRevenueServiceDocumentCategoryValuesParams,
  getRevenueServiceDocuments,
  putRevenueServiceDocument,
  verifyRevenueServiceDocument,
  upsertRevenueServiceDocumentCategoryValues,
  getRevenueServiceDocument,
};
