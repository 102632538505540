import forEach from 'lodash/forEach';
import { AtomEffect, atom, atomFamily, selectorFamily } from 'recoil';

import { Family } from '@src/constants/atom_keys';
import { TSection } from '@src/types/common';
import { TAmountFilter, TDateFilter, TFilterData } from '@src/types/filter';
import {
  restoreFilterFromStorage,
  storeFilterToStorage,
  overrideFromURLParams,
  reflectToURLParams,
} from '@src/utils/filter';

const makeSyncFilterWithStorageEffect = (section: TSection): AtomEffect<TFilterData> => {
  return ({ setSelf, trigger, onSet }) => {
    // Initialize atom value to the remote storage state
    if (trigger === 'get') {
      // Avoid expensive initialization
      const filterData = restoreFilterFromStorage(section) as TFilterData;
      const urlParams = overrideFromURLParams(section, filterData);
      setSelf(urlParams);
    }

    onSet((data) => {
      storeFilterToStorage(section, data);
      if (!section.skipURLParams) reflectToURLParams(section, data);
    });
  };
};

const filterData = atomFamily<TFilterData, TSection>({
  key:     Family.Filter,
  default: undefined,
  effects: (section) => [makeSyncFilterWithStorageEffect(section)],
});

const filterCounter = selectorFamily<number, TSection>({
  key: Family.FilterCounter,
  get:
    (section) => ({ get }) => {
      const filter = get(filterData(section));

      if (!filter) return 0;

      let count = 0;
      forEach(filter, (value, key) => {
        if (key === 'transaction_date' || key === 'invoice_date') {
          const filterValue = filter[key] as TDateFilter;
          if (!filterValue) return;

          if (filterValue.gte && filterValue.gte !== '') count += 1;
          if (filterValue.lte && filterValue.lte !== '') count += 1;
        } else if (key === 'amount') {
          const filterValue = filter[key] as TAmountFilter;
          if (!filterValue) return;

          if (
            (filterValue.val && filterValue.val !== '')
            || (filterValue.gte && filterValue?.gte !== '')
            || (filterValue.lte && filterValue?.lte !== '')
          ) {
            count += 1;
          }
        } else if (key === 'excluded') {
          if (filter[key] === 'true') count += 1;
        } else if (filter[key]) count += 1;
      });

      return count;
    },
});

const searchColumnValueType = atom<string>({
  key:     Family.SearchColumnValue,
  default: '',
});

const multiSelectAddValueType = atom<string[]>({
  key:     Family.MultiSelectAddValue,
  default: [],
});

const budgetsSelectAddValueType = atom<string[]>({
  key:     Family.BudgetsSelectAddValue,
  default: [],
});

const forecastSelectAddValueType = atom<string[]>({
  key:     Family.ForecastSelectAddValue,
  default: [],
});

const hideZeroRowsType = atom<boolean>({
  key:     Family.HideZeroRows,
  default: true,
});

export {
  filterData,
  filterCounter,
  searchColumnValueType,
  multiSelectAddValueType,
  hideZeroRowsType,
  budgetsSelectAddValueType,
  forecastSelectAddValueType,
};
