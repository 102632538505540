import React from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';

import { Button } from '@src/components/ui/buttons';
import Modal from '@src/components/ui/modal';

interface IDeleteDocumentModalProps extends IUseModalProps {
  onConfirm: () => void;
}

const DeleteDocumentModal = ({
  isOpen,
  onCancel,
  onConfirm,
}: IDeleteDocumentModalProps): JSX.Element => {
  const handleConfirm = () => {
    onConfirm();
    onCancel?.();
  };

  return (
    <Modal
      show={ isOpen }
      title="Remove document"
    >
      <Modal.Body>
        <div>
          <p>Are you sure you want to proceed and delete this document?</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="cancel-link modal-link-btn"
          fontSize={ 16 }
          title="Cancel"
          type="button"
          onClick={ onCancel }
        />
        <Button
          className="modal-link-btn"
          fontSize={ 16 }
          title="Confirm"
          type="button"
          onClick={ handleConfirm }
        />
      </Modal.Footer>
    </Modal>
  );
};

const useDeleteDocumentModal = makeUseModal(DeleteDocumentModal);
export default useDeleteDocumentModal;
