import React from 'react';

import classNames from 'classnames';

import { useLDBusinessFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { TID } from '@src/types/common';
import { TLaunchDarklyFeature } from '@src/types/launch_darkly';

import styles from '../navigation_styles.module.scss';

interface IServiceMenuItemProps {
  businessId?: TID,
  isSubMenu?: boolean,
  isHidden?: boolean,
  serviceUrl: string,
  title: JSX.Element | string,
  launchDarklyFeature?: TLaunchDarklyFeature,
  ldBusinessFeature?: string,
  disabledByLDFeature?: boolean,
  hasBorderBottom?: boolean,
  onClickHandler?: () => void,
  wrapText?: boolean,
  icon?: JSX.Element,
}

const ServiceMenuItem = ({
  businessId,
  disabledByLDFeature = false,
  isSubMenu = false,
  isHidden,
  serviceUrl,
  title,
  launchDarklyFeature,
  ldBusinessFeature,
  hasBorderBottom = false,
  onClickHandler,
  wrapText = false,
  icon,
}: IServiceMenuItemProps) => {
  const featureQuery = useLDBusinessFeatureQuery(businessId, ldBusinessFeature);

  if (isHidden) return null;

  if (ldBusinessFeature && businessId && (!featureQuery.data !== disabledByLDFeature)) return null;

  const titleClasses = classNames(
    isSubMenu ? 'm-l-30 m-t-8 m-b-8' : 'm-l-18 m-t-8 m-b-8',
  );

  return (
    <li className={ `${hasBorderBottom ? styles['border-bottom'] : '' }` } data-global-feature-toggle={ launchDarklyFeature }>
      <a
        className={ `submenu-title ${wrapText ? styles['wrap-submenu-title'] : ''}` }
        href={ onClickHandler ? undefined : serviceUrl }
        onClick={ onClickHandler }
      >
        {!!icon && icon}
        <span className={ `${titleClasses} ${styles['ellipsis-text']}` }>{ title }</span>
      </a>
    </li>
  );
};

export default ServiceMenuItem;
