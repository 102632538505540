import React from 'react';

import {
  IDocument,
  TDocumentsSortColumn,
} from '@src/types/documents';
import { ISorting } from '@src/types/sorting';

import { AppLink } from '@src/components/ui_v2/buttons';
import CollectionTable from '@src/components/ui_v2/collection_table';

interface IRelatedDocumentsTableProps {
  records: IDocument[],
  sorting: ISorting<TDocumentsSortColumn>,
}

const docytId = (document: IDocument) => {
  return (
    <AppLink
      newWindow
      href={ `/document/${document.docytId}` }
    >
      { document.docytId }
    </AppLink>
  );
};

const RelatedDocumentsTable = ({
  records,
  sorting,
}: IRelatedDocumentsTableProps) => {
  return (
    <CollectionTable<
      IDocument,
      TDocumentsSortColumn
    >
      height="100%"
      hideSectionBorder
      records={ records }
      sorting={ sorting }
    >
      <CollectionTable.TextColumn<IDocument>
        name="docyt_id"
        title="Docyt ID"
        value={ docytId }
        width="32%"
      />
      <CollectionTable.TextColumn<IDocument>
        name="file_name"
        title="Name"
        value={ (document) => document.finalFileKey || document.originalFileKey }
      />
    </CollectionTable>
  );
};

export default React.memo(RelatedDocumentsTable);
