import React, { useLayoutEffect, useRef } from 'react';

import { IUIStyleProps, uiStyleProps } from '@src/utils/ui_style_helpers';

interface ITooltipProps<C extends React.ElementType> extends IUIStyleProps {
  as?: C,
  placement?: 'top' | 'bottom' | 'left' | 'right',
  title: string,
}

type TTooltipProps<C extends React.ElementType> = ITooltipProps<C> &
  Omit<React.ComponentPropsWithoutRef<C>, keyof ITooltipProps<C>>

const Tooltip = <C extends React.ElementType = 'a'>({
  children,
  as: component,
  placement = 'bottom',
  title,
  ...props
}: TTooltipProps<C>) => {
  const tooltipElement = useRef<any>(null);

  useLayoutEffect(() => {
    if (!tooltipElement.current) return;

    $(tooltipElement.current).tooltip();
  }, []);

  const [classes, elementProps] = uiStyleProps(
    undefined,
    props,
  );

  const Component = component || 'a';

  return (
    <Component
      ref={ tooltipElement }
      className={ classes }
      data-html="true"
      data-placement={ placement }
      data-toggle="tooltip"
      title={ title }
      { ...elementProps }
    >
      { children }
    </Component>
  );
};

export default Tooltip;
