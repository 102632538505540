import React from 'react';

import { TID } from '@src/types/common';

import BusinessProvider from '@src/components/providers/business_provider';
import Root from '@src/components/root';
import SideView from '@src/components/ui/side_view';

import EmployeeCard from './accounts/employee_cards';

interface EmployeeCardPageProps {
  businessId: TID,
  rcPaymentAccountId: TID,
  multiEntityEmployeeCardFeatureFlag: boolean,
}

const EmployeeCardPage = ({
  businessId,
  rcPaymentAccountId,
  multiEntityEmployeeCardFeatureFlag = true,
}: EmployeeCardPageProps): JSX.Element => {
  return (
    <Root>
      <BusinessProvider businessId={ businessId }>
        <SideView.Provider>
          <EmployeeCard
            businessId={ businessId }
            multiEntityEmployeeCardFeatureFlag={ multiEntityEmployeeCardFeatureFlag }
            rcPaymentAccountId={ rcPaymentAccountId }
          />
          <SideView.Render />
        </SideView.Provider>
      </BusinessProvider>
    </Root>
  );
};

export default EmployeeCardPage;
