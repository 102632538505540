import { useMutation, useQueryClient, useQuery } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  getFinancialInstitutionConnection,
  createFinancialInstitutionConnection,
  createFinancialInstitutionEmployeeMapping,
  updateFinancialInstitutionConnection,
  destroyFinancialInstitutionEmployeeMappings,
  deleteFinancialInstitutionConnection,
  ICreateFinancialInstitutionConnectionParams,
  ICreateFinancialInstitutionEmployeeMappingParams,
  IUpdateFinancialInstitutionConnectionParams,
  IDestroyFinancialInstitutionEmployeeMappingsParams,
  IGetFinancialInstitutionConnectionParams,
  IGetFinancialInstitutionAccountOwnersParams,
  IGetFinancialInstitutionAccountOwnersResponse,
  IFinancialConnectionParams,
  IGetFinancialInstitutionConnectionResponse,
  disconnectFinancialInstitutionConnection,
  IGetFinancialInstitutionConnectionResponseInfinite,
  getFinancialInstitutionConnectionInfinite,
  getFinancialInstitutionAccountOwners,
} from '@src/requests/financial_institution_connection';
import { IFinancialInstitutionConnection } from '@src/types/financial_institution_connection';

import { createUseGetInfiniteCollection } from './infinite_collection_queries';

const useGetFinancialInstitutionConnection = (params: IGetFinancialInstitutionConnectionParams) => {
  return useQuery<IGetFinancialInstitutionConnectionResponse, Error>(
    [QueryKey.financial_institution_connection, params],
    () => getFinancialInstitutionConnection(params),
  );
};

const useGetFinancialInstitutionConnectionInfiniteScroll = createUseGetInfiniteCollection<
  IFinancialInstitutionConnection,
  IGetFinancialInstitutionConnectionParams,
  IGetFinancialInstitutionConnectionResponseInfinite
>({
  queryKey: QueryKey.financial_institution_connection,
  request:  getFinancialInstitutionConnectionInfinite,
});

const useCreateFinancialInstitutionConnection = () => {
  const queryClient = useQueryClient();

  return useMutation<
    void,
    Error,
    ICreateFinancialInstitutionConnectionParams
  >(
    createFinancialInstitutionConnection,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.financial_institution_connection);
      },
    },
  );
};

const useUpdateFinancialInstitutionConnection = () => {
  const queryClient = useQueryClient();

  return useMutation<
    void,
    Error,
    IUpdateFinancialInstitutionConnectionParams
  >(
    updateFinancialInstitutionConnection,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.financial_institution_connection);
      },
    },
  );
};

const useDestroyFinancialInstitutionConnection = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IFinancialConnectionParams>(
    deleteFinancialInstitutionConnection,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.financial_institution_connection);
      },
    },
  );
};

const useDisconnectFinancialInstitutionConnection = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IFinancialConnectionParams>(
    disconnectFinancialInstitutionConnection,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.financial_institution_connection);
      },
    },
  );
};

const useGetFinancialInstitutionAccountOwners = (
  params: IGetFinancialInstitutionAccountOwnersParams,
  options?: { enabled?: boolean },
) => {
  return useQuery<IGetFinancialInstitutionAccountOwnersResponse, Error>(
    [
      QueryKey.financial_institution_connection,
      params.financialInstitutionBankAccountId,
      params.reconciliationPaymentAccountId,
    ],
    () => getFinancialInstitutionAccountOwners(params),
    {
      enabled: options?.enabled !== undefined
        ? options.enabled
        && !!params.financialInstitutionBankAccountId
        && !!params.reconciliationPaymentAccountId
        : !!params.financialInstitutionBankAccountId && !!params.reconciliationPaymentAccountId,
    },
  );
};

const useCreateFinancialInstitutionEmployeeMapping = (financialInstitutionBankAccountId: number) => {
  const queryClient = useQueryClient();

  return useMutation<
    void,
    Error,
    ICreateFinancialInstitutionEmployeeMappingParams
  >(
    (params) => createFinancialInstitutionEmployeeMapping(financialInstitutionBankAccountId, params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.financial_institution_connection);
      },
    },
  );
};

const useDeleteFinancialInstitutionEmployeeMapping = (financialInstitutionBankAccountId: number) => {
  const queryClient = useQueryClient();

  return useMutation<
    void,
    Error,
    IDestroyFinancialInstitutionEmployeeMappingsParams
  >(
    (params) => destroyFinancialInstitutionEmployeeMappings(financialInstitutionBankAccountId, params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.financial_institution_connection);
      },
    },
  );
};

export {
  useGetFinancialInstitutionConnection,
  useGetFinancialInstitutionConnectionInfiniteScroll,
  useGetFinancialInstitutionAccountOwners,
  useCreateFinancialInstitutionConnection,
  useCreateFinancialInstitutionEmployeeMapping,
  useUpdateFinancialInstitutionConnection,
  useDeleteFinancialInstitutionEmployeeMapping,
  useDestroyFinancialInstitutionConnection,
  useDisconnectFinancialInstitutionConnection,
};
