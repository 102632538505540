import React, { FC } from 'react';

import { IAdjustmentEntry, IJournalEntryLine } from '@src/types/adjustment_entries';
import { amountToNumber } from '@src/utils/amount_helper';

import Table from '@src/components/ui/table';

import styles from '@src/components/journal_entries/detail_page/styles.module.scss';

const formatAmount = (amount: any) => {
  if (amount) {
    const amountNumber = amountToNumber(amount);
    return amountNumber.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  }

  return '--';
};

const EntryLine: React.FC<{ line: IJournalEntryLine }> = ({ line }) => {
  return (
    <Table.Row>
      <Table.Cell>{line.chartOfAccount?.displayName || '--'}</Table.Cell>
      <Table.Cell>{line.memo || '--'}</Table.Cell>
      <Table.Cell>{line.entity?.name || line.entity?.qboName || '--'}</Table.Cell>
      <Table.Cell>{line.accountingClass?.name || '--'}</Table.Cell>
      <Table.Cell>{formatAmount(line.debits)}</Table.Cell>
      <Table.Cell>{formatAmount(line.credits)}</Table.Cell>
    </Table.Row>
  );
};

const DetailTable: FC<{data: IAdjustmentEntry}> = ({ data }) => {
  // eslint-disable-next-line max-len
  const debitsTotal = data?.lines?.reduce((acc, current) => acc + (Number(current.debits) || 0), 0) || 0;

  // eslint-disable-next-line max-len
  const creditsTotal = data?.lines?.reduce((acc, current) => acc + (Number(current.credits) || 0), 0) || 0;

  return (
    <>
      <div className={ styles['entry-lines'] }>
        <Table className={ styles.table } wrapperClassName="business-inbox-table-wrapper inbox-sortable-table">
          <Table.Head>
            <Table.Row>
              <Table.HCell>Account</Table.HCell>
              <Table.HCell>Description</Table.HCell>
              <Table.HCell>Name</Table.HCell>
              <Table.HCell>Department</Table.HCell>
              <Table.HCell>Debits</Table.HCell>
              <Table.HCell>Credits</Table.HCell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            { data?.lines?.map((line) => <EntryLine key={ line.id } line={ line } />) }
          </Table.Body>
          <Table.Foot>
            <Table.Row className={ styles['table-footer'] }>
              <Table.Cell colSpan={ 4 }><b>Total</b></Table.Cell>
              <Table.Cell><b>{formatAmount(debitsTotal)}</b></Table.Cell>
              <Table.Cell><b>{formatAmount(creditsTotal)}</b></Table.Cell>
            </Table.Row>
          </Table.Foot>
        </Table>
      </div>
      <div className={ styles['entry-memo'] }>
        <p>
          <strong>Memo: </strong>
          {data.memo || '--'}
        </p>
      </div>
    </>
  );
};

export default DetailTable;
