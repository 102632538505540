import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const OpenFullScreenIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="open-full-screen"
      pathsNumber={ 3 }
    />
  );
};

export default OpenFullScreenIcon;
