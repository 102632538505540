import { useCallback, useEffect, useState } from 'react';

import toastr from '@lib/toastr';
import { useGenerateLinkToken, useAggregatorRetrieveLinkToken } from '@src/hooks/queries/generate_aggregator_token';
import { useGeneratePlaidItemLinkToken, useRetrievePlaidItemLinkToken } from '@src/hooks/queries/plaid_items';
import usePlaidService from '@src/hooks/use_plaid';
import useQuiltt from '@src/hooks/use_quiltt';
import { IPlaidMetadata, IQuilltMeta } from '@src/types/financial_institution_connection';
import { showLinkPlaidWindow } from '@src/utils/plaid';

import { IConnectNewFinancialInstitutionData } from './connect_financial_institution/schema';

// this is a hard code flag for this specific component which will decide
// whether plaid should open in same window or it should open in separate window
const OPEN_PLAID_IN_SAME_WINDOW = false;

type TAggregatorsType = 'plaid' | 'quiltt'

interface IUseConnectPlaidAccountParams {
  onAggregatorConnected: (data: IConnectNewFinancialInstitutionData) => void,
}

const useConnectAggregatorAccount = ({
  onAggregatorConnected,
}: IUseConnectPlaidAccountParams) => {
  const connectAggregatorToken = useGenerateLinkToken();
  const reconnectAggregatorToken = useAggregatorRetrieveLinkToken();

  const generateLink = useGeneratePlaidItemLinkToken();
  const retrieveLink = useRetrievePlaidItemLinkToken();

  const [accountData, setAccountData] = useState<
    IConnectNewFinancialInstitutionData | null>(null);

  const handleAggregatorAccountConnected = useCallback((
    metadata: IPlaidMetadata | IQuilltMeta,
  ) => {
    if (!accountData) return;

    // prepare the request data based on aggregator
    const aggregatorMetadata = (() => {
      if (metadata.type === 'quiltt') {
        return {
          quilttConnectionId: metadata.connectionId,
          quilttConnectorId:  metadata.connectorId,
          quilttProfileId:    metadata.profileId,
        };
      }
      return { plaidPublicToken: metadata.publicToken, plaidAccountId: metadata.account_id };
    })();

    onAggregatorConnected({
      ...accountData,
      ...aggregatorMetadata,
    });

    setAccountData(null);
  }, [accountData, onAggregatorConnected]);

  const { open, ready: isPlaidReady, setLinkToken } = usePlaidService({
    handleOnSuccess: (publicToken, metadata) => {
      const data = { ...metadata, publicToken };
      handleAggregatorAccountConnected(data);
    },
    handleOnExit: (error) => {
      if (error) {
        toastr.error(error, 'Error');
      }
    },
  });

  /**
   * This function used to load the Plaid UI also it will decide whether UI should load on current window or new window
   * @param linkToken plaid link token
   */
  const loadPlaidUI = useCallback((linkToken: string) => {
    // if this condition false plaid will be open in a separate window
    if (OPEN_PLAID_IN_SAME_WINDOW) {
      // once linkToken set to the state it will trigger the useEffect and load the plaid UI in same window
      setLinkToken(linkToken);
    } else {
      showLinkPlaidWindow(linkToken);
    }
  }, [setLinkToken]);

  const { open: openQuiltt, setReConnectInstitution, loadQuiltUIForReconnect } = useQuiltt({
    handleOnSuccessCallback: (metadata) => {
      handleAggregatorAccountConnected(metadata);
    },
  });

  // Handle effect of updating the token for Plaid into state and open the Plaid window when it's ready
  useEffect(() => {
    if (isPlaidReady) {
      open();
    }
  }, [isPlaidReady, open]);

  useEffect(() => {
    window.Docyt.vent.on('plaid:account:connected', (publicToken: string, metadata: IPlaidMetadata) => {
      onAggregatorConnected({
        ...accountData,
        plaidPublicToken: publicToken,
        plaidAccountId:   metadata.account_id,
      });
    });

    return () => {
      window.Docyt.vent.off('plaid:account:connected');
    };
  }, [accountData, handleAggregatorAccountConnected, onAggregatorConnected]);

  const { mutate: generate } = generateLink;
  const { mutate: generateConnectAggregatorToken } = connectAggregatorToken;
  const handleConnectToAggregator = useCallback((
    data: IConnectNewFinancialInstitutionData,
    aggregator?: TAggregatorsType,
    isQuilttIntegrationEnabled?: boolean,
  ) => {
    setAccountData(() => ({ ...data, aggregator }));
    switch (aggregator) {
      case 'quiltt':
        openQuiltt();
        break;
      case 'plaid':
      default:
        // If the feature is enabled, the Plaid link token should be generated by the banking service.
        if (isQuilttIntegrationEnabled) {
          generateConnectAggregatorToken({
            management_group_id: data.managementGroupId,
            product:             'transactions',
          }, {
            onSuccess: (response) => {
              // Currently, we are only considering Plaid in this API call,
              // as Quilt is already handled by the frontend. We'll address this in the next phase.
              if (response.plaid) {
                loadPlaidUI(response.plaid.linkToken);
              }
            },
          });
        } else {
          generate({
            product: 'transactions',
          }, {
            onSuccess: (financialInstitutionConnection) => {
              loadPlaidUI(financialInstitutionConnection.linkToken);
            },
          });
        }
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generate, loadPlaidUI, openQuiltt]);

  const { mutate: retrieve } = retrieveLink;
  const { mutate: reconnectToken } = reconnectAggregatorToken;
  const handleReconnectToAggregator = useCallback((
    data: IConnectNewFinancialInstitutionData,
    isQuilttIntegrationEnabled?: boolean,
  ) => {
    setAccountData(data);
    if (isQuilttIntegrationEnabled) {
      window.Docyt.vent.trigger('show:spinner');
      reconnectToken(
        {
          financialInstitutionConnectionId: data.id,
        },
        {
          onSuccess: (financialInstitutionConnection) => {
            window.Docyt.vent.trigger('hide:spinner');
            if (data.aggregator === 'quiltt') {
              loadQuiltUIForReconnect({
                connectionId: financialInstitutionConnection.quiltt.connectionId,
                institution:  financialInstitutionConnection.quiltt.institutionId,
                session:      financialInstitutionConnection.quiltt.session,
              });
            } else {
              loadPlaidUI(financialInstitutionConnection.plaid.linkToken);
            }
          },
        },
      );
    } else {
      window.Docyt.vent.trigger('show:spinner');
      retrieve({
        financialInstitutionConnectionId: data.id,
      }, {
        onSuccess: (financialInstitutionConnection) => {
          window.Docyt.vent.trigger('hide:spinner');
          loadPlaidUI(financialInstitutionConnection.linkToken);
        },
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retrieve, loadPlaidUI, setReConnectInstitution]);

  return {
    connectAggregator:     handleConnectToAggregator,
    reconnectToAggregator: handleReconnectToAggregator,
    generateLinkMutation:  generateLink.isError ? generateLink : connectAggregatorToken,
  };
};

export {
  useConnectAggregatorAccount,
};
