import { TMongoID } from '@src/types/common';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import { apiGet } from '../helpers';

export interface IReportRefreshLogsParams {
  reportId: TMongoID,
  params?: { startDate?: string; endDate?: string; type?: string }
}

const getReportRefreshLogsByReportId = (
  params: IReportRefreshLogsParams,
): Promise<any> => {
  const tempParams = params?.params ? underscoreKeys(params.params) : {};
  return apiGet(
    `/report-new/api/v1/reports/activities/reports/${params.reportId}`,
    { ...tempParams },
  ).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      meta: {
        totalCount: cdata.count,
      },
      collection: cdata,
    };
  });
};

export { getReportRefreshLogsByReportId };
