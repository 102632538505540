import React, { useCallback, useMemo } from 'react';

import { useBulkAddToBusiness } from '@src/hooks/queries/business_vendors';
import { IBusiness } from '@src/types/businesses';
import { TID } from '@src/types/common';

import { useBulkActionModal } from '@src/components/common/bulk_action_modal';
import { IBulkActionStatus } from '@src/components/common/bulk_action_modal/hooks';

import Result from './edit_linked_businesses_result';
import { useEditLinkedBusinessesModal } from '../modal/edit_linked_businesses_modal';

interface IEditLinkedBusinessesActionProps {
  businessId: TID,
  vendorId: TID,
  linkedBusinesses: any[],
  onDone: () => void,
}

const editLinkedBusinessesResult = (status: IBulkActionStatus<any, any>) => (
  <Result status={ status } />
);

const EditLinkedBusinessesAction = ({
  businessId,
  vendorId,
  linkedBusinesses,
  onDone,
}: IEditLinkedBusinessesActionProps): JSX.Element => {
  const editLinkedBusinesses = useEditLinkedBusinessesModal();

  const add = useBulkAddToBusiness();
  const bulkAction = useBulkActionModal({
    mutation: add,
    onDone,
  });

  const { runMutation } = bulkAction;

  const linkedBusinessIds = useMemo(() => {
    return linkedBusinesses.map((business) => (business.id));
  }, [linkedBusinesses]);

  const handleLinkToBusinesses = useCallback((businesses: IBusiness[]) => {
    const params = businesses
      .filter((business) => !linkedBusinessIds.includes(business.id))
      .map((business) => ({
        businessId: business.id,
        vendorIds:  [vendorId],
      }));

    runMutation(params);
  }, [runMutation, vendorId, linkedBusinessIds]);

  const handleDone = useCallback((businesses: IBusiness[]) => {
    handleLinkToBusinesses(businesses);
  }, [handleLinkToBusinesses]);

  return (
    <div className="pull-right pointer">
      <bulkAction.Component
        { ...bulkAction.props }
        itemsTitle="vendor links created"
        result={ editLinkedBusinessesResult }
        title="Vendors Links"
      />
      <editLinkedBusinesses.Component
        { ...editLinkedBusinesses.props }
        businessId={ businessId }
        linkedBusinessIds={ linkedBusinessIds }
        onDone={ handleDone }
      />
      <a
        className="edit-linked-businesses"
        role="button"
        tabIndex={ 0 }
        onClick={ editLinkedBusinesses.open }
      >
        Edit
      </a>
    </div>
  );
};

export default EditLinkedBusinessesAction;
